export const handleExpressSubmit = (
  expressRatesType,
  expressRatesParcelTo,
  expressRatesParcelWeightType,
  allExpressRatesParcel,
  allExpressRatesDocument
) => {
  const weightType = expressRatesParcelWeightType.split("(")[1].split(")")[0];
  let onlyWeight;
  if (weightType.length < 4) {
    onlyWeight = weightType.slice(0, 1);
  } else if (weightType.length == 4) {
    onlyWeight = weightType.slice(0, 2);
  } else {
    onlyWeight = weightType.slice(0, 3);
  }
  console.log(weightType);
  console.log(onlyWeight);
  console.log(expressRatesParcelWeightType);
  let weightInLetter;
  if (onlyWeight == 0.5) {
    weightInLetter = "half";
  } else if (onlyWeight == 1) {
    weightInLetter = "one";
  } else if (onlyWeight == 1.5) {
    weightInLetter = "oneAndHalf";
  } else if (onlyWeight == 2) {
    weightInLetter = "two";
  } else if (onlyWeight == 2.5) {
    weightInLetter = "twoAndHalf";
  } else if (onlyWeight == 3) {
    weightInLetter = "three";
  } else if (onlyWeight == 5) {
    weightInLetter = "five";
  } else if (onlyWeight == 7.5) {
    weightInLetter = "sevenAndHalf";
  } else {
    weightInLetter = "ten";
  }
  if (expressRatesType == "Parcel") {
    const country = allExpressRatesParcel.find(
      (country) => country.country == expressRatesParcelTo
    );
    console.log(country);

    const result = country[weightInLetter];
    console.log(result);
    return result;
  } else {
    const country = allExpressRatesDocument.find(
      (country) => country.country == expressRatesParcelTo
    );
    console.log(country);
    const result = country[weightInLetter];
    console.log(result);
    return result;
  }
};
export const handleDoorToDoorSubmit = (
  weight,
  productType,
  allD2dRates,
  bySea
) => {
  const selectedproductType = allD2dRates.find(
    (pdctType) => pdctType.id == productType
  );
  if (bySea) {
    if (weight <= 100) {
      const result = Math.round(weight * selectedproductType["100kg"]);
      return { result, perKg: selectedproductType["100kg"] };
    }
    if (100 < weight && weight <= 1000) {
      const result = Math.round(weight * selectedproductType["below_1000kg"]);
      return { result, perKg: selectedproductType["below_1000kg"] };
    }
    if (weight > 1000) {
      const result = Math.round(weight * selectedproductType["above_1000kg"]);
      return { result, perKg: selectedproductType["above_1000kg"] };
    }
  } else {
    if (weight <= 0.3) {
      const result = selectedproductType.parcel;
      return {
        result,
        perKg: Math.round(selectedproductType.parcel * 3.333333),
      };
    }
    if (0.3 < weight && weight <= 5) {
      const result = Math.round(weight * selectedproductType.ten);
      return { result, perKg: selectedproductType.ten };
    }
    if (weight > 5) {
      const result = Math.round(weight * selectedproductType.eleven);
      return { result, perKg: selectedproductType.eleven };
    }
  }
};

export const handleFreightSubmit = () => {};
