import React, { Component } from "react";
import {
  auth,
  signInWithGoogle,
  singInWithFacebook,
} from "./firebase/firebase.utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toggleLoginModal } from "../actions/index";

class IntroModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      currentUser: null,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      document.getElementById("modal-close-icon-login").click();
      this.setState({ email: "", password: "" });

      this.props.history.push("/");
      this.setState({ email: "", password: "" });
    } catch (error) {
      alert(error);
    }
  };

  componentWillUnmount = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSignInWithFacebook = async () => {
    try {
      await singInWithFacebook();
      console.log();
    } catch (error) {}
  };

  handleSignInWithGoogle = async () => {
    try {
      const currentUser = await signInWithGoogle();
      this.setState(currentUser, () => {
        console.log(this.state.currentUser);
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <div
          className="modal fade"
          id="request_intro_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal bg-fixed request-pag-img"
              style={{
                boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                height: window.screen.width > 700 ? "700px" : "500px",
                width: window.screen.width > 700 ? "600px" : "400px",
                backgroundImage: `url(${this.props.introModal})`,
                margin: "auto",
              }}
            >
              <div className="modal-body p-0">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="modal-close-icon-login"
                >
                  <i
                    className="icofont-close-line"
                    style={{ color: "white" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    introModal: state.notices.introModal,
  };
};
export default withRouter(connect(mapStateToProps)(IntroModal));
