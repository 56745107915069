import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./myInvoiceDatatable.css";
import { Link } from "react-router-dom";
import {
  setParcelObj,
  checkedValuesRedux,
  totalAmountToPayRedux,
} from "../../../actions/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      parcelValue: "",
      deliveryAddress: "ALG OFFICE",
      packagingChosed: "",
      purchasingObj: null,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };
  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      myData.forEach((parcel) => {
        //  this is not affecting my output see line 104
        newData.push({
          "Invoice No": parcel.imageUrl ? parcel.paymentId : parcel.id,
          Date: parcel.date,
          "Total Bill":
            parcel.totalBill || parseInt(parcel.amount * parcel.quantity),
          "Total Paid": parcel.totalPaid || 0,
          "Total Due":
            (parcel.totalBill || parseInt(parcel.amount * parcel.quantity)) -
            (parcel.totalPaid || 0),
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index]
              ? newData[cellInfo.index][cellInfo.column.id]
              : null,
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  renderButton = (row, array) => {
    if (array.length > 0) {
      const parcelObj = array.find((parcel) => {
        if (parcel.imageUrl) {
          return parcel.paymentId === row.original["Invoice No"];
        } else {
          return parcel.id === row.original["Invoice No"];
        }
      });

      if (parcelObj.invoiceStatus && parcelObj.invoiceStatus === "Paid") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
              style={{
                background: "green",
                fontSize: 12,
                fontFamily: "sans-serif",
                padding: "5px 10px",
                borderRadius: 8,
              }}
            >
              <i className="icofont-tick-mark" style={{ paddingRight: 2 }}></i>
              paid
            </div>
          </div>
        );
      }
      if (
        parcelObj.paymentRequest &&
        parcelObj.paymentRequest !== "recharged"
      ) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
              style={{
                background:
                  parcelObj.paymentRequest == "pending" ? "orange" : "red",
                fontSize: 12,
                fontFamily: "sans-serif",
                padding: "5px 10px",
                borderRadius: 8,
              }}
              onClick={async () => {
                if (parcelObj.paymentRequest == "pending") {
                  await this.props.setParcelObj(parcelObj);
                  this.props.history.push(
                    `/user-my-invoice/${parcelObj.parcelId}`
                  );
                } else {
                }
              }}
            >
              <i
                style={{ paddingRight: 2 }}
                className={
                  parcelObj.paymentRequest == "pending"
                    ? "icofont-hand"
                    : "icofont-close"
                }
              ></i>
              {parcelObj.paymentRequest}
            </div>
          </div>
        );
      }
      if (parcelObj.invoiceStatus && parcelObj.invoiceStatus === "Paid") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
              style={{
                background: "green",
                fontSize: 12,
                fontFamily: "sans-serif",
                padding: "5px 10px",
                borderRadius: 8,
              }}
            >
              <i className="icofont-tick-mark" style={{ paddingRight: 2 }}></i>{" "}
              paid
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
              to="#"
              style={{
                background: "#7d017d",
                fontSize: 12,
                fontFamily: "sans-serif",
                padding: "5px 10px",
                borderRadius: 8,
              }}
              role="button"
              data-toggle="modal"
              data-target="#request_payment_popup_2"
              onClick={() => {
                let total =
                  (parcelObj.totalBill ||
                    parseInt(parcelObj.amount * parcelObj.quantity)) -
                  (parcelObj.totalPaid || 0);
                this.props.checkedValuesRedux([parcelObj]);
                this.props.totalAmountToPayRedux({
                  total,
                  express: false,
                });
              }}
            >
              <i
                className="icofont-location-arrow"
                style={{ paddingRight: 2 }}
              ></i>{" "}
              pay
            </div>
          </div>
        );
      }
    } else {
      return "";
    }
  };

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    const { purchasingObj } = this.state;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      myData.forEach((parcel) => {
        //  this is not affecting my output see line 104
        newData.push({
          "Invoice No": parcel.imageUrl ? parcel.paymentId : parcel.id,
          Date: parcel.date,
          "Total Bill":
            parcel.totalBill || parseInt(parcel.amount * parcel.quantity),
          "Total Paid": parcel.totalPaid || 0,
          "Total Due":
            (parcel.totalBill || parseInt(parcel.amount * parcel.quantity)) -
            (parcel.totalPaid || 0),
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }
    columns.push(
      {
        Header: <b>Invoice</b>,
        id: "orderDetails",
        accessor: (str) => "orderDetails",
        Cell: (row) => this.renderButton(row, myData),

        style: {
          textAlign: "center",
        },
        sortable: false,
      },
      {
        Header: <b>Status</b>,
        id: "orderDetails",
        accessor: (str) => "orderDetails",
        Cell: (row) => {
          let purchasingObj = myData.find(
            (purchasing) =>
              purchasing.id === row.original["Invoice No"] ||
              purchasing.paymentId === row.original["Invoice No"]
          );
          console.log(purchasingObj);
          return (
            <div style={{ padding: "5px 0px" }}>
              <span
                style={{
                  padding: "3px 5px",
                  background: purchasingObj
                    ? purchasingObj.purchasingStatus == "Purchasing" ||
                      purchasingObj.purchasingStatus == "Purchased"
                      ? "#f9ac21"
                      : purchasingObj.purchasingStatus == "Delivered" ||
                        purchasingObj.purchasingStatus == "Bangladesh Warehouse"
                      ? "green"
                      : purchasingObj.purchasingStatus
                      ? "cadetblue"
                      : purchasingObj.category === "sold-products"
                      ? "green"
                      : purchasingObj.category === "sourcing"
                      ? "#0000a9"
                      : "red"
                    : "red",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: 12,
                  cursor: "pointer",
                }}
                data-toggle="modal"
                data-target="#StatusModal"
                onClick={() => {
                  this.setState({
                    purchasingObj: purchasingObj,
                    purchasingStatus:
                      purchasingObj && purchasingObj.purchasingStatus
                        ? purchasingObj.purchasingStatus
                        : "",
                  });
                }}
              >
                {" "}
                {purchasingObj && purchasingObj.purchasingStatus
                  ? purchasingObj.purchasingStatus
                  : purchasingObj && purchasingObj.category == "sold-products"
                  ? "Sold"
                  : "Sourcing"}{" "}
              </span>
            </div>
          );
        },

        style: {
          textAlign: "center",
        },
        sortable: false,
      },

      {
        Header:
          this.state.checkedValues.length > 0 ? (
            <button
              className="btn btn-sm btn-delete mb-0 b-r-4"
              style={{ backgroundColor: "purple", color: "white" }}
              role="button"
              data-toggle="modal"
              data-target="#request_payment_popup_2"
              onClick={() => {
                let total = 0;
                let selected = myData.filter((parcel) => {
                  if (
                    this.state.checkedValues.includes(parcel.id) ||
                    this.state.checkedValues.includes(parcel.paymentId)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                });
                console.log(selected);
                console.log(this.state.checkedValues);
                selected.map((parcelObj) => {
                  total +=
                    (parcelObj.totalBill ||
                      parseInt(parcelObj.amount * parcelObj.quantity)) -
                    (parcelObj.totalPaid || 0);
                });

                this.props.checkedValuesRedux(selected);
                this.props.totalAmountToPayRedux({
                  total,
                  express: false,
                });
              }}
            >
              Pay
            </button>
          ) : (
            <button
              style={{
                backgroundColor: "gray",
                color: "white",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => window.alert("Please choose an invoice first")}
            >
              multiple pay
            </button>
          ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => {
          const parcelObj = myData.find((parcel) => {
            if (parcel.imageUrl) {
              return parcel.paymentId === row.original["Invoice No"];
            } else {
              return parcel.id === row.original["Invoice No"];
            }
          });
          return (
            <div>
              <span>
                {(parcelObj.paymentRequest &&
                  parcelObj.paymentRequest == "pending") ||
                parcelObj.paymentRequest ==
                  "recharged" ? null : parcelObj.invoiceStatus ==
                  "Paid" ? null : (
                  <input
                    type="checkbox"
                    name={row.original["Invoice No"]}
                    defaultChecked={this.state.checkedValues.includes(
                      row.original["Invoice No"]
                    )}
                    onChange={(e) =>
                      this.selectRow(e, row.original["Invoice No"])
                    }
                  />
                )}
              </span>
            </div>
          );
        },
        accessor: key,
        style: {
          textAlign: "center",
        },
      }
    );

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
        <div
          className="modal fade"
          id="StatusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", minWidth: "100%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Status
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Invoice Id:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Status{" "}
                  <span
                    style={{
                      padding: "3px 5px",
                      background: purchasingObj
                        ? purchasingObj.purchasingStatus == "Purchasing" ||
                          purchasingObj.purchasingStatus == "Purchased"
                          ? "#f9ac21"
                          : purchasingObj.purchasingStatus == "Delivered" ||
                            purchasingObj.purchasingStatus ==
                              "Bangladesh Warehouse"
                          ? "green"
                          : purchasingObj.purchasingStatus
                          ? "cadetblue"
                          : purchasingObj.category === "sold-products"
                          ? "green"
                          : purchasingObj.category === "sourcing"
                          ? "#0000a9"
                          : "red"
                        : "red",
                      color: "white",
                      borderRadius: "5px",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {purchasingObj && purchasingObj.purchasingStatus
                      ? purchasingObj.purchasingStatus
                      : purchasingObj &&
                        purchasingObj.category == "sold-products"
                      ? "Sold"
                      : "Sourcing"}{" "}
                  </span>
                </div>
                {purchasingObj && purchasingObj.purchasingStatus ? (
                  <>
                    <div style={{ padding: "5px 0px" }}>
                      Order Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj && purchasingObj.purchasingDate}{" "}
                      </span>
                    </div>
                    <div style={{ padding: "5px 0px" }}>
                      Purchased Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj && purchasingObj.purchasedDate}{" "}
                      </span>
                    </div>
                    <div style={{ padding: "5px 0px" }}>
                      Abroad Warehouse Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj &&
                          purchasingObj.abroadWarehouseDate}{" "}
                      </span>
                    </div>
                    <div style={{ padding: "5px 0px" }}>
                      In Shipment Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj && purchasingObj.InShipmentDate}{" "}
                      </span>
                    </div>
                    <div style={{ padding: "5px 0px" }}>
                      Bangladesh Warehouse Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj &&
                          purchasingObj.bangladeshWarehouseDate}{" "}
                      </span>
                    </div>
                    <div style={{ padding: "5px 0px" }}>
                      Delivered Date:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {purchasingObj && purchasingObj.deliveredDate}{" "}
                      </span>
                    </div>
                  </>
                ) : null}
                <div
                  style={{
                    padding: "2px 0px",
                    borderBottom: "1px solid gainsboro",
                    marginBottom: 20,
                  }}
                ></div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(
  connect(null, { setParcelObj, checkedValuesRedux, totalAmountToPayRedux })(
    Datatable
  )
);
