import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import {
  uploadImageRechargeRequest,
  makeP2pAgentRequest,
} from "../../firebase/firebase.utils";
import {
  getAllP2PRedux,
  getAllWarehouseProductsRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
  addToCart,
  removeFromCart,
  getAllP2pAgentRequestRedux,
} from "../../../actions/index";
import { ShoppingCart, Bell } from "react-feather";
import { withRouter } from "react-router-dom";
export class MyShipmentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",

      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      arrivalDate: "",
    };
  }

  componentDidMount = async () => {
    console.log(this.props);

    const { getAllP2pAgentRequestRedux, currentUser } = this.props;

    if (currentUser && currentUser.id) {
      await getAllP2pAgentRequestRedux(currentUser.id);
    }
  };

  componentWillReceiveProps = async (nextProps) => {
    const { getAllP2pAgentRequestRedux, currentUser } = this.props;
    if (currentUser.id !== nextProps.currentUser.id) {
      await getAllP2pAgentRequestRedux(nextProps.currentUser.id);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    let date = new Date();

    if (this.state.loading) {
      alert("Please wait.. your image is uploading");
      return;
    }
    if (this.state.type === "upload") {
      let bookingObj = {
        id: date.getTime().toString(),
        date: date.toLocaleDateString("en-GB"),
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.props.currentUser.id,
        status: "pending",
      };

      await this.props.uploadP2PRedux(bookingObj);
    } else if (this.state.type === "update") {
      let bookingObj = {
        id: this.state.id,
        date: this.state.date,
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.state.userId,
        status: this.state.status,
      };
      await this.props.updateP2PRedux(bookingObj);
    }

    this.setState({
      name: "",
      quantity: "",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
    });
  };

  renderActionButton = (product, cart) => {
    let addedToCart = cart.find((product2) => product2.id == product.id);
    console.log(addedToCart);
    if (product.agentStatus && product.agentStatus !== "Reject") {
      return (
        <div
          style={{
            color: "white",
            padding: "5px 7px",
            borderRadius: 5,
            backgroundColor:
              product.agentStatus == "Pending" ? "darkorange" : "red",
            textAlign: "center",

            fontWeight: "bold",
          }}
        >
          <i
            className="icofont-business-man"
            style={{
              fontSize: "130%",
              marginRight: "5px",
              color: "white",
            }}
          ></i>
          {product.agentStatus}
        </div>
      );
    } else {
      if (addedToCart) {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "darkgreen",
              textAlign: "center",

              fontWeight: "bold",
            }}
          >
            <i
              className="icofont-tick-boxed"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Added
          </div>
        );
      } else {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "green",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => {
              this.props.addToCart(product);
              return toast.success("Added to Cart");
            }}
          >
            <i
              className="icofont-cart-alt"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Add
          </div>
        );
      }
    }
  };

  handleCartSubmit = async () => {
    const { cart, currentUser } = this.props;
    if (!this.state.arrivalDate) {
      alert("Please Select Your arrival date.");
      return;
    }
    let [year, month, day] = this.state.arrivalDate.split("-");
    for (let i = 0; i < cart.length; i++) {
      await this.props.updateP2PRedux({
        ...cart[i],
        agentId: currentUser.id,
        agentStatus: "Pending",
        arrivalDate: `${day}/${month}/${year}`,
        assignedTime: new Date().getTime(),
      });
      this.props.removeFromCart(cart[i]);
    }
    let arrivalDate = `${day}/${month}/${year}`;
    await makeP2pAgentRequest(cart, currentUser, arrivalDate);
  };

  render() {
    const { productObj } = this.state;
    const { cart } = this.props;

    let total = 0;
    if (productObj) {
      productObj.cart.map((product) => {
        total += parseInt(product.agentCost);
      });
    }

    return (
      <Fragment>
        <Breadcrumb title={"My Request"} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    My Request
                  </h5>
                </div>

                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Arrival Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Id
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Shipment Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.p2p.map((product, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{product.arrivalDate}</td>

                            <td>{product.id}</td>

                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  color:
                                    product.status == "Pending"
                                      ? "darkorange"
                                      : product.status == "Approved"
                                      ? "green"
                                      : product.status == "Reject"
                                      ? "red"
                                      : "cadetblue",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  color:
                                    product.shipmentStatus == "Assigned"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {product.shipmentStatus || "Not Assigned"}
                              </div>
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#instructionModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  padding: "5px 7px",
                                  borderRadius: 5,
                                  backgroundColor: "cadetblue",
                                  maxWidth: "100px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                see
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="instructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", maxWidth: "100%" }}
            >
              <div
                className="modal-header"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Your Picked Products
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          colSpan={3}
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Weight
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Earn
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.productObj &&
                        this.state.productObj.cart.map((product, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{product.date}</td>
                            <td>
                              <a href={product.imageUrl} target="_blank">
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={product.imageUrl}
                                />
                              </a>
                            </td>
                            <td>{product.name}</td>
                            <td colSpan={3}>
                              <pre>{product.description}</pre>
                            </td>
                            <td>{product.quantity}</td>
                            <td>{product.totalWeight || 0}Kg</td>
                            <td>{product.amount}Tk</td>
                            <td>{product.agentCost || 0}Tk</td>
                          </tr>
                        ))}
                      <tr>
                        <td
                          colSpan={10}
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                            textAlign: "end",
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{ fontWeight: "bold", fontSize: 24 }}
                          colSpan={1}
                        >
                          {total}Tk
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                {cart.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.handleCartSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2pAgentRequest,
    currentUser: state.currentUser.currentUser,
    cart: state.currentUser.cart,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWarehouseProductsRedux,
    uploadP2PRedux,
    updateP2PRedux,
    deleteP2PRedux,
    addToCart,
    removeFromCart,
    getAllP2pAgentRequestRedux,
  })(MyShipmentRequest)
);
