const INITIAL_STATE = {
  currentUser: { displayName: "", email: "" },
  rechargeRequests: [],
  recharge: [],
  payments: [],
  otpNumber: "",
  loan: 0,
  cart: [],
};

const setCurrentUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cart: [action.payload, ...state.cart],
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((product) => product.id !== action.payload.id),
      };
    case "GET_USER_LOAN":
      return {
        ...state,
        loan: action.payload,
      };
    case "SET_NUMBER":
      return {
        ...state,
        otpNumber: action.payload,
      };
    case "SET_IMAGE_URL":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "UPDATE_USER":
      return {
        ...state,
        currentUser: action.payload,
      };

    case "UPLOAD_RECHARGE_REQUEST":
      return {
        ...state,
        rechargeRequests: [action.payload, ...state.rechargeRequests],
      };

    case "GET_ALL_RECHARGE_REQUEST_OF_SINGLE_USER":
      return {
        ...state,
        rechargeRequests: action.payload,
      };
    case "GET_ALL_RECHARGE_OF_SINGLE_USER":
      return {
        ...state,
        recharge: action.payload,
      };
    case "GET_ALL_PAYMENT_OF_SINGLE_USER":
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return { ...state };
  }
};
export default setCurrentUserReducer;
