import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  getAllParcelsOfSingleUserRedux,
  totalAmountToPayRedux,
} from "../../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";

class MakePaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      termsAndCondition: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.parcelArray && nextProps.parcelArray.length > 0) {
      const invoiceArray = nextProps.parcelArray.filter(
        (parcel) => parcel.invoiceGenerated == true
      );
      this.setState({
        invoiceArray,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    const { email, password, invoiceArray } = this.state;
    const { checkedInvoices } = this.props;
    const invoicesToPay = invoiceArray.filter((invoice) =>
      checkedInvoices.includes(invoice.parcelId)
    );
    let total = 0;
    let totalWeight = 0;
    invoicesToPay.forEach((invoice) => (total += invoice.finalTotal));
    invoicesToPay.forEach(
      (invoice) => (totalWeight += parseFloat(invoice.grossWeight))
    );
    console.log(total);
    return (
      <>
        <div
          className="modal fade"
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div className="modal-content login-modal order-details-modal-container">
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2 className="h2-xl mb-2 fw-6 pb-2 order-details-header">
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div className="table-responsive-md">
                            <table className="table">
                              <thead style={{ position: "sticky", top: -3 }}>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Parcel Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Carton
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Product
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Gross Weight
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Rate/Kg
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: "rgb(0, 37, 76)",
                                      color: "white",
                                    }}
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesToPay.length > 0 &&
                                  invoicesToPay.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.parcelId}
                                      >
                                        <th scope="row">{invoice.parcelId}</th>
                                        <td>{invoice.cartonNo}</td>
                                        <td>{invoice.productName}</td>
                                        <td>{invoice.grossWeight}Kg</td>
                                        <td>{invoice.ratePerKg}Tk</td>
                                        <td>{invoice.finalTotal}Tk</td>
                                      </tr>
                                    );
                                  })}

                                <tr className="table-light">
                                  <th scope="row"></th>
                                  <td></td>
                                  <td className="result-td">Total Weight</td>
                                  <td className="result-td">
                                    {totalWeight.toString().slice(0, 5)}Kg
                                  </td>
                                  <td className="result-td">Grand Total</td>
                                  <td className="result-td">{total}Tk</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{ display: "flex", flexDirectioin: "row" }}
                          >
                            <input
                              className="terms-condition-checkbox"
                              style={{ marginLeft: 10 }}
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div className="agree-terms-condition">
                              I read & agree to the{" "}
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push(
                                    "/terms-and-condition"
                                  );
                                }}
                                data-dismiss="modal"
                              >
                                Terms & Condition
                              </span>
                              ,&nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push("/privacy-policy");
                                }}
                                data-dismiss="modal"
                              >
                                Privacy Policy
                              </span>
                              &nbsp;&&nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push("/refund-policy");
                                }}
                                data-dismiss="modal"
                              >
                                Refund Policy
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    this.props.totalAmountToPayRedux({
                                      total,
                                      express: false,
                                      invoicesToPay: invoicesToPay,
                                    });
                                  }}
                                  className="mt-3 btn btn-secondary "
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_payment_popup"
                                >
                                  Proceed to checkout
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    checkedInvoices: state.payments.checkedInvoices,
    currentUser: state.currentUser.currentUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    getAllParcelsOfSingleUserRedux,
    totalAmountToPayRedux,
  })(MakePaymentModal)
);
