import React from "react";

const VideoBox = () => {
  return (
    <>
      <div className="video-box">
        <div className="close-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Y-6tiFM7zFM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
};
export default VideoBox;
