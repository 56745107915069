import React, { Component } from "react";
import man from "../../assets/images/dashboard/user2.jpg";
import { connect } from "react-redux";

export class User_panel extends Component {
  render() {
    const { user } = this.props;
    return (
      <div>
        <div className="sidebar-user text-center">
          <div>
            {user && user.imageUrl ? (
              <img
                className="img-60 rounded-circle lazyloaded blur-up"
                style={{ maxHeight: "60px" }}
                src={user.imageUrl}
                alt="#"
              />
            ) : (
              <img
                className="img-60 rounded-circle lazyloaded blur-up"
                src={man}
                alt="#"
              />
            )}
          </div>
          <h6 className="mt-3 f-14">{user ? user.displayName : ""}</h6>
          <p style={{ color: "purple" }}>{user ? user.status : ""}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.currentUser.currentUser,
  };
};
export default connect(mapStateToProps, null)(User_panel);
