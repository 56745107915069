import React from "react";
import { connect } from "react-redux";
import "./content.css";
import { withRouter } from "react-router";
const Content = (props) => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div
                className="col-md-12 col-lg-6 notice-board-container"
                style={{
                  minHeight: "300px",
                  border: "1px solid gainsboro",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="img-icon mt-4 d-flex align-items-center"
                  style={{
                    border: "1px solid gainsboro",
                    borderRadius: "20px",
                    padding: "8px 20px",
                    background: "rgb(14, 111, 14)",
                  }}
                >
                  <h5
                    style={{
                      margin: "auto",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    className="notice-header-text"
                  >
                    নোটিশ বোর্ড
                  </h5>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  {props.notices.length > 0 &&
                    props.notices.slice(0, 10).map((notice, index) => (
                      <div
                        key={index}
                        style={{
                          borderBottom: "1px solid gainsboro",
                          padding: "8px 10px",
                        }}
                      >
                        <li
                          key={index}
                          style={{
                            fontSize: "90%",
                            cursor: "pointer",
                            color: "#337ab7",
                          }}
                          onClick={() => {
                            props.history.push(`/recent-news/${notice.id}`);
                          }}
                          className="notice-list"
                        >
                          {index + 1}. {notice.noticeTitle}
                        </li>{" "}
                        <br></br>
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <button
                    className="btn see-more-button"
                    style={{
                      backgroundColor: "rgb(14, 111, 14)",
                      color: "white",
                      borderRadius: "20px",
                      padding: "7px 12px",
                      marginBottom: "20px",
                    }}
                  >
                    previous
                    <i
                      className="icofont-arrow-right"
                      style={{ fontWeight: "bold" }}
                    ></i>
                  </button>
                  <button
                    className="btn see-more-button"
                    style={{
                      backgroundColor: "rgb(14, 111, 14)",
                      color: "white",
                      borderRadius: "20px",
                      padding: "7px 12px",
                      marginBottom: "20px",
                    }}
                  >
                    next
                    <i
                      className="icofont-arrow-right"
                      style={{ fontWeight: "bold" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notices: state.notices.notices,
    blogs: state.notices.blogs,
  };
};
export default withRouter(connect(mapStateToProps, null)(Content));
