import React from "react";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 pb-0">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12">
                <h3 className="h3-sm fw-6 txt-blue mb-4">
                  Privacy Policy for ALG Limited
                </h3>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    At alglimited.com,one of our main priorities is the privacy
                    of our visitors. This Privacy Policy document contains types
                    of information that is collected and recorded by
                    alglimited.com and how we use it.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in
                    alglimited.com. This policy is not applicable to any
                    information collected offline or via channels other than
                    this website.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">Consent</h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its terms.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                Information we collect
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        1.
                      </span>
                      The personal information that you are asked to provide,
                      and the reasons why you are asked to provide it, will be
                      made clear to you at the point we ask you to provide your
                      personal information.
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        2.
                      </span>
                      If you contact us directly, we may receive additional
                      information about you such as your name, email address,
                      phone number, the contents of the message and/or
                      attachments you may send us, and any other information you
                      may choose to provide.
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        3.
                      </span>
                      When you register for an Account, we may ask for your
                      contact information, including items such as name, company
                      name, address, email address, and telephone number.
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        4.
                      </span>
                      We may collect some images (only if the user agree to
                      upload) to improve our apps functionality and to give our
                      customer the best shipping experience.Though It is also
                      not mandatory for a user to upload his/her image (as
                      profile image).
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                How we use your information
              </h3>
              <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>
                {" "}
                We use the information we collect in various ways, including to:
              </h4>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <i className="icofont-check"></i>Provide, operate, and
                      maintain our website
                    </li>
                    <li>
                      <i className="icofont-check"></i>Improve, personalize, and
                      expand our website
                    </li>
                    <li>
                      <i className="icofont-check"></i>Improve, personalize, and
                      expand our mobile applications.
                    </li>
                    <li>
                      <i className="icofont-check"></i>Understand and analyze
                      how you use our website
                    </li>
                    <li>
                      <i className="icofont-check"></i> Develop new products,
                      services, features, and functionality
                    </li>
                    <li>
                      <i className="icofont-check"></i> Communicate with you,
                      either directly or through one of our partners, including
                      for customer service, to provide you with updates and
                      other information relating to the website, and for
                      marketing and promotional purposes
                    </li>
                    <li>
                      <i className="icofont-check"></i>Send you emails
                    </li>{" "}
                    <br></br>
                    <li>
                      <i className="icofont-check"></i> Find and prevent fraud
                    </li>
                    <li>
                      <i className="icofont-check"></i> Collected images are
                      safely stored on our backend server and can be deleted
                      anytime upon a request from the actual uploader of the
                      image.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">Log Files</h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  alglimited.com follows a standard procedure of using log
                  files. These files log visitors when they visit websites. All
                  hosting companies do this and a part of hosting services'
                  analytics. The information collected by log files include
                  internet protocol (IP) addresses, browser type, Internet
                  Service Provider (ISP), date and time stamp, referring/exit
                  pages, and possibly the number of clicks. These are not linked
                  to any information that is personally identifiable. The
                  purpose of the information is for analyzing trends,
                  administering the site, tracking users' movement on the
                  website, and gathering demographic information.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                Advertising Partners Privacy Policies
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of alglimited.com.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on
                  alglimited.com, which are sent directly to users' browser.
                  They automatically receive your IP address when this occurs.
                  These technologies are used to measure the effectiveness of
                  their advertising campaigns and/or to personalize the
                  advertising content that you see on websites that you visit.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Note that alglimited.com has no access to or control over
                  these cookies that are used by third-party advertisers.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                Third Party Privacy Policies
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  alglimited.com's Privacy Policy does not apply to other
                  advertisers or websites. Thus, we are advising you to consult
                  the respective Privacy Policies of these third-party ad
                  servers for more detailed information. It may include their
                  practices and instructions about how to opt-out of certain
                  options.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                CCPA Privacy Rights (Do Not Sell My Personal Information)
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Under the CCPA, among other rights, California consumers have
                  the right to:
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Request that a business that collects a consumer's personal
                  data disclose the categories and specific pieces of personal
                  data that a business has collected about consumers.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Request that a business delete any personal data about the
                  consumer that a business has collected.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Request that a business that sells a consumer's personal data,
                  not sell the consumer's personal data.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                GDPR Data Protection Rights
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  We would like to make sure you are fully aware of all of your
                  data protection rights. Every user is entitled to the
                  following:
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to access – You have the right to request copies of
                  your personal data. We may charge you a small fee for this
                  service.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate. You
                  also have the right to request that we complete the
                  information you believe is incomplete.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                Children's Information
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  alglimited.com does not knowingly collect any Personal
                  Identifiable Information from children under the age of 13. If
                  you think that your child provided this kind of information on
                  our website, we strongly encourage you to contact us
                  immediately and we will do our best efforts to promptly remove
                  such information from our records.
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
