import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import "./tabset.css";
import { connect } from "react-redux";
import { DivideCircle, FolderPlus } from "react-feather";
import {
  uploadRechargeRequestRedux,
  getAllRechargeRequestsOfSingleUserRedux,
  getAllRechargeOfSingleUserRedux,
} from "../../../actions/index";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import man from "../assets/images/dashboard/plus image.jpeg";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
export class Tabset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      imageUrl: man,
      method: "",
      file: "",
      loading: false,
      submit_loader: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.user.uid) {
      this.props.getAllRechargeRequestsOfSingleUserRedux(this.props.user.uid);
      this.props.getAllRechargeOfSingleUserRedux(this.props.user.uid);
    }
  };
  componentWillReceiveProps = async (nextProps) => {
    if (this.props.user.uid !== nextProps.user.uid) {
      this.props.getAllRechargeRequestsOfSingleUserRedux(this.props.user.uid);
      this.props.getAllRechargeOfSingleUserRedux(this.props.user.uid);
    }
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);

    return t.toLocaleDateString("en-US");
  };

  renderRechargeRequestStatus = (status) => {
    let backgroundColor;
    let icofont;
    if (status === "pending") {
      backgroundColor = "#f99322";
      icofont = "icofont-spinner";
    }
    if (status === "recharged") {
      backgroundColor = "darkgreen";
      icofont = "icofont-like";
    }
    if (status === "rejected") {
      backgroundColor = "red";
      icofont = "icofont-ui-close";
    }

    return (
      <div
        className=" icon-left no-shadow align-self-center "
        style={{
          fontSize: "85%",
          fontFamily: "sans-serif",

          padding: "7px",
          color: backgroundColor,
        }}
      >
        <i className={icofont}></i> {status}
      </div>
    );
  };
  getPaymentMethod = (recharge) => {
    if (recharge.paymentMethod === "Cash") {
      return "Cash";
    }
    if (recharge.paymentMethod === "Bank") {
      return recharge.bankName;
    }
    if (recharge.paymentMethod === "Mobile Banking") {
      return recharge.mobileBanking;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { user } = this.props;
    if (this.state.loading) {
      alert("please wait for the image to be uploded.");
      return;
    }
    if (this.state.imageUrl === man) {
      alert("Please upload your transactions copy.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    const rechargeId = Math.floor(Math.random() * Date.now());
    console.log(rechargeId);
    this.setState({ submit_loader: true });
    await this.props.uploadRechargeRequestRedux({
      status: "pending",
      rechargeId,
      userId: user.uid,
      ...this.state,
    });
    toast.success(
      "Your recharge Request is submitted! Our team member will confirm it soon"
    );
    this.setState({
      amount: "",
      imageUrl: man,
      method: "",
      file: "",
      loading: false,
      submit_loader: false,
    });
  };
  render() {
    const { user } = this.props;

    console.log(this.props.rechargeRequestsArray);

    return (
      <Fragment>
        <Tabs>
          <TabList
            className="nav nav-tabs tab-coupon"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Tab className="nav-link">Recharge Now!</Tab>
            <Tab className="nav-link">কিভাবে রিচার্জ করবেন</Tab>
            <Tab className="nav-link">Recharge requests</Tab>
            <Tab className="nav-link">Recharge history</Tab>
          </TabList>
          <TabPanel style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                maxWidth: "700px",
                borderRadius: "10px",
                background: "purple",
                padding: "40px",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div
                  className="form-row mb-4"
                  style={{
                    fontSize: "160%",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  ছবি অথবা স্ক্রিনশট আপলোড করুন
                </div>
                <div
                  className="form-row mb-2"
                  style={{
                    fontSize: "130%",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Amount
                </div>
                <div className="form-row mb-4">
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    placeholder="Enter Amount"
                    style={{ fontSize: "1rem" }}
                    onChange={this.handleChange}
                    value={this.state.amount}
                    required
                  />
                </div>
                <div
                  className="form-row mb-2"
                  style={{
                    fontSize: "130%",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Method
                </div>
                <div className="form-row mb-4">
                  {" "}
                  <select
                    style={{ border: "0px", marginTop: "7px" }}
                    value={this.state.method}
                    onChange={this.handleChange}
                    name="method"
                    className="custom-select"
                    required
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Brac Bank">Brac Bank</option>
                    <option value="City Bank">City Bank</option>
                    <option value="Islami Bank">Islami Bank</option>
                    <option value="Bkash">Bkash</option>
                    <option value="Rocket">Rocket</option>
                    <option value="Nagad">Nagad</option>
                  </select>
                </div>
                <div
                  className="form-row mb-2"
                  style={{
                    fontSize: "130%",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Upload Image
                </div>
                <div className="form-row mb-4">
                  <div
                    className="box-input-file"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {this.state.loading ? (
                      <div
                        class="spinner-border text-light mt-3 ml-4"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <img
                          className="img-100 lazyloaded blur-up"
                          src={this.state.imageUrl}
                          alt="#"
                          style={{ zIndex: 10, cursor: "pointer" }}
                          onClick={() => {
                            document
                              .getElementById("upload-image-input")
                              .click();
                          }}
                        />

                        <input
                          id="upload-image-input"
                          className="upload"
                          type="file"
                          style={{
                            position: "absolute",
                            zIndex: 5,
                            maxWidth: "50px",
                            marginTop: "20px",
                          }}
                          onChange={(e) => this._handleImgChange(e, 0)}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div
                    className="col pt-3"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      style={{
                        minWidth: "100px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        minHeight: "30px",
                      }}
                    >
                      {!this.state.submit_loader && <>Upload</>}

                      <CircleLoader
                        loading={this.state.submit_loader}
                        color="white"
                        size={15}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TabPanel>
          <TabPanel>
            <form className="needs-validation user-add" noValidate="">
              <div className="form-row mt-6">
                <span>
                  <i
                    className="icofont-tick-boxed"
                    style={{
                      color: "purple",
                      marginRight: "3px",
                      fontSize: "130%",
                      fontWeight: "bold",
                    }}
                  ></i>
                </span>
                উপরূল্ল্যেখিত আমাদের যেকোন একাউন্টতে ( মোবাইল ব্যাংকিং, ব্যাংক
                ডিপোজিট অথবা ব্যাংক ট্রান্সফারের মাধ্যমে ) পেমেন্ট সম্পন্ন করুন
                ।
              </div>
              <div className="form-row mt-3">
                <span>
                  <i
                    className="icofont-tick-boxed"
                    style={{
                      color: "purple",
                      marginRight: "3px",
                      fontSize: "130%",
                      fontWeight: "bold",
                    }}
                  ></i>
                </span>
                Recharge now! অপশনটিতে ক্লিক করুন ।
              </div>

              <div className="form-row mt-3">
                <span>
                  <i
                    className="icofont-tick-boxed"
                    style={{
                      color: "purple",
                      marginRight: "3px",
                      fontSize: "130%",
                      fontWeight: "bold",
                    }}
                  ></i>
                </span>
                মোবাইল ব্যাংকিং, ব্যংক ট্রান্সফার এর ক্ষেত্রে আপনার
                ট্রানজেকশনটির ছবি তুলে আপলোড করুন ।ব্যাংক ডিপোজিটের ক্ষেত্রে
                জমার রিসিটের ছবি আপলোড করুন ।
              </div>
              <div className="form-row mt-3">
                <span>
                  <i
                    className="icofont-tick-boxed"
                    style={{
                      color: "purple",
                      marginRight: "3px",
                      fontSize: "130%",
                      fontWeight: "bold",
                    }}
                  ></i>
                </span>
                এমাউন্ট উল্লেখ করুন এবং আপলোড বাটনে ক্লিক করুন ।
              </div>
            </form>
          </TabPanel>

          <TabPanel>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>

                  <th scope="col">Recharge Id</th>

                  <th scope="col">Payment Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.props.rechargeRequestsArray &&
                this.props.rechargeRequestsArray.length > 0
                  ? this.props.rechargeRequestsArray.map((recharge, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{recharge.rechargeId}</td>
                        <td>{recharge.method}</td>
                        <td>{recharge.amount}Tk</td>
                        <td>
                          {this.renderRechargeRequestStatus(recharge.status)}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Recharge Id</th>
                  <th scope="col">Receit No</th>
                  <th scope="col">Payment Method</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Recharge By</th>
                </tr>
              </thead>
              <tbody>
                {this.props.rechargesArray &&
                this.props.rechargesArray.length > 0
                  ? this.props.rechargesArray.map((recharge, index) => (
                      <tr kye={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{recharge.rechargedAt}</td>
                        <td>{recharge.rechargeId}</td>
                        <td>{recharge.receitNo}</td>
                        <td>{this.getPaymentMethod(recharge)}</td>
                        <td>{recharge.amount}Tk</td>
                        <td>{recharge.rechargeBy}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.currentUser.rechargeRequests);
  console.log(state.currentUser.currentUser);

  return {
    allLots: state.lots.lots,
    rechargeRequestsArray: state.currentUser.rechargeRequests,
    rechargesArray: state.currentUser.recharge,
  };
};
export default connect(mapStateToProps, {
  uploadRechargeRequestRedux,
  getAllRechargeRequestsOfSingleUserRedux,
  getAllRechargeOfSingleUserRedux,
})(Tabset);
