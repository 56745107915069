import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Search } from "react-feather";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import {
  getAllP2PRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
} from "../../../actions/index";
import { withRouter } from "react-router-dom";
import PaymentModal from "./paymentModal";
export class MyRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",

      id: "",
      bookingId: "",
      name: "",
      quantity: "",
      totalWeight: "",
      selectCountry: "INDIA TO BANGLADESH",
      amount: "",
      description: "",
      receiversName: "",
      receiversAddress: "",
      receiversMobileNo: "",
      receiversPostcode: "",

      paymentStatus: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      searchFor: "",
    };
  }

  componentDidMount = async () => {
    if (this.props.currentUser && this.props.currentUser.id) {
      this.props.getAllP2PRedux(this.props.currentUser.id);
    }
  };
  componentWillReceiveProps = async (nextProps) => {
    if (nextProps.currentUser && nextProps.currentUser.id) {
      if (nextProps.currentUser.id !== this.props.currentUser.id) {
        this.props.getAllP2PRedux(nextProps.currentUser.id);
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    let date = new Date();

    if (this.state.loading) {
      alert("Please wait.. your image is uploading");
      return;
    }
    if (this.state.type === "upload") {
      let bookingObj = {
        id: date.getTime(),
        bookingId: "p2p" + date.getTime().toString(),
        date: date.toLocaleDateString("en-GB"),
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        totalWeight: this.state.totalWeight,
        receiversName: this.state.receiversName,
        receiversAddress: this.state.receiversAddress,
        receiversMobileNo: this.state.receiversMobileNo,
        receiversPostcode: this.state.receiversPostcode,
        selectCountry: this.state.selectCountry,
        userId: this.props.currentUser.id,
        status: "pending",
        paymentStatus: "Not Generated",
      };

      await this.props.uploadP2PRedux(bookingObj);
    } else if (this.state.type === "update") {
      let bookingObj = {
        id: this.state.id,
        bookingId: this.state.bookingId,
        date: this.state.date,
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        totalWeight: this.state.totalWeight,
        receiversName: this.state.receiversName,
        receiversAddress: this.state.receiversAddress,
        receiversMobileNo: this.state.receiversMobileNo,
        receiversPostcode: this.state.receiversPostcode,
        selectCountry: this.state.selectCountry,
        userId: this.state.userId,
        status: this.state.status,
        paymentStatus: this.state.paymentStatus,
      };
      await this.props.updateP2PRedux(bookingObj);
    }

    this.setState({
      name: "",
      quantity: "",
      totalWeight: "",
      receiversName: "",
      receiversAddress: "",
      receiversMobileNo: "",
      receiversPostcode: "",
      paymentStatus: "",
      selectCountry: "INDIA TO BANGLADESH",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
    });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { productObj } = this.state;
    const { p2p } = this.props;
    let renderableP2p = [];
    if (this.props.match.params.status == "my-request") {
      renderableP2p = p2p.filter(
        (product) =>
          product.status == "pending" ||
          product.status == "approved" ||
          (product.status.toLowerCase().includes("warehouse") &&
            !product.deliveryWarehouse)
      );
    } else if (this.props.match.params.status == "in-shipment") {
      renderableP2p = p2p.filter((product) => product.status == "in shipment");
    } else if (this.props.match.params.status == "delivered") {
      renderableP2p = p2p.filter((product) => product.status == "delivered");
    } else if (this.props.match.params.status.includes("warehouse")) {
      renderableP2p = p2p.filter(
        (product) =>
          product.status == this.props.match.params.status &&
          product.deliveryWarehouse
      );
    }

    if (this.state.searchFor) {
      renderableP2p = renderableP2p.filter((booking) => {
        return (
          booking.bookingId
            .toLowerCase()
            .includes(this.state.searchFor.toLowerCase()) ||
          booking.name
            .toLowerCase()
            .includes(this.state.searchFor.toLowerCase())
        );
      });
    }

    return (
      <Fragment>
        <PaymentModal booking={productObj} />
        <Breadcrumb title="My Requests" parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    My Requests
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Product"
                            onChange={this.handleSearchBarChange}
                            style={{ paddingLeft: 10 }}
                          />
                          <span>
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>

                    <button
                      className="btn"
                      data-toggle="modal"
                      data-target="#personalInfoModal"
                      onClick={() => {
                        this.setState({
                          name: "",
                          quantity: "",
                          totalWeight: "",
                          receiversName: "",
                          receiversAddress: "",
                          receiversMobileNo: "",
                          receiversPostcode: "",
                          selectCountry: "INDIA TO BANGLADESH",
                          amount: "",
                          description: "",
                          productObj: null,
                          loading: false,
                          imageUrl: man,
                          file: "",
                          type: "upload",
                        });
                      }}
                      style={{
                        backgroundColor: "rgb(0, 37, 76)",
                        padding: "10px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      New Request
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Booking Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Weight
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Shipping Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Payment Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderableP2p.length > 0 &&
                          renderableP2p.map((product, index) => (
                            <tr key={index}>
                              <th
                                scope="row"
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.bookingId}
                              </th>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.date}
                              </td>
                              <td>
                                <a href={product.imageUrl} target="_blank">
                                  <img
                                    style={{ height: 70, width: 70 }}
                                    src={
                                      product.imageUrl ? product.imageUrl : man
                                    }
                                  />
                                </a>
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.name}
                              </td>

                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.quantity}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.totalWeight}Kg
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.amount}Tk
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.shippingCost || 0}Tk
                              </td>

                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={() => {
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    color: "white",
                                    padding: "3px 7px",
                                    borderRadius: 5,
                                    backgroundColor:
                                      product.status == "pending"
                                        ? "darkorange"
                                        : product.status == "approved"
                                        ? "green"
                                        : product.status == "reject"
                                        ? "red"
                                        : product.status == "delivered"
                                        ? "darkgreen"
                                        : "cadetblue",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {product.status}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {product.paymentStatus == "Not Paid" ||
                                  product.paymentStatus == "Rejected" ? (
                                    <span
                                      style={{
                                        padding: 10,
                                        color: "white",
                                        padding: "7px 12px",
                                        borderRadius: 5,
                                        backgroundColor: "purple",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#request_payment_popup"
                                      onClick={() => {
                                        this.setState({
                                          productObj: product,
                                        });
                                      }}
                                    >
                                      Pay
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        padding: 10,
                                        color: "white",
                                        padding: "4px 7px",
                                        borderRadius: 5,
                                        fontSize: 14,
                                        backgroundColor: product
                                          ? product.paymentStatus ==
                                            "Not Generated"
                                            ? "gray"
                                            : product.paymentStatus ==
                                              "Not Paid"
                                            ? "orange"
                                            : product.paymentStatus == "Pending"
                                            ? "darkorange"
                                            : product.paymentStatus == "Paid"
                                            ? "green"
                                            : "red"
                                          : "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {product.paymentStatus}
                                    </span>
                                  )}
                                </div>
                              </td>
                              <td>
                                {product.status == "pending" ||
                                product.status == "reject" ? (
                                  <div
                                    className="row"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <i
                                      className="icofont-edit"
                                      data-toggle="modal"
                                      data-target="#personalInfoModal"
                                      onClick={() => {
                                        this.setState({
                                          id: product.id,
                                          bookingId: product.bookingId,
                                          name: product.name,
                                          quantity: product.quantity,
                                          totalWeight: product.totalWeight,
                                          receiversName: product.receiversName,
                                          receiversAddress:
                                            product.receiversAddress,
                                          receiversMobileNo:
                                            product.receiversMobileNo,
                                          receiversPostcode:
                                            product.receiversPostcode,
                                          paymentStatus: product.paymentStatus,
                                          selectCountry: product.selectCountry,
                                          amount: product.amount,
                                          description: product.description,
                                          imageUrl: product.imageUrl,
                                          type: "update",
                                          userId: product.userId,
                                          status: product.status,
                                          date: product.date,
                                        });
                                      }}
                                      style={{
                                        color: "green",
                                        marginRight: 8,
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                    <i
                                      className="icofont-trash"
                                      data-toggle="modal"
                                      data-target="#deleteExpenseModal"
                                      onClick={() => {
                                        this.setState({
                                          productObj: product,
                                        });
                                      }}
                                      style={{
                                        color: "red",
                                        marginLeft: 8,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                ) : product.status
                                    .toLowerCase()
                                    .includes("warehouse") ||
                                  product.status == "delivered" ? (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "3px 7px",
                                      borderRadius: 5,
                                      backgroundColor: "darkgreen",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    received
                                  </div>
                                ) : product.status == "in shipment" ? (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "3px 7px",
                                      borderRadius: 5,
                                      backgroundColor: "darkgreen",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    In Shipment
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "3px 7px",
                                      borderRadius: 5,
                                      backgroundColor: "#007d80",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#instructionModal"
                                  >
                                    Instruction
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "50%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  New Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT IMAGE
                    </label>
                    <div
                      className="box-input-file"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border text-light mt-3 ml-4"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <img
                            className="img-100 lazyloaded blur-up"
                            src={this.state.imageUrl}
                            alt="#"
                            style={{
                              zIndex: 10,
                              cursor: "pointer",
                              border: "1px solid gainsboro",
                            }}
                            onClick={() => {
                              document
                                .getElementById("upload-image-input")
                                .click();
                            }}
                          />

                          <input
                            id="upload-image-input"
                            className="upload"
                            type="file"
                            style={{
                              position: "absolute",
                              zIndex: 5,
                              maxWidth: "50px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => this._handleImgChange(e, 0)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Product Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      DESCRIPTION
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Ex: size, color, other details etc"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL QUANTITY (maximum 3)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      onChange={this.handleChange}
                      value={this.state.quantity}
                      id="exampleFormControlInput1"
                      placeholder="Enter Product Quantity"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      max={3}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL WEIGHT
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalWeight"
                      onChange={this.handleChange}
                      value={this.state.totalWeight}
                      id="exampleFormControlInput1"
                      placeholder="Enter Total Weight (Kg)"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL PRICE
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      onChange={this.handleChange}
                      value={this.state.amount}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount (BDT)"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SELECT COUNTRY
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="selectCountry"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.selectCountry}
                    >
                      <option value="INDIA TO BANGLADESH">
                        INDIA TO BANGLADESH
                      </option>
                      <option value="BANGLADESH TO INDIA">
                        BANGLADESH TO INDIA
                      </option>
                      <option value="CHINA TO BANGLADESH">
                        CHINA TO BANGLADESH
                      </option>
                      <option value="BANGLADESH TO CHINA">
                        BANGLADESH TO CHINA
                      </option>
                      <option value="USA TO BANGLADESH">
                        USA TO BANGLADESH
                      </option>
                      <option value="BANGLADESH TO USA">
                        BANGLADESH TO USA
                      </option>
                      <option value="DUBAI TO BANGLADESH">
                        DUBAI TO BANGLADESH
                      </option>
                      <option value="BANGLADESH TO DUBAI">
                        BANGLADESH TO DUBAI
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RECEIVER'S NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receiversName"
                      onChange={this.handleChange}
                      value={this.state.receiversName}
                      id="exampleFormControlInput1"
                      placeholder="Receiver's Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RECEIVER'S ADDRESS
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receiversAddress"
                      onChange={this.handleChange}
                      value={this.state.receiversAddress}
                      id="exampleFormControlInput1"
                      placeholder="Receiver's Address"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RECEIVER'S MOBILE NO
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receiversMobileNo"
                      onChange={this.handleChange}
                      value={this.state.receiversMobileNo}
                      id="exampleFormControlInput1"
                      placeholder="Receiver's Mobile No"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RECEIVER'S POST CODE
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receiversPostcode"
                      onChange={this.handleChange}
                      value={this.state.receiversPostcode}
                      id="exampleFormControlInput1"
                      placeholder="Receiver's Postcode"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", maxWidth: "70%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Product
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to remove this Product?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.totalWeight}Kg</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteP2PRedux(productObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Product Image</td>
                      <td style={{ fontWeight: "bold" }}>Product Name</td>
                      <td style={{ fontWeight: "bold" }}>Total Quantity</td>
                      <td style={{ fontWeight: "bold" }}>Total Weight</td>
                      <td style={{ fontWeight: "bold" }}>Total Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.totalWeight}Kg</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Shipping Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.shippingCost) || "0"}Tk
                      </span>
                    </div>

                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          paddingTop: 0,
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.status == "pending"
                              ? "darkorange"
                              : productObj.status == "approved"
                              ? "green"
                              : productObj.status == "reject"
                              ? "red"
                              : productObj.status == "delivered"
                              ? "darkgreen"
                              : "cadetblue"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.status}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Country:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.selectCountry}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Payment Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.paymentStatus == "Not Generated"
                              ? "gray"
                              : productObj.paymentStatus == "Not Paid"
                              ? "orange"
                              : productObj.paymentStatus == "Pending"
                              ? "darkorange"
                              : productObj.paymentStatus == "Paid"
                              ? "green"
                              : "red"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.paymentStatus}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Product Details:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.description}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Booking Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      From Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.fromWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouseReceiveDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Assigned Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.assignedDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      To Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.toWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouseReceiveDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversName}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversAddress}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversMobileNo}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversPostcode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="instructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", maxWidth: "70%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Now send your products to any of our werehouse address listed
                  below (Further Instructions)
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "purple",
                        padding: 10,
                        color: "white",
                        borderRadius: 5,
                        maxWidth: 200,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      India Warehouse: 54, Alimuddin Street, Haji Md Mohsin
                      Square, JanBazar, Taltala, kolkata, West Bengal, 700014
                      Phone: 8327018844
                    </div>
                    <div
                      style={{
                        backgroundColor: "purple",
                        padding: 10,
                        color: "white",
                        borderRadius: 5,
                        maxWidth: 200,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Bangladesh Warehouse: 37/2 Pritom-Zaman Tower, Ground
                      Floor, Shop-8,Culvert Road, Dhaka-1000. Bangladesh
                      Phone:+8801885994310
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2p,
    currentUser: state.currentUser.currentUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllP2PRedux,
    uploadP2PRedux,
    updateP2PRedux,
    deleteP2PRedux,
  })(MyRequest)
);
