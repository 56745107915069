const INITIAL_STATE = {
  orders: [],
  ordersArrayOfSingleUser: [],
  expressOrders: [],
  sourcings: [],
};

const setOrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_ORDERS":
      return { ...state, orders: [...action.payload] };
    case "GET_ALL_PARCELS_OF_SINGLE_USER":
      return { ...state, ordersArrayOfSingleUser: [...action.payload] };
    case "GET_ALL_SOURCINGS":
      return { ...state, sourcings: [...action.payload] };

    case "UPDATE_PARCEL_OF_USER":
      const newArray = state.ordersArrayOfSingleUser.map((order) => {
        if (order.parcelId === action.payload.parcelId) {
          return action.payload;
        } else {
          return order;
        }
      });
      return { ...state, ordersArrayOfSingleUser: newArray };
    case "GET_ALL_RECEIVED_EXPRESS_BOOKINGS_OF_SINGLE_USER":
      return { ...state, expressOrders: [...action.payload] };
    case "UPDATE_ORDER":
      const updateordersArrayOfSingleUser = state.ordersArrayOfSingleUser.map(
        (order) => {
          if (order.parcelId === action.payload.parcelId) {
            return action.payload;
          } else {
            return order;
          }
        }
      );

      return {
        ...state,
        ordersArrayOfSingleUser: updateordersArrayOfSingleUser,
      };
    case "UPDATE_MULTIPLE_ORDERS":
      const newOrdersArray = state.ordersArrayOfSingleUser.map((order) => {
        console.log(action.payload.paidInvoices.length);
        const newOrder = action.payload.paidInvoices.find(
          (parcelId) => parcelId == order.parcelId
        );
        console.log(newOrder);
        if (newOrder) {
          return { ...order, paymentRequest: "pending" };
        } else {
          return order;
        }
      });
      console.log(newOrdersArray);
      return { ...state, ordersArrayOfSingleUser: newOrdersArray };
    case "UPDATE_MULTIPLE_SOURCINGS":
      const newOrdersArraySourcings = state.sourcings.map((order) => {
        const newOrder = action.payload.paidInvoices.find((parcel) => {
          if (parcel.category === "sold-products") {
            return parcel.paymentId == order.paymentId;
          } else {
            return parcel.id == order.id;
          }
        });
        console.log(newOrder);
        if (newOrder) {
          return {
            ...order,
            paymentRequest: "pending",
          };
        } else {
          return order;
        }
      });
      console.log(newOrdersArraySourcings);
      return { ...state, sourcings: newOrdersArraySourcings };
    case "UPDATE_MULTIPLE_ORDERS_EXPRESS":
      const newOrdersArrayExpress = state.expressOrders.map((order) => {
        console.log(action.payload.paidInvoices.length);
        const newOrder = action.payload.paidInvoices.find(
          (bookingId) => bookingId == order.bookingId
        );
        console.log(newOrder);
        if (newOrder) {
          return { ...order, paymentRequest: "pending" };
        } else {
          return order;
        }
      });
      console.log(newOrdersArrayExpress);
      return { ...state, expressOrders: newOrdersArrayExpress };

    default:
      return { ...state };
  }
};
export default setOrdersReducer;
