import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  User,
} from "react-feather";

export const MENUITEMSFORUSER = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "My Profile",
    icon: User,
    type: "link",
    active: false,
    path: "/user/edit-profile",
  },
  {
    title: "P2P As Agent",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p-agent-register/new",
        title: "Register As P2P Agent",
        type: "link",
      },
    ],
  },
  {
    title: "P2P As Customer",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p/my-request",
        title: "My Request",
        type: "link",
      },
      {
        path: "/p2p/in-shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/delivered",
        title: "Delivered",
        type: "link",
      },
    ],
  },

  {
    title: "My Bookings",
    icon: Clipboard,
    type: "link",
    active: false,
    path: "/user-my-booking",
  },

  {
    title: "My Parcel",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-my-parcel",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-my-parcel-express",
        title: "Express",
        type: "link",
      },
    ],
  },

  {
    title: "My Invoice",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-my-invoice",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-my-invoice-express",
        title: "Express",
        type: "link",
      },
      {
        path: "/user-invoice-sourcing",
        title: "Sourcing/Purchasing/Stock",
        type: "link",
      },
    ],
  },
  {
    title: "My Payments",
    icon: DollarSign,
    type: "link",
    active: false,
    path: "/user-my-payment",
  },

  {
    title: "All Delivered",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-all-delivered",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-all-delivered-express",
        title: "Express",
        type: "link",
      },
    ],
  },
  {
    title: "My Refund",
    icon: Box,
    type: "link",
    active: false,
    path: "/user-my-refund",
  },
  {
    title: "Terms & Condition",
    path: "/user/terms-and-condition",
    icon: Chrome,
    type: "link",
    active: false,
  },
];
export const MENUITEMSFORP2PAGENT = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "My Profile",
    icon: User,
    type: "link",
    active: false,
    path: "/user/edit-profile",
  },
  {
    title: "P2P As Agent",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        title: "Warehouse Product",

        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/warehouse/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/warehouse/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/warehouse/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/warehouse/Dubai warehouse",
          },
          {
            title: "Banlgadesh Warehouse",
            type: "link",
            path: "/p2p/warehouse/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/agent2/my-shipment-request",
        title: "My Request",
        type: "link",
      },
      {
        path: "/p2p/agent/in-shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/agent/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/agent/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/agent/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/agent/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/agent/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/agent/delivered",
        title: "Delivered",
        type: "link",
      },
    ],
  },
  {
    title: "P2P As Customer",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p/my-request",
        title: "My Request",
        type: "link",
      },
      {
        path: "/p2p/in-shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/delivered",
        title: "Delivered",
        type: "link",
      },
    ],
  },

  {
    title: "My Bookings",
    icon: Clipboard,
    type: "link",
    active: false,
    path: "/user-my-booking",
  },

  {
    title: "My Parcel",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-my-parcel",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-my-parcel-express",
        title: "Express",
        type: "link",
      },
    ],
  },

  {
    title: "My Invoice",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-my-invoice",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-my-invoice-express",
        title: "Express",
        type: "link",
      },
      {
        path: "/user-invoice-sourcing",
        title: "Sourcing/Purchasing/Stock",
        type: "link",
      },
    ],
  },
  {
    title: "My Payments",
    icon: DollarSign,
    type: "link",
    active: false,
    path: "/user-my-payment",
  },

  {
    title: "All Delivered",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/user-all-delivered",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/user-all-delivered-express",
        title: "Express",
        type: "link",
      },
    ],
  },
  {
    title: "My Refund",
    icon: Box,
    type: "link",
    active: false,
    path: "/user-my-refund",
  },
  {
    title: "Terms & Condition",
    path: "/user/terms-and-condition",
    icon: Chrome,
    type: "link",
    active: false,
  },
];
