import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import Tabset from "./tabset";
import { connect } from "react-redux";
import man from "../assets/images/dashboard/user2.jpg";
import CountUp from "react-countup";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
} from "react-feather";
import { uploadImageRedux } from "../../../actions/index";

export class RechargeWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [man],
      file: "",
      toggleModal: true,
    };
  }

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { pictures } = this.state;

    reader.onloadend = () => {
      pictures[i] = reader.result;
      this.setState({
        file: file,
        pictures,
        user: null,
      });
    };
    if (file) {
      const { user } = this.props;
      reader.readAsDataURL(file);
      const imgUrl = await this.props.uploadImageRedux(user, file);
      console.log(imgUrl);
      pictures[i] = imgUrl;
      this.setState({
        pictures,
      });
      console.log(pictures);
    }
  };
  startToggleModal = async (userObj) => {
    if (userObj == null) {
      this.setState({ toggleModal: !this.state.toggleModal, user: null });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        user: userObj,
      });
    }
  };

  render() {
    const { allOrders, payments, recharges, user, parcelsArray } = this.props;

    // getting total my due
    let myDue = 0;
    const UnPaidInvoices = parcelsArray.filter(
      (parcel) => parcel.invoiceStatus === "Not Paid"
    );
    UnPaidInvoices.forEach((parcel) => {
      myDue = myDue + parcel.finalTotal;
      return myDue;
    });

    // getting my Parcels
    const myParcels = parcelsArray.filter((parcel) => !parcel.parcelStatus);

    // getting all Delivered
    const allDeliverd = parcelsArray.filter(
      (parcel) => parcel.parcelStatus === "Delivered"
    );

    // need to add additional info Parcels
    const addAdditionalInfoParcels = parcelsArray.filter(
      (parcel) => !parcel.editRequested
    );
    console.log(user);
    return (
      <Fragment>
        {/* <Breadcrumb title={user && user.displayName} parent="Users" /> */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: "6rem" }}>
            <>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-warning card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Navigation className="font-warning" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Wallet
                        </span>
                        <h3 className="mb-0">
                          Tk <CountUp className="counter" end={user.myWallet} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden  widget-cards">
                  <div className="bg-secondary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Box className="font-secondary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Due
                        </span>
                        <h3 className="mb-0">
                          Tk &nbsp;
                          <CountUp className="counter" end={myDue} />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-primary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <MessageSquare className="font-primary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Parcel
                        </span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={myParcels.length} />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-danger card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Users className="font-danger" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          Total Received Parcel
                        </span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={allDeliverd.length}
                          />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  বিকাশ,রকেট,নগদ পেমেন্টের ক্ষেত্রে পেমেন্ট দিয়ে ট্রানজেকশনটির
                  স্ক্রীনশট নিচে আপলোড করুন।সরাসরি ব্যংক ডিপোজিট এর ক্ষেত্রে
                  আপনার জমার স্লিপের ছবি তুলে আপলোড করুন। ধন্যবাদ
                </div>
                <div className="card-body">
                  <Tabset
                    user={user}
                    startToggleModal={this.startToggleModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    parcelsArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.currentUser.currentUser,
    payments: state.currentUser.payments,
    recharges: state.currentUser.recharge,
    user: state.currentUser.currentUser,
  };
};

export default connect(mapStateToProps, { uploadImageRedux })(RechargeWallet);
