import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
class Content extends Component {
  state = { noticeObj: null };

  componentDidMount = async () => {
    console.log(this.props.match.params.noticeId);
    if (this.props.allNotices && this.props.allNotices.length > 0) {
      const noticeObj = this.props.allNotices.find(
        (notice) => notice.id == this.props.match.params.noticeId
      );
      this.setState({ noticeObj });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allNotices && nextProps.allNotices.length > 0) {
      const noticeObj = nextProps.allNotices.find(
        (notice) => notice.id == nextProps.match.params.noticeId
      );
      this.setState({ noticeObj });
    }
  };

  render() {
    const { noticeObj } = this.state;
    return (
      <>
        <main id="body-content">
          <section className="wide-tb-80">
            <div className="container pos-rel">
              <div className="row align-items-start">
                <div className="col-md-12 col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-left">
                        <img
                          src="images/blog_single.jpg"
                          alt=""
                          className="rounded mb-4"
                        />
                        <div className="meta-box">
                          <a href="#">Business</a> <span>/</span>{" "}
                          {noticeObj && noticeObj.createdAt}
                        </div>
                        <h4 className="h4-md mb-3 fw-7 txt-blue">
                          {noticeObj && noticeObj.noticeTitle}
                        </h4>
                        <pre>{noticeObj && noticeObj.noticeBody}</pre>
                      </div>
                      <div className="row align-items-center mt-5">
                        <div className="col-md-auto">
                          <div className="tags">
                            <a href="#">Outstanding</a>
                            <a href="#">Lifestyle</a>
                            <a href="#">Travel</a>
                          </div>
                        </div>
                        <div className="col-md-auto ml-auto">
                          <div className="share-this">
                            <div className="d-inline-flex align-items-center">
                              Share it:
                              <a
                                href="#"
                                className="rounded-circle tw"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Twitter"
                              >
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a
                                href="#"
                                className="rounded-circle ff"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Facebook"
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a
                                href="#"
                                className="rounded-circle ln"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Linkedin"
                              >
                                <i className="fab fa-linkedin"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <form
                        action="#"
                        method="post"
                        noValidate="novalidate"
                        className="rounded-field"
                      >
                        <div className="form-row mb-4">
                          <div className="col">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Your Name"
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-row mb-4">
                          <div className="col">
                            <textarea
                              rows="7"
                              placeholder="Message"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-row">
                          <button
                            type="submit"
                            className="form-btn btn-theme bg-orange"
                          >
                            Post Comment{" "}
                            <i className="icofont-rounded-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <aside className="sidebar-spacer row">
                    <div className="sidebar-primary col-lg-12 col-md-6">
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Search</h3>
                        <form className="flex-nowrap col ml-auto footer-subscribe p-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search …"
                          />
                          <button
                            type="submit"
                            className="btn btn-theme bg-orange"
                          >
                            <i className="icofont-search p-0"></i>
                          </button>
                        </form>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                        <div className="blog-list-footer">
                          <ul className="list-unstyled">
                            {this.props.allNotices &&
                            this.props.allNotices.length > 0
                              ? this.props.allNotices
                                  .slice(-2)
                                  .map((notice) => (
                                    <li key={notice.id}>
                                      <div className="media">
                                        <div className="post-thumb">
                                          <img
                                            src="images/post_thumb_2.jpg"
                                            alt=""
                                            className="rounded-circle"
                                          />
                                        </div>
                                        <div className="media-body post-text">
                                          <h5 className="mb-3 h5-md">
                                            <Link
                                              to={`/recent-news/${notice.id}`}
                                            >
                                              {notice.noticeTitle.slice(0, 30)}
                                            </Link>
                                          </h5>
                                          <p>
                                            {notice.noticeBody.slice(0, 50)}
                                          </p>

                                          <div className="comment-box">
                                            <span>
                                              <i className="icofont-ui-calendar"></i>
                                              {notice.createdAt}
                                            </span>
                                            <span>
                                              <Link
                                                to={`/recent-news/${notice.id}`}
                                              >
                                                <i className="icofont-speech-comments"></i>
                                                25
                                              </Link>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                              : null}
                          </ul>
                        </div>
                      </div>
                      <div className="widget-wrap text-center bg-sky-blue rounded py-5">
                        <div className="mb-2">
                          <i className="icofont-headphone-alt icofont-4x"></i>
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-4">
                          Need help?
                        </h3>
                        <p>
                          Call our award-winning
                          <br /> support team 24/7
                        </p>
                        <a href="#" className="btn-theme bg-orange mt-3">
                          Get In Touch <i className="icofont-rounded-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="sidebar-secondary col-lg-12 col-md-6">
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                        <div className="blog-list-categories">
                          <ul className="list-unstyled">
                            <li className="active">
                              <a href=" href"> Business</a>
                            </li>
                            <li>
                              <a href="#">Logistics</a>
                            </li>
                            <li>
                              <a href="#">News</a>
                            </li>
                            <li>
                              <a href="#">Recent</a>
                            </li>
                            <li>
                              <a href="#">Transport</a>
                            </li>
                            <li>
                              <a href="#">Warehousing</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Our Photostream</h3>
                        <ul id="basicuse" className="photo-thumbs"></ul>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Text Widget</h3>
                        <p>
                          Explain to you how all this mistaken idea of hovered
                          link and praising pain was born and I will give you a
                          complete count of the system expound
                        </p>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allNotices: state.notices.notices,
  };
};
export default withRouter(connect(mapStateToProps)(Content));
