import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setBookingRequestRedux } from "./../actions/index";
import man from "./userPanel/assets/images/dashboard/plus image.jpeg";
import { CircleLoader } from "react-spinners";
import { uploadImageD2dExpressProduct } from "./firebase/firebase.utils";
class SearchSubmitModalDoortoDoor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      ctnQuantity: "",
      totalCbm: "",
      productContains: "liquid",
      productBrand: "Brand",
      productsTotalCost: "",
      productsImage: "",
      imageUrl: man,
      file: "",
      productQuantity: "",
      alternateTotalCbm: "",
      othersProductName: "",
      submit_loader: false,
      image_loader: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(value);
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    if (this.props.currentUser.displayName === "") {
      alert("Please log in to your account to request a booking");
      document.getElementById("modal-close-icon-door-to-door").click();
      return;
    }

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ image_loader: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageD2dExpressProduct(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ image_loader: false });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.props.currentUser.displayName === "") {
      alert("Please log in to your account to request a booking");
      document.getElementById("modal-close-icon-door-to-door").click();
      return;
    } else {
      if (this.state.image_loader) {
        alert("please wait for the image to be uploded");
        return;
      }
      this.setState({ submit_loader: true });
      await this.props.setBookingRequestRedux({
        userId: this.props.currentUser.uid,
        ...this.state,
        ...this.props.result,
        date: this.state.date.replaceAll("-", "/"),
      });
      toast.success(
        "your booking request is successful. Our agent will confirm your booking soon"
      );
      document.getElementById("modal-close-icon-door-to-door-1").click();
      this.setState({ submit_loader: false });
    }
    this.setState({
      date: "",
      ctnQuantity: "",
      totalCbm: "",
      productContains: "",
      productBrand: "",
      productsTotalCost: "",
      productsImage: "",
      imageUrl: man,
      file: "",
      alternateTotalCbm: "",
      productQuantity: "",
      othersProductName: "",
    });
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_search_submit_popup_door_to_door"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div className="modal-content search-submit-modal">
              <div className="modal-body p-0">
                <section className="pos-rel" style={{ background: "white" }}>
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close search-submit-modal-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="modal-close-icon-door-to-door-1"
                      onClick={() => {
                        this.setState({
                          date: "",
                          ctnQuantity: "",
                          totalCbm: "",
                          productContains: "",
                          productBrand: "",
                          productsTotalCost: "",
                          productsImage: "",
                          imageUrl: man,
                          file: "",
                          alternateTotalCbm: "",
                          productQuantity: "",
                        });
                      }}
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "orange" }}
                      ></i>
                    </a>
                    <div
                      className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col"
                        style={{ padding: "10px", backgroundColor: "#4e014e" }}
                      >
                        <div
                          className="row mt-4 mb-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "150%",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Approximate shipping cost:
                        </div>
                        {this.props.searchType === "express" ? (
                          <>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "25%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Parcel Type: &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result.parcelType}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "25%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Product Name: &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result.productName}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "25%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Parcel To: &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result.parcelTo}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "25%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Parcel Box: &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result.parcelBox} kg
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col">
                                <div
                                  className="center-head"
                                  style={{ margin: "0px" }}
                                >
                                  <span className="bg-light-gray txt-orange"></span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "200%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                Total
                              </div>
                              <div
                                style={{
                                  fontSize: "250%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result.total}Tk
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                color: "white",
                                fontFamily: "sans-serif",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              (IF 1 CBM= 167kg)
                            </div>
                            <div
                              className="row"
                              style={{
                                color: "orange",
                                fontFamily: "sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                padding: "30px 15px 0px 15px",
                              }}
                            >
                              **This total is calculated based on your choosed
                              destination country,parcel type and parcel box.
                              And it may change according to market rate if you
                              book later.
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Ship By
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.shipBy
                                  : ""}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Ship From
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.shipFrom
                                  : ""}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Product Type
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.productType
                                  : ""}
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Per kg
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.perKg
                                  : ""}
                                tk/kg
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  fontSize: "140%",
                                  color: "white",
                                }}
                              >
                                Total weight{" "}
                              </div>
                              <div
                                style={{
                                  fontSize: "140%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.weight
                                  : ""}{" "}
                                kg
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col">
                                <div
                                  className="center-head"
                                  style={{ margin: "0px" }}
                                >
                                  <span className="bg-light-gray txt-orange"></span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "200%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                Total
                              </div>
                              <div
                                style={{
                                  fontSize: "250%",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.props.result
                                  ? this.props.result.result
                                  : ""}
                                Tk
                              </div>
                            </div>
                            <div
                              className="row mt-2"
                              style={{
                                color: "white",
                                fontFamily: "sans-serif",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              (IF 1 CBM= 167kg)
                            </div>
                            <div
                              className="row"
                              style={{
                                color: "orange",
                                fontFamily: "sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                padding: "30px 15px 0px 15px",
                                paddingLeft: "20px",
                              }}
                            >
                              **৫ কেজির নিচের সকল পার্সেল এর দাম সাধারণ দামের
                              চেয়ে তুলনামূলক ভাবে বেশি থাকবে ।
                            </div>
                          </>
                        )}

                        <div className="row mt-3">
                          <div className="col">
                            <div
                              className="center-head"
                              style={{ marginBottom: "0px" }}
                            >
                              <span
                                className="bg-light-gray txt-orange"
                                style={{ textTransform: "none" }}
                              >
                                read carefully
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            color: "white",
                            paddingTop: "15px",
                            paddingBottom: "10px",
                          }}
                        >
                          উপরের রেটটি সম্ভাব্য রেট। কনফার্ম রেট পেতে নিচের তথ্য
                          প্রদান পূর্বক বুকিং করুন । বুকিং এর ২৪ ঘণ্টার মধ্যে
                          আপনার শিপমেন্টের সকল প্রকার খরচ আপনাকে জানিয়ে দেয়া
                          হবে।
                        </div>
                      </div>

                      <div
                        className="col-md-7 col-12"
                        style={{ background: "white" }}
                      >
                        <div className="px-3 m-4">
                          <h2
                            className="h2-xl fw-6"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                            }}
                          >
                            Detail information
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            // noValidate="noValidate"
                            className="rounded-field"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                Having trouble?
                                <span
                                  style={{
                                    color: "purple",
                                    marginLeft: "2px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp; contact support
                                </span>
                              </div>
                            </div>
                            <div className="form-row mb-1">Select Date:</div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  type="date"
                                  name="date"
                                  onChange={this.handleChange}
                                  value={this.state.date}
                                  className="form-control"
                                  placeholder="approx date"
                                  style={{
                                    borderRadius: "10rem",
                                    width: "60%",
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">
                              Carton quantity:
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    width: "60%",
                                  }}
                                  type="number"
                                  name="ctnQuantity"
                                  value={this.state.ctnQuantity}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="quantity"
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">Total CBM:</div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    width: "60%",
                                  }}
                                  type="number"
                                  name="totalCbm"
                                  value={this.state.totalCbm}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="total CBM"
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">
                              Product Quantity:
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    width: "60%",
                                  }}
                                  type="number"
                                  name="productQuantity"
                                  value={this.state.productQuantity}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="product quantity"
                                  required
                                />
                              </div>
                            </div>

                            {this.props.result &&
                            this.props.result.d2dSeaAir === "sea" ? null : (
                              <>
                                <div className="form-row mb-1">
                                  Products Total Cost:
                                </div>
                                <div className="form-row mb-2">
                                  <div className="col">
                                    <input
                                      style={{
                                        borderRadius: "10rem",
                                        width: "60%",
                                      }}
                                      type="number"
                                      name="productsTotalCost"
                                      value={this.state.productsTotalCost}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      placeholder="total Cost(BDT)"
                                      required
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="form-row mb-1">
                              Product Name (specific):
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    width: "60%",
                                  }}
                                  type="text"
                                  name="othersProductName"
                                  value={this.state.othersProductName}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="product name"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row mb-1">Products Image:</div>
                            <div className="form-row mb-4">
                              <div
                                className="box-input-file"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {this.state.image_loader ? (
                                  <div
                                    class="spinner-border mt-3 ml-4"
                                    role="status"
                                    style={{
                                      color: "purple",
                                      paddingTop: "25px",
                                    }}
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      className="img-100 lazyloaded blur-up"
                                      src={this.state.imageUrl}
                                      alt="#"
                                      style={{ zIndex: 10, cursor: "pointer" }}
                                      onClick={() => {
                                        document
                                          .getElementById("upload-image-input")
                                          .click();
                                      }}
                                    />
                                    <input
                                      id="upload-image-input"
                                      className="upload"
                                      type="file"
                                      style={{
                                        position: "absolute",
                                        zIndex: 5,
                                        maxWidth: "50px",
                                        marginTop: "20px",
                                      }}
                                      onChange={(e) =>
                                        this._handleImgChange(e, 0)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="form-row mt-3">
                              <div className="col">
                                <div
                                  className="center-head"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <span className="bg-light-gray txt-orange">
                                    Product information
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <div>
                                <div className="form-row mb-1">
                                  Product contains:
                                </div>
                                <div className="form-row">
                                  <select
                                    className="custom-select"
                                    name="productContains"
                                    value={this.state.productContains}
                                    onChange={this.handleChange}
                                    required
                                  >
                                    <option value="liquid">liquid</option>
                                    <option value="powder">powder</option>
                                    <option value="battery">battery</option>
                                    <option value="magnet">magnet</option>
                                    <option value="sensor">sensor</option>
                                    <option value="none">none</option>
                                  </select>
                                </div>
                              </div>
                              <div>
                                <div className="form-row mb-1">
                                  Brand/non-brand
                                </div>
                                <select
                                  name="productBrand"
                                  value={this.state.productBrand}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-select"
                                >
                                  <option value="Brand">Brand</option>
                                  <option value="Non Brand">Non Brand</option>
                                </select>
                              </div>
                            </div>
                            <div
                              className="row mt-5"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  background: "purple",
                                  color: "white",
                                  padding: "13px",
                                  borderRadius: "1rem",
                                  minWidth: "100px",
                                  minHeight: "50px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!this.state.submit_loader && <>Book now</>}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    result: state.result.result,
    searchType: state.result.searchType,
    currentUser: state.currentUser.currentUser,
  };
};
export default connect(mapStateToProps, {
  setBookingRequestRedux,
})(SearchSubmitModalDoortoDoor);
