import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";
import { updateRefundStatusRedux } from "../../../actions/index";
import { connect } from "react-redux";
export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };
  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      console.log(myData);
      myData.forEach((payment) => {
        newData.push({
          "Payment Id": payment.paymentId,
          "Paid At": payment.paidAt,
          "Payment Method": payment.paymentMethod,
          Amount: `${payment.amount} Tk`,
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index]
              ? newData[cellInfo.index][cellInfo.column.id]
              : null,
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      console.log(myData);
      myData.forEach((payment) => {
        newData.push({
          "Payment Id": payment.paymentId,
          "Paid At": payment.paidAt,
          "Payment Method": payment.paymentMethod,
          Amount: `${payment.amount} Tk`,
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }
    columns.push({
      Header: <b>Paid Invoices</b>,
      id: "orderDetails",
      accessor: (str) => "orderDetails",
      Cell: (row) => {
        const paymentObj = myData.find(
          (payment) => payment.paymentId === row.original["Payment Id"]
        );
        return (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id={`popover-positioned-bottom`}
                style={{ minWidth: "12%" }}
              >
                <Popover.Title
                  style={{ backgroundColor: "#13c9ca", color: "white" }}
                  as="h3"
                >
                  Invoices List
                </Popover.Title>
                <Popover.Content className="popover-body-container">
                  <div
                    style={{
                      paddingBottom: "10px",
                    }}
                  >
                    {" "}
                    {paymentObj.paidInvoice.map((parcelId) => (
                      <div
                        key={parcelId}
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: "5px",
                        }}
                      >
                        {parcelId}
                      </div>
                    ))}
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <button className="btn btn-secondary">invoices</button>
          </OverlayTrigger>
        );
      },
      style: {
        textAlign: "center",
      },
      sortable: false,
    });

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allLots: state.lots.lots,
  };
};
export default connect(mapStateToProps, { updateRefundStatusRedux })(Datatable);
