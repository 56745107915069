import {
  getAllExpressRatesParcel,
  getAllExpressRatesDocument,
  getAllBookingsOfSingleUser,
  getUserLoan,
  getAllReceivedExpressBookingsOfCurrentUser,
  getAllParcelsOfSingleUser,
  getAllRechargeRequestsOfSingleUser,
  getAllRechargeOfSingleUser,
  getAllPaymentOfSingleUser,
  getSingleBooking,
  setBookingRequest,
  getAllD2DRates,
  getAllLots,
  getAllSourcings,
  updateOrder,
  uploadImage,
  updateUser,
  uploadRechargeRequest,
  uploadPaymentRequest,
  uploadPaymentRequestExpress,
  uploadPaymentRequestSourcing,
  makePayment,
  updateRefundStatus,
  getAllNotices,
  getOrderTrackingResult,
  getBookingTrackingResult,
  sendOtp,
  verifyOtp,
  getAllBlogs,
  getAllForwarders,
  getAllp2p,
  getAllp2pAssignedAgent,
  getAllP2pAgent,
  getAllWarehouseProducts,
  uploadp2p,
  updatep2p,
  deletep2p,
  uploadPaymentRequestP2p,
} from "../components/firebase/firebase.utils";

export const setCurrentUserRedux = (userObj) => {
  return {
    type: "SET_CURRENT_USER",
    payload: userObj,
  };
};
export const addToCart = (productObj) => {
  return {
    type: "ADD_TO_CART",
    payload: productObj,
  };
};
export const removeFromCart = (productObj) => {
  return {
    type: "REMOVE_FROM_CART",
    payload: productObj,
  };
};

export const getAllExpressRatesParcelRedux = () => async (dispatch) => {
  const expressRatesParcelArray = await getAllExpressRatesParcel();
  dispatch({
    type: "GET_ALL_EXPRESS_RATES_PARCEL",
    payload: expressRatesParcelArray,
  });
};

export const getAllExpressRatesDocumentRedux = () => async (dispatch) => {
  const documentExpressRatesArray = await getAllExpressRatesDocument();
  dispatch({
    type: "GET_ALL_DOCUMENT_EXPRESS_RATES",
    payload: documentExpressRatesArray,
  });
};

export const getAllD2DRatesRedux =
  (freightType, country) => async (dispatch) => {
    const allD2DRatesAirArray = await getAllD2DRates(freightType, country);
    dispatch({
      type: "GET_ALL_D2D_RATES",
      payload: allD2DRatesAirArray,
    });
  };

export const getAllLotsRedux = () => async (dispatch) => {
  const lotsArray = await getAllLots();
  dispatch({ type: "GET_ALL_LOTS", payload: lotsArray });
};

export const getAllSourcingsRedux = (userId) => async (dispatch) => {
  const invoicesArray = await getAllSourcings(userId);
  dispatch({ type: "GET_ALL_SOURCINGS", payload: invoicesArray });
};
export const getAllForwarderRedux = () => async (dispatch) => {
  const forwarders = await getAllForwarders();
  dispatch({
    type: "GET_ALL_FORWARDERS",
    payload: forwarders,
  });
};

export const getAllBookingsOfSingleUserRedux = (userId) => async (dispatch) => {
  const bookingsArray = await getAllBookingsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_BOOKINGS_OF_SINGLE_USER", payload: bookingsArray });
};
export const getUserLoanRedux = (userId) => async (dispatch) => {
  const loanAmount = await getUserLoan(userId);
  dispatch({ type: "GET_USER_LOAN", payload: loanAmount });
};

export const getAllReceivedExpressBookingsOfCurrentUserRedux =
  (userId) => async (dispatch) => {
    const parcelsArray = await getAllReceivedExpressBookingsOfCurrentUser(
      userId
    );
    dispatch({
      type: "GET_ALL_RECEIVED_EXPRESS_BOOKINGS_OF_SINGLE_USER",
      payload: parcelsArray,
    });
  };

export const getSingleBookingRedux = (bookingId) => async (dispatch) => {
  const resultbookingObj = await getSingleBooking(bookingId);
  dispatch({ type: "GET_SINGLE_BOOKING", payload: resultbookingObj });
};

export const getAllParcelsOfSingleUserRedux = (userId) => async (dispatch) => {
  const parcelsArray = await getAllParcelsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_PARCELS_OF_SINGLE_USER", payload: parcelsArray });
};
export const getAllRechargeRequestsOfSingleUserRedux =
  (userId) => async (dispatch) => {
    const rechargeRequestArray = await getAllRechargeRequestsOfSingleUser(
      userId
    );
    dispatch({
      type: "GET_ALL_RECHARGE_REQUEST_OF_SINGLE_USER",
      payload: rechargeRequestArray,
    });
  };
export const getAllRechargeOfSingleUserRedux = (userId) => async (dispatch) => {
  const rechargeArray = await getAllRechargeOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_RECHARGE_OF_SINGLE_USER",
    payload: rechargeArray,
  });
};

export const setExpressResultRedux = (result) => {
  return {
    type: "SET_EXPRESS_RESULT",
    payload: result,
  };
};

export const setD2dResultRedux = (result) => {
  return {
    type: "SET_D2D_RESULT",
    payload: result,
  };
};

export const setFreightResultRedux = (result) => {
  return {
    type: "SET_FREIGHT_RESULT",
    payload: result,
  };
};

export const setBookingRequestRedux = (bookingObj) => async (dispatch) => {
  const uploadedBookingObj = await setBookingRequest(bookingObj);
  dispatch({
    type: "SET_BOOKING_REQUEST",
    payload: uploadedBookingObj,
  });
};

export const uploadImageRedux = (currentUser, file) => async (dispatch) => {
  const updatedUserObj = await uploadImage(currentUser, file);
  dispatch({
    type: "SET_IMAGE_URL",
    payload: updatedUserObj,
  });
};

export const uploadRechargeRequestRedux =
  (rechargeObject) => async (dispatch) => {
    const rechargeObj = await uploadRechargeRequest(rechargeObject);
    dispatch({
      type: "UPLOAD_RECHARGE_REQUEST",
      payload: rechargeObj,
    });
  };
export const uploadPaymentRequestRedux =
  (paymentObject) => async (dispatch) => {
    const paymentObj = await uploadPaymentRequest(paymentObject);
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS",
      payload: paymentObj,
    });
  };
export const uploadPaymentRequestSourcingRedux =
  (paymentObject) => async (dispatch) => {
    const paymentObj = await uploadPaymentRequestSourcing(paymentObject);
    dispatch({
      type: "UPDATE_MULTIPLE_SOURCINGS",
      payload: paymentObj,
    });
  };
export const uploadPaymentRequestExpressRedux =
  (paymentObject) => async (dispatch) => {
    const paymentObj = await uploadPaymentRequestExpress(paymentObject);
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS_EXPRESS",
      payload: paymentObj,
    });
  };
export const uploadPaymentRequestP2pRedux =
  (paymentObject) => async (dispatch) => {
    const p2pObj = await uploadPaymentRequestP2p(paymentObject);
    dispatch({
      type: "UPDATE_P2P",
      payload: p2pObj,
    });
  };

export const updateUserRedux = (updatedUser) => async (dispatch) => {
  const updatedUserObj = await updateUser(updatedUser);
  dispatch({
    type: "UPDATE_USER",
    payload: updatedUserObj,
  });
};

export const setParcelObj = (parcelObj) => {
  return {
    type: "SET_PARCEL_OBJ",
    payload: parcelObj,
  };
};

export const checkedValuesRedux = (array) => {
  return {
    type: "CHECKED_INVOICE_FOR_PAYMENT",
    payload: array,
  };
};

export const updateMyParcelArrayOfUser = (parcelObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(parcelObj);
  dispatch({
    type: "UPDATE_PARCEL_OF_USER",
    payload: updatedOrderObj,
  });
};
export const updateOrderRedux = (parcelObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(parcelObj);
  dispatch({
    type: "UPDATE_ORDER",
    payload: updatedOrderObj,
  });
};

export const updateRefundStatusRedux = (parcelObj) => async (dispatch) => {
  const updatedOrderObj = await updateRefundStatus(parcelObj);
  dispatch({
    type: "UPDATE_ORDER",
    payload: updatedOrderObj,
  });
};

export const getAllBlogsRedux = () => async (dispatch) => {
  const blogsArray = await getAllBlogs();
  dispatch({ type: "GET_ALL_BLOGS", payload: blogsArray });
};

export const makePaymentRedux =
  (total, invoicesToPay, currentUser, parcelsArray) => async (dispatch) => {
    const updatedUserObj = await makePayment(
      total,
      invoicesToPay,
      currentUser,
      parcelsArray
    );
    dispatch({
      type: "UPDATE_USER",
      payload: updatedUserObj,
    });
  };

export const getAllPaymentOfSingleUserRedux = (userId) => async (dispatch) => {
  const paymentArray = await getAllPaymentOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_PAYMENT_OF_SINGLE_USER",
    payload: paymentArray,
  });
};

export const getAllNoticesRedux = () => async (dispatch) => {
  const noticesArray = await getAllNotices();
  dispatch({ type: "GET_ALL_NOTICES", payload: noticesArray });
};

export const setIntroModal = (introModal) => async (dispatch) => {
  dispatch({ type: "SET_INTRO_MODAL", payload: introModal });
};

export const getOrderTrackingResultRedux = (trackingNo) => async (dispatch) => {
  const resultObj = await getOrderTrackingResult(trackingNo);
  dispatch({ type: "GET_ORDER_TRACKING_RESULT", payload: resultObj });
};
export const getBookingTrackingResultRedux =
  (trackingNo) => async (dispatch) => {
    const resultObj = await getBookingTrackingResult(trackingNo);
    dispatch({ type: "GET_BOOKING_TRACKING_RESULT", payload: resultObj });
  };

// send otp
export const sendOtpRedux = (number) => async (dispatch) => {
  dispatch({ type: "SET_NUMBER", payload: number });
};

// verify otp
export const verifyOtpRedux = (number, otp) => async (dispatch) => {
  const userObj = await verifyOtp(number, otp);
  dispatch({ type: "SET_CURRENT_USER", payload: userObj });
};

// total amount to pay
export const totalAmountToPayRedux = (total) => {
  console.log(total);

  return {
    type: "TOTAL_AMOUNT",
    payload: total,
  };
};

// P2P CRUD
export const getAllP2PRedux = (userId) => async (dispatch) => {
  const p2ps = await getAllp2p(userId);
  dispatch({
    type: "GET_ALL_P2PS",
    payload: p2ps,
  });
};
export const getAllP2PAssignedAgentRedux = (agentId) => async (dispatch) => {
  const p2ps = await getAllp2pAssignedAgent(agentId);
  dispatch({
    type: "GET_ALL_P2PS",
    payload: p2ps,
  });
};
export const getAllP2pAgentRequestRedux = (userId) => async (dispatch) => {
  const p2ps = await getAllP2pAgent(userId);
  dispatch({
    type: "GET_ALL_P2P_AGENT_REQUEST",
    payload: p2ps,
  });
};
export const getAllWarehouseProductsRedux =
  (status, currentUser) => async (dispatch) => {
    const p2ps = await getAllWarehouseProducts(status, currentUser);
    dispatch({
      type: "GET_ALL_P2PS",
      payload: p2ps,
    });
  };

export const uploadP2PRedux = (p2pObj) => async (dispatch) => {
  const uploadedp2pObj = await uploadp2p(p2pObj);
  dispatch({
    type: "UPLOAD_P2P",
    payload: uploadedp2pObj,
  });
};

export const updateP2PRedux = (p2pObj) => async (dispatch) => {
  const updatedp2pObj = await updatep2p(p2pObj);
  dispatch({
    type: "UPDATE_P2P",
    payload: updatedp2pObj,
  });
};
export const deleteP2PRedux = (p2pId) => async (dispatch) => {
  await deletep2p(p2pId);
  dispatch({
    type: "DELETE_P2P",
    payload: p2pId,
  });
};
