import React from "react";
import "./services.css";
const Services = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-80 bg-fixed what-we-offer1"></section>
        <section className="bg-white wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>what we offer</span>
                  Our Main Services
                </h1>
              </div>
              <div className="col-md-4 ">
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-1.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-vehicle-delivery-van"></i>
                      GROUND DELIVERY
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 ">
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-2.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-airplane-alt"></i>
                      AIR DELIVERY
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 ">
                <a href="service-details.html">
                  <div className="icon-box-1">
                    <img src="images/icon-box-3.jpg" alt="" />
                    <div className="text">
                      <i className="icofont-ship"></i>
                      SEA DELIVERY
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-gray">
          <div className="container-fluid p-0">
            <div className="row align-items-center no-gutters">
              <div className="col-lg-4 text-center support">
                <div className="px-5 wide-tb-100"></div>
              </div>
              <div className="col-lg-4 text-center safe-and-secure">
                <div className="px-5 wide-tb-100"></div>
              </div>
              <div className="col-lg-4 text-center fast-delivery ">
                <div
                  className="px-5 wide-tb-100"
                  style={{ position: "relative", zIndex: "999" }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Services;
