import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setBookingRequestRedux } from "./../actions/index";
import "./OrderTrackingModalResult.css";
class OrderTrackingModalResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      ctnQuantity: "",
      ctnHeight: "",
      ctnWidth: "",
      ctnLength: "",
      productContains: "",
      productBrand: "",
    };
  }

  render() {
    const { orderTrackingResult } = this.props;
    let parcelObj = {};
    if (orderTrackingResult) {
      parcelObj = orderTrackingResult.parcelsArray[0];
    }

    return (
      <>
        <div
          className="modal fade"
          id="request_order_tracking_result_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable request_popup"
            role="document"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="modal-content order-tracking-modal-result">
              <div className="modal-body p-0">
                <section className="pos-rel">
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close search-submit-modal-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="modal-close-icon-door-to-door"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "orange" }}
                      ></i>
                    </a>
                    {orderTrackingResult !== null ? (
                      <div
                        style={{
                          boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "#4e014e",
                          }}
                        >
                          <div
                            className="row mt-4 mb-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "150%",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {parcelObj.trackingNo}
                          </div>

                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Lot No: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {orderTrackingResult.lotArray.length == 1
                                ? orderTrackingResult.lotObj.lotNo
                                : orderTrackingResult.lotArray.map(
                                    (lot) => `${lot},`
                                  )}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Country: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {orderTrackingResult.lotObj.selectCountry}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Shipment method: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {orderTrackingResult.lotObj.shipmentMethod}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Shipping Line: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {orderTrackingResult.lotObj.shippingLine}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Carton No: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {orderTrackingResult.parcelsArray.map(
                                (parcel) => `${parcel.parcelId},`
                              )}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Delivery Date: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              {orderTrackingResult.lotObj.arrivalDate}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="px-3 m-4">
                            <h2
                              className="h2-xl fw-6"
                              style={{
                                color: "purple",
                                fontSize: "140%",
                                fontWeight: "bolder",
                              }}
                            >
                              Order Status{" "}
                              {orderTrackingResult.lotArray.length == 1 ? (
                                ""
                              ) : (
                                <span
                                  style={{ fontSize: "80%", transform: "none" }}
                                >
                                  for {orderTrackingResult.lotObj.lotNo}
                                </span>
                              )}
                            </h2>
                            <div clssName="row mt-3"></div>
                            <div className="row mt-5">
                              <div className="col order-tracking-bar">
                                {orderTrackingResult.lotObj
                                  .shipmentStatusScore >= 1 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-industries"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Abroad Warehouse <br />
                                      {parcelObj.dateofWarehouseReceive}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-industries"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Abroad Warehouse</p>
                                  </span>
                                )}
                                {orderTrackingResult.lotObj
                                  .shipmentStatusScore >= 2 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Abroad Customs
                                      <br />
                                      {
                                        orderTrackingResult.lotObj
                                          .abroadCustomsDate
                                      }
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Abroad Customs</p>
                                  </span>
                                )}
                                {orderTrackingResult.lotObj
                                  .shipmentStatusScore >= 3 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-airplane-alt"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Ready to Fly
                                      <br />
                                      {
                                        orderTrackingResult.lotObj
                                          .readyToFlyDate
                                      }
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-airplane-alt"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Ready to Fly</p>
                                  </span>
                                )}
                                {orderTrackingResult.lotObj
                                  .shipmentStatusScore >= 4 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Bangladesh Customs
                                      <br />
                                      {
                                        orderTrackingResult.lotObj
                                          .bangladeshCustomsDate
                                      }
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Bangladesh Customs</p>
                                  </span>
                                )}
                                {orderTrackingResult.lotObj
                                  .shipmentStatusScore >= 5 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-home"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Local Warehouse
                                      <br />
                                      {
                                        orderTrackingResult.lotObj
                                          .localWarehouseDate
                                      }
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-home"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Local Warehouse</p>
                                  </span>
                                )}

                                {parcelObj.parcelStatus == "Delivered" ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-tick-mark"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                   
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Delivered
                                      <br />
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-tick-mark"></i>
                                    </div>
                                    &nbsp; <p>Delivered</p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col">
                              <div
                                className="center-head"
                                style={{ marginBottom: "0px" }}
                              >
                                <span
                                  className="bg-light-gray"
                                  style={{
                                    textTransform: "none",
                                    color: "purple",
                                  }}
                                >
                                  শিপমেন্ট সংক্রান্ত নোটিশ
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: "80%",
                              padding: "10px",
                              paddingTop: "5px",
                              paddingBottom: "15px",
                            }}
                          >
                            {orderTrackingResult.lotObj.shipmentNotice}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "#4e014e",
                          }}
                        >
                          <div
                            className="row mt-4 mb-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "150%",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            Not found
                          </div>
                        </div>

                        <div>
                          <div className="px-3 m-4">
                            <div className="row mt-3"></div>
                            <div
                              className="row mt-5"
                              style={{ color: "darkgray", fontSize: "150%" }}
                            >
                              Sorry! No Order is found with this tracking No.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orderTrackingResult: state.ordersAlg.orderTrackingResult,
  };
};
export default connect(mapStateToProps, { setBookingRequestRedux })(
  OrderTrackingModalResult
);
