import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import "./Content.css";
class Content extends Component {
  state = { blogObj: null };

  componentDidMount = async () => {
    console.log(this.props.match.params.blogId);
    if (this.props.allBlogs && this.props.allBlogs.length > 0) {
      const blogObj = this.props.allBlogs.find(
        (blog) =>
          blog.blogTitle
            .replace("%", "")
            .replaceAll(" ", "-")
            .replaceAll("?", "-") == this.props.match.params.blogId
      );
      this.setState({ blogObj }, () => {
        console.log(blogObj);
      });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allBlogs && nextProps.allBlogs.length > 0) {
      const blogObj = nextProps.allBlogs.find(
        (blog) =>
          blog.blogTitle
            .replace("%", "")
            .replaceAll(" ", "-")
            .replaceAll("?", "-") == nextProps.match.params.blogId
      );
      this.setState({ blogObj });
    }
  };

  render() {
    const { blogObj } = this.state;
    return (
      <>
        <main id="body-content">
          <section className="wide-tb-80">
            <div className="container pos-rel">
              <div className="row ">
                <div className="col blog-body">
                  {blogObj && (
                    <div style={{ textAlign: "justify" }}>
                      {ReactHtmlParser(blogObj.blogBody)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allBlogs: state.notices.blogs,
  };
};
export default withRouter(connect(mapStateToProps)(Content));
