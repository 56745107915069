import React, { Component } from "react";
import {
  auth,
  createUserProfileDocument,
} from "../../../components/firebase/firebase.utils";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "./OtherInformationModal.css";
import { updateMyParcelArrayOfUser } from "../../../actions/index";
import { CircleLoader } from "react-spinners";
class OtherInformationModal extends Component {
  constructor() {
    super();

    this.state = {
      productsValue: "",
      packagingChosed: "",
      deliveryAddress: "",
      insurance: false,
      bookingId: "",
      customerDeclaredProductName: "",
      customerDeclaredQuantity: "",
      customerDeclaredTotalCbm: "",
      customerDeclaredProductContains: "none",
      additionalServices: false,
      qcCheck: false,
      submit_loader: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.parcelObj) {
      this.setState({
        customerDeclaredProductName: nextProps.parcelObj.productName,
        customerDeclaredQuantity: nextProps.parcelObj.quantity,
        customerDeclaredTotalCbm: nextProps.parcelObj.totalCbm,
        deliveryAddress: nextProps.parcelObj.deliveryAddress
          ? nextProps.parcelObj.deliveryAddress
          : "ALG Office",
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { packagingChosed, submit_loader } = this.state;
    const { parcelObj } = this.props;

    if (submit_loader) {
      return;
    }
    let packagingCost = 0;
    if (packagingChosed) {
      if (packagingChosed === "Green Bag") {
        packagingCost = 200;
      }
      if (packagingChosed === "Green Bag with Polythene") {
        packagingCost = 350;
      }
      if (packagingChosed === "Wooden Box") {
        packagingCost = 2480;
      }
    }
    this.setState({ submit_loader: true });
    await this.props.updateMyParcelArrayOfUser({
      ...parcelObj,
      ...this.state,
      packagingCost,
      editRequested: true,
      editApproved: false,
    });

    document.getElementById("modal-close-other-information").click();
    this.setState({ submit_loader: false });
  };

  componentWillUnmount = () => {
    this.setState({
      productsValue: "",
      packagingChosed: "",
      deliveryAddress: "",

      insurance: false,
      bookingId: "",
      customerDeclaredProductName: "",
      customerDeclaredQuantity: "",
      customerDeclaredTotalCbm: "",
      customerDeclaredProductContains: "none",
      additionalServices: false,
      qcCheck: false,
    });
  };

  render() {
    const { parcelObj } = this.props;
    const {
      productsValue,
      packagingChosed,
      deliveryAddress,
      insurance,
      bookingId,
      customerDeclaredProductName,
      customerDeclaredQuantity,

      customerDeclaredTotalCbm,
      additionalServices,
      qcCheck,
    } = this.state;
    console.log(parcelObj);
    return (
      <>
        <div
          className="modal fade"
          id="request_other_information_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content signup-modal"
              style={{ maxWidth: "700px" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-0">
                    <Link
                      to="#"
                      className="close other_information_modal_close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="modal-close-other-information"
                    >
                      <i className="icofont-close-line"></i>
                    </Link>
                    <div
                      className="d-lg-flex justify-content-center no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div className="col-12">
                        <div className="px-3 m-4 ">
                          <h2
                            className="h2-xl mb-1 fw-6 sign-in-header-title other-information-header-title"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            Additional Information
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            className="rounded-field"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                <span
                                  className="sub-header-title"
                                  style={{
                                    color: "purple",
                                    marginLeft: "2px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  Local Delivery, Packaging, Insurace,Issues
                                </span>
                                {parcelObj.chineseNote && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      color: "red",
                                      fontSize: "80%",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {parcelObj.chineseNote}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-row mb-3">
                              <div className="col">
                                <div style={{ marginBottom: "5px" }}>
                                  Products Value
                                </div>
                                <input
                                  type="text"
                                  name="productsValue"
                                  value={productsValue}
                                  className="form-control input-field-70"
                                  placeholder="Total Cost"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              <div className="col">
                                <div style={{ marginBottom: "5px" }}>
                                  Product Name
                                </div>
                                <input
                                  type="text"
                                  name="customerDeclaredProductName"
                                  value={customerDeclaredProductName}
                                  className="form-control input-field-70"
                                  placeholder="Enter product Name"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <div style={{ marginBottom: "5px" }}>
                                  Products Quantity
                                </div>
                                <input
                                  type="text"
                                  name="customerDeclaredQuantity"
                                  value={customerDeclaredQuantity}
                                  className="form-control input-field-70"
                                  placeholder="Total Quantity"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                              <div className="col">
                                <div style={{ marginBottom: "5px" }}>
                                  Total CBM
                                </div>
                                <input
                                  type="text"
                                  name="customerDeclaredTotalCbm"
                                  value={customerDeclaredTotalCbm}
                                  className="form-control input-field-70"
                                  placeholder="Total Cbm"
                                  onChange={this.handleChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <div style={{ marginBottom: "5px" }}>
                                  Product Contains
                                </div>
                                <select
                                  className="custom-select form-control input-field-70"
                                  name="customerDeclaredProductContains"
                                  value={
                                    this.state.customerDeclaredProductContains
                                  }
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option value="none">none</option>
                                  <option value="liquid">liquid</option>
                                  <option value="powder">powder</option>
                                  <option value="battery">battery</option>
                                  <option value="magnet">magnet</option>
                                  <option value="sensor">sensor</option>
                                </select>
                              </div>
                              <div className="col"></div>
                            </div>

                            <div
                              className="form-row mt-4 mb-3"
                              style={{
                                color: additionalServices
                                  ? "darkorange"
                                  : "gray",
                                fontWeight: "bold",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                className={
                                  additionalServices
                                    ? "icofont-minus-circle"
                                    : "icofont-plus-circle"
                                }
                                onClick={() => {
                                  this.setState({
                                    additionalServices:
                                      !this.state.additionalServices,
                                  });
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: additionalServices
                                    ? "darkorange"
                                    : "gray",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;Take additional services if you wish.
                              </i>
                            </div>
                            {additionalServices ? (
                              <>
                                {parcelObj.packagingCost == 0 ? (
                                  <>
                                    <div style={{ marginBottom: "5px" }}>
                                      Choose Packaging
                                    </div>
                                    <select
                                      // style={{ border: "0px", marginTop: "7px" }}
                                      onChange={this.handleChange}
                                      name="packagingChosed"
                                      value={packagingChosed}
                                      className="form-control input-field-70 custom-select"
                                    >
                                      <option value="">Select Packaging</option>
                                      <option value="Green Bag">
                                        Green Bag (200Tk)
                                      </option>
                                      <option value="Green Bag with Polythene">
                                        Green Bag with Polythene (350Tk)
                                      </option>
                                      <option value="Wooden Box">
                                        Wooden Box (2480Tk)
                                      </option>
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div style={{ marginBottom: "5px" }}>
                                      Mandatory Packaging
                                    </div>
                                    <input
                                      type="text"
                                      name="packagingCost"
                                      value={
                                        parcelObj && parcelObj.packagingCost
                                      }
                                      className="form-control input-field-70"
                                      readOnly
                                    />
                                  </>
                                )}

                                <div className="form-row my-3">
                                  <div className="col">
                                    <div style={{ marginBottom: "5px" }}>
                                      Booking Id:
                                    </div>
                                    <input
                                      type="text"
                                      name="bookingId"
                                      value={bookingId}
                                      onChange={this.handleChange}
                                      className="form-control input-field-70"
                                      placeholder="Enter Booking Id if you booked for this parcel"
                                    />
                                  </div>
                                </div>
                                <div style={{ marginBottom: "5px" }}>
                                  Delivery Address
                                </div>
                                <div className="form-row mb-3">
                                  <div className="col">
                                    <textarea
                                      type="text"
                                      name="deliveryAddress"
                                      value={deliveryAddress}
                                      className="form-control input-field-70"
                                      placeholder="Enter your Delivery Address"
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <>
                                  <div style={{ marginBottom: "5px" }}>
                                    Addition Services
                                  </div>

                                  <div className="form-check mb-1">
                                    <input
                                      className="form-check-input"
                                      name="qcCheck"
                                      type="checkbox"
                                      value={true}
                                      checked={qcCheck}
                                      onChange={() => {
                                        this.setState({ qcCheck: !qcCheck });
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      style={{ marginTop: "3px" }}
                                    >
                                      QC Check (100tk)
                                    </label>
                                  </div>
                                  {this.state.productsValue ? (
                                    <div className="form-check mb-1">
                                      <input
                                        className="form-check-input"
                                        name="insurance"
                                        type="checkbox"
                                        value={true}
                                        checked={insurance}
                                        onChange={() => {
                                          this.setState({
                                            insurance: !insurance,
                                          });
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        style={{ marginTop: "3px" }}
                                      >
                                        Insurance (3% of products value)
                                      </label>
                                    </div>
                                  ) : null}
                                </>
                              </>
                            ) : null}

                            <div
                              className="form-row mt-4"
                              style={{ justifyContent: "center" }}
                            >
                              <button
                                type="submit"
                                className="from-btn btn-theme bg-orange"
                                style={{
                                  minWidth: "130px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>
                                    Update
                                    <i className="icofont-rounded-right"></i>
                                  </>
                                )}
                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelObj: state.parcelObj.parcelObj,
  };
};
export default withRouter(
  connect(mapStateToProps, { updateMyParcelArrayOfUser })(OtherInformationModal)
);
