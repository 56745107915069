import React, { Component } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

class FbMessenger extends Component {
  render() {
    return (
      <div>
        <MessengerCustomerChat
          pageId="952057258270545"
          appId="457510575254968"
          htmlRef={window.location.pathname}
        />
      </div>
    );
  }
}

export default FbMessenger;
