import React, { Component, Fragment } from "react";
import { ShoppingBag, DollarSign, AlertCircle } from "react-feather";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class Notification extends Component {
  render() {
    const { allOrders, payments, recharges, currentUser, parcelsArray } =
      this.props;

    // getting total my due
    let myDue = 0;
    const UnPaidInvoices = parcelsArray.filter(
      (parcel) => parcel.invoiceStatus === "Not Paid"
    );
    UnPaidInvoices.forEach((parcel) => {
      myDue = myDue + parcel.finalTotal;
      return myDue;
    });

    // getting my Parcels
    const myParcels = parcelsArray.filter((parcel) => !parcel.parcelStatus);

    // need to add additional info Parcels
    const addAdditionalInfoParcels = parcelsArray.filter(
      (parcel) => !parcel.editRequested && !parcel.editApproved
    );
    console.log(addAdditionalInfoParcels);

    const totalNotificationCount =
      addAdditionalInfoParcels.length + UnPaidInvoices.length;
    return (
      <Fragment>
        <ul className="notification-dropdown onhover-show-div p-0">
          <li>
            Notification
            <span className="badge badge-pill badge-primary pull-right">
              {totalNotificationCount}
            </span>
          </li>
          {UnPaidInvoices.length > 0 && (
            <li>
              <Link to="/user-my-invoice">
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0">
                      <span>
                        <ShoppingBag />
                      </span>
                      Invoices to Pay..!
                    </h6>
                    <p className="mb-0">
                      You have {UnPaidInvoices.length} invoices ready to pay.
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          )}
          {addAdditionalInfoParcels.length > 0 && (
            <li>
              <Link to="/user-my-parcel">
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0 txt-success">
                      <span>
                        <DollarSign />
                      </span>
                      Add additional Information to Parcel
                    </h6>
                    <p className="mb-0">
                      {addAdditionalInfoParcels.length} Parcels needs to be
                      updated
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          )}
        </ul>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelsArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.currentUser.currentUser,
    payments: state.currentUser.payments,
    recharges: state.currentUser.recharge,
  };
};
export default connect(mapStateToProps)(Notification);
