import React, { useEffect } from "react";
import Header from "../../PagesHeader";
import Footer from "../../Footer";
import Content from "./Content";
import Breadcrumbs from "../../Breadcrumbs";
import EmailSubscribe from "../../EmailSubscribe";
import BackToTop from "../../BackToTop";
import SearchPopup from "../../SearchPopup";

import LoginModal from "../../LoginModal";
import SignUpModal from "../../SignUpModal";
import OrderTrackingModal from "../../OrderTrackingModal";
import OrderTrackingModalResult from "../../OrderTrackingModalResult";
import PhoneModal from "../../PhoneModal";
import OtpModal from "../../OtpModal";
import ForgotPasswordModal from "../../ForgotPasswordModal";
import BreadcrumbsData from "../../breadcrumbs.json";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs
        title="Terms And Condition"
        description="Terms And Condition"
      />
      <Content />
      <EmailSubscribe />
      <SearchPopup />
      <PhoneModal />
      <OtpModal />
      <LoginModal />
      <SignUpModal />
      <OrderTrackingModal />
      <OrderTrackingModalResult />
      <ForgotPasswordModal />
      <BackToTop />
      <Footer />
    </>
  );
};
export default TermsAndCondition;
