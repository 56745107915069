import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllNoticesRedux } from "../../../actions/index";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
class Content extends Component {
  componentDidMount = async () => {
    await this.props.getAllNoticesRedux();
  };
  render() {
    console.log(this.props);
    return (
      <>
        <main id="body-content">
          <section className="wide-tb-80">
            <div className="container pos-rel">
              <div className="row align-items-start">
                <div className="col-md-12 col-lg-8 blog-list">
                  <div className="row">
                    {this.props.allNotices && this.props.allNotices.length > 0
                      ? this.props.allNotices.map((notice) => (
                          <div
                            className="col-md-6"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.props.history.push(
                                `/recent-news/${notice.id}`
                              )
                            }
                          >
                            <div className="blog-warp">
                              <img
                                src="images/blog_img_1.jpg"
                                alt=""
                                className="rounded"
                              />
                              <div className="meta-box">
                                <a href="#">Business</a> <span>/</span>
                                {notice.createdAt}
                              </div>
                              <h4 className="h4-md mb-3">
                                <Link to={`/recent-news/${notice.id}`}>
                                  {notice.noticeTitle}
                                </Link>
                              </h4>
                              <p>
                                {notice.noticeBody.slice(0, 100)} ..........
                              </p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="text-center">
                    <a href="#" className="btn-theme bg-navy-blue">
                      Older Post <i className="icofont-rounded-right"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <aside className="sidebar-spacer row">
                    <div className="sidebar-primary col-lg-12 col-md-6">
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Search</h3>
                        <form className="flex-nowrap col ml-auto footer-subscribe p-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search …"
                          />
                          <button
                            type="submit"
                            className="btn btn-theme bg-orange"
                          >
                            <i className="icofont-search p-0"></i>
                          </button>
                        </form>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                        <div className="blog-list-footer">
                          <ul className="list-unstyled">
                            {this.props.allNotices &&
                            this.props.allNotices.length > 0
                              ? this.props.allNotices
                                  .slice(-2)
                                  .map((notice) => (
                                    <li
                                      key={notice.id}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.props.history.push(
                                          `/recent-news/${notice.id}`
                                        );
                                      }}
                                    >
                                      <div className="media">
                                        <div className="post-thumb">
                                          <img
                                            src="images/post_thumb_1.jpg"
                                            alt=""
                                            className="rounded-circle"
                                          />
                                        </div>
                                        <div className="media-body post-text">
                                          <h5 className="mb-3 h5-md">
                                            <Link
                                              to={`/recent-news/${notice.id}`}
                                            >
                                              {notice.noticeTitle.slice(0, 30)}
                                            </Link>
                                          </h5>
                                          <p>
                                            {notice.noticeBody.slice(0, 50)}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                              : null}
                          </ul>
                        </div>
                      </div>
                      <div className="widget-wrap text-center bg-sky-blue rounded py-5">
                        <div className="mb-2">
                          <i className="icofont-headphone-alt icofont-4x"></i>
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-4">
                          Need help?
                        </h3>
                        <p>
                          Call our award-winning
                          <br /> support team 24/7
                        </p>
                        <a href="#" className="btn-theme bg-orange mt-3">
                          Get In Touch <i className="icofont-rounded-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="sidebar-secondary col-lg-12 col-md-6">
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Recent Posts</h3>
                        <div className="blog-list-categories">
                          <ul className="list-unstyled">
                            <li className="active">
                              <a href=" href"> Business</a>
                            </li>
                            <li>
                              <a href="#">Logistics</a>
                            </li>
                            <li>
                              <a href="#">News</a>
                            </li>
                            <li>
                              <a href="#">Recent</a>
                            </li>
                            <li>
                              <a href="#">Transport</a>
                            </li>
                            <li>
                              <a href="#">Warehousing</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Our Photostream</h3>
                        <ul id="basicuse" className="photo-thumbs"></ul>
                      </div>
                      <div className="widget-wrap">
                        <h3 className="h3-md fw-7 mb-4">Text Widget</h3>
                        <p>
                          Explain to you how all this mistaken idea of hovered
                          link and praising pain was born and I will give you a
                          complete count of the system expound
                        </p>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allNotices: state.notices.notices,
  };
};
export default withRouter(
  connect(mapStateToProps, { getAllNoticesRedux })(Content)
);
