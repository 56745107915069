import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { setBookingRequestRedux } from "../actions/index";
import { CircleLoader } from "react-spinners";
class SearchSubmitModalFreight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      weight: "",
      ctnQuantity: "",
      totalCbm: "",
      containerSize: "",
      productContains: "",
      cnfService: "",
      portOfOrigin: "",
      portOfDestination: "",
      productQuantity: "",
      pick_up: false,
      port_of_origin: false,
      port_of_destination: false,
      delivery: false,
      submit_loader: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.props.currentUser.displayName === "") {
      alert("Please log in to your account to request a booking");
      document.getElementById("modal-close-icon-freight").click();
      return;
    } else {
      this.setState({
        submit_loader: true,
      });
      let totalCbm =
        ((this.state.ctnHeight * this.state.ctnWidth * this.state.ctnLength) /
          1000000) *
        this.state.ctnQuantity;
      console.log(totalCbm);
      let chargeableWeight = totalCbm * 167;
      console.log(chargeableWeight);
      await this.props.setBookingRequestRedux({
        userId: this.props.currentUser.uid,
        totalCbm,
        chargeableWeight,
        ...this.state,
        ...this.props.result,
        date: this.props.result.date.replaceAll("-", "/"),
      });
      toast.success(
        "your booking request is Pending. Our agent will confirm your booking soon"
      );
      document.getElementById("modal-close-icon-freight").click();
      this.setState({
        submit_loader: false,
      });
    }
    this.setState({
      productName: "",
      weight: "",
      ctnQuantity: "",
      totalCbm: "",
      containerSize: "",
      productContains: "",
      cnfService: "",
      portOfOrigin: "",
      portOfDestination: "",
      productQuantity: "",
      pick_up: false,
      port_of_origin: false,
      port_of_destination: false,
      delivery: false,
    });
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_search_submit_popup_freight"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div className="modal-content search-submit-modal">
              <div className="modal-body p-0">
                <section className="pos-rel" style={{ background: "white" }}>
                  <div className="container-fluid p-0">
                    <a
                      id="modal-close-icon-freight"
                      href="#"
                      className="close search-submit-modal-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "orange" }}
                      ></i>
                    </a>
                    <div
                      className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col"
                        style={{ padding: "10px", backgroundColor: "darkblue" }}
                      >
                        <div className="row mt-5 search-submit-modal-header-1">
                          সন্মানিত গ্রাহক
                        </div>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="center-head">
                              <span className="bg-light-gray txt-orange">
                                read carefully
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{ color: "white", paddingLeft: "5px" }}>
                          আমাদের ফ্রেইড এর স্পেস বুকিং এর ক্ষেত্রে নিন্মোক্ত
                          তথ্যগুলো প্রদান করে আমাদের সহযোগিতা করুন। বুকিং এর
                          পরবর্তী ২৪ ঘণ্টার মধ্যে আপনার শিপমেন্টের সকল প্রকার
                          খরচ আপনাকে জানিয়ে দেয়া হবে।
                        </div>
                        <div className="row mt-1 sea-logos-row"></div>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="center-head">
                              <span
                                className="bg-light-gray txt-orange"
                                style={{
                                  fontSize: "130%",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                We are partnered with
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4 sea-logos-row">
                          <div
                            className="col sea-logo-container"
                            style={{
                              backgroundImage: "url(../images/air-logos/3.png)",
                            }}
                          ></div>
                          <div
                            className="col sea-logo-container"
                            style={{
                              backgroundImage: "url(../images/air-logos/8.png)",
                            }}
                          ></div>
                          <div
                            className="col sea-logo-container"
                            style={{
                              backgroundImage:
                                "url(../images/air-logos/10.png)",
                            }}
                          ></div>
                          <div
                            className="col sea-logo-container"
                            style={{
                              backgroundImage:
                                "url(../images/air-logos/11.png)",
                            }}
                          ></div>
                          <div
                            className="col sea-logo-container"
                            style={{
                              backgroundImage:
                                "url(../images/air-logos/14.png)",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="col-md-7 col-12"
                        style={{ background: "white" }}
                      >
                        <div className="px-3 m-4">
                          <h2
                            className="h2-xl fw-6"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                            }}
                          >
                            Detail information
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            className="rounded-field freight-modal-form"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                Having trouble?
                                <span
                                  style={{
                                    color: "purple",
                                    marginLeft: "2px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp; contact support
                                </span>
                              </div>
                            </div>

                            <div className="form-row mb-2">
                              <div className="col">
                                <div className="form-row mb-1">
                                  Port of Origin:
                                </div>
                                <input
                                  type="text"
                                  name="portOfOrigin"
                                  value={this.state.portOfOrigin}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="Port of Origin"
                                  required
                                />
                              </div>
                              <div className="col">
                                <div className="form-row mb-1">
                                  Port of Destination:
                                </div>
                                <div className="form-row">
                                  <input
                                    type="text"
                                    name="portOfDestination"
                                    value={this.state.portOfDestination}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    placeholder="port of destination"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <div className="form-row mb-1">
                                  Product Name:
                                </div>
                                <input
                                  type="text"
                                  name="productName"
                                  value={this.state.productName}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="enter product name"
                                  required
                                />
                              </div>
                              <div className="col">
                                <div className="form-row mb-1">
                                  Total Weight:
                                </div>
                                <div className="form-row">
                                  <input
                                    type="text"
                                    name="weight"
                                    value={this.state.weight}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    placeholder="total approx weight (kg)"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-row mb-1">Total Carton:</div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    fontSize: "90%",
                                    padding: "20px",
                                  }}
                                  type="number"
                                  name="ctnQuantity"
                                  value={this.state.ctnQuantity}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="carton quantity"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row mb-1">Total CBM:</div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  style={{
                                    borderRadius: "10rem",
                                    fontSize: "90%",
                                    padding: "20px",
                                  }}
                                  type="number"
                                  name="totalCbm"
                                  value={this.state.totalCbm}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="total CBM"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row mb-1">
                              Product Qunatity
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  type="text"
                                  name="productQuantity"
                                  value={this.state.productQuantity}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  placeholder="product Quantity"
                                  style={{
                                    borderRadius: "10rem",
                                  }}
                                  required
                                />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col">
                                <div
                                  className="center-head"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <span
                                    className="bg-light-gray"
                                    onClick={() => {
                                      this.setState({
                                        pick_up: !this.state.pick_up,
                                      });
                                    }}
                                    style={{
                                      fontSize: "130%",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: this.state.pick_up
                                        ? "white"
                                        : "gainsboro",
                                    }}
                                  >
                                    <i
                                      className="icofont-truck-loaded"
                                      style={{
                                        fontSize: "100%",
                                        border: this.state.pick_up
                                          ? "1px solid white"
                                          : "1px solid gainsboro",
                                        borderRadius: "10rem",
                                        padding: "5px",
                                        backgroundColor: this.state.pick_up
                                          ? "orange"
                                          : "white",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    className="bg-light-gray"
                                    onClick={() => {
                                      this.setState({
                                        port_of_origin:
                                          !this.state.port_of_origin,
                                      });
                                    }}
                                    style={{
                                      fontSize: "130%",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: this.state.port_of_origin
                                        ? "white"
                                        : "gainsboro",
                                    }}
                                  >
                                    <i
                                      className="icofont-industries-5"
                                      style={{
                                        fontSize: "100%",
                                        border: this.state.port_of_origin
                                          ? "1px solid white"
                                          : "1px solid gainsboro",
                                        borderRadius: "10rem",
                                        padding: "5px",
                                        backgroundColor: this.state
                                          .port_of_origin
                                          ? "orange"
                                          : "white",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    className="bg-light-gray"
                                    onClick={() => {
                                      this.setState({
                                        port_of_destination:
                                          !this.state.port_of_destination,
                                      });
                                    }}
                                    style={{
                                      fontSize: "130%",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: this.state.port_of_destination
                                        ? "white"
                                        : "gainsboro",
                                    }}
                                  >
                                    <i
                                      className="icofont-industries-2"
                                      style={{
                                        fontSize: "100%",
                                        border: this.state.port_of_destination
                                          ? "1px solid white"
                                          : "1px solid gainsboro",
                                        borderRadius: "10rem",
                                        padding: "5px",
                                        backgroundColor: this.state
                                          .port_of_destination
                                          ? "orange"
                                          : "white",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    className="bg-light-gray"
                                    onClick={() => {
                                      this.setState({
                                        delivery: !this.state.delivery,
                                      });
                                    }}
                                    style={{
                                      fontSize: "130%",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: this.state.delivery
                                        ? "white"
                                        : "gainsboro",
                                    }}
                                  >
                                    <i
                                      className="icofont-truck-alt"
                                      style={{
                                        fontSize: "100%",
                                        border: this.state.delivery
                                          ? "1px solid white"
                                          : "1px solid gainsboro",
                                        borderRadius: "10rem",
                                        padding: "5px",
                                        backgroundColor: this.state.delivery
                                          ? "orange"
                                          : "white",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "5px",
                                    color: "white",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <span
                                    className="bg-light-gray txt-orange"
                                    style={{
                                      fontSize: "70%",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: "gray",
                                    }}
                                  >
                                    pick up
                                  </span>
                                  <span
                                    className="bg-light-gray txt-orange"
                                    style={{
                                      fontSize: "70%",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: "gray",
                                    }}
                                  >
                                    port of origin
                                  </span>
                                  <span
                                    className="bg-light-gray txt-orange"
                                    style={{
                                      fontSize: "70%",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: "gray",
                                    }}
                                  >
                                    port of discharge
                                  </span>
                                  <span
                                    className="bg-light-gray txt-orange"
                                    style={{
                                      fontSize: "70%",
                                      marginBottom: "0px",
                                      padding: "0px",
                                      color: "gray",
                                    }}
                                  >
                                    delivery
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              {this.props.result &&
                              this.props.result.freightResultFrom == "sea" ? (
                                <div>
                                  <div className="form-row mb-1">
                                    Container Size:
                                  </div>
                                  <div className="form-row">
                                    <select
                                      className="custom-select"
                                      name="containerSize"
                                      value={this.state.containerSize}
                                      onChange={this.handleChange}
                                      required
                                    >
                                      <option value="20' Standard">
                                        20' Standard
                                      </option>
                                      <option value="40' Standard">
                                        40' Standard
                                      </option>
                                      <option value="40' High Cube">
                                        40' High Cube
                                      </option>
                                      <option value="20' Refrigerated">
                                        20' Refrigerated
                                      </option>
                                      <option value="40' Refrigerated">
                                        40' Refrigerated
                                      </option>
                                      <option value="45' High Cube">
                                        45' High Cube
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="form-row mb-1">
                                    Product Contains:
                                  </div>
                                  <div className="form-row">
                                    <select
                                      className="custom-select"
                                      name="productContains"
                                      value={this.state.productContains}
                                      onChange={this.handleChange}
                                      required
                                    >
                                      <option value="powder">powder</option>
                                      <option value="battery">battery</option>
                                      <option value="liquid">liquid</option>
                                      <option value="magnet">magnet</option>
                                      <option value="sensor">sensor</option>
                                      <option value="none">none</option>
                                    </select>
                                  </div>
                                </div>
                              )}

                              <div>
                                <div className="form-row mb-1">CNF Service</div>
                                <select
                                  name="cnfService"
                                  value={this.state.cnfService}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-select"
                                >
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                            <div
                              className="row mt-3"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  background: "#0000de",
                                  color: "white",
                                  padding: "13px",
                                  borderRadius: "1rem",
                                  minWidth: "100px",
                                  minHeight: "50px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                {!this.state.submit_loader && <>Book now</>}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    result: state.result.result,
    searchType: state.result.searchType,
    currentUser: state.currentUser.currentUser,
  };
};
export default connect(mapStateToProps, { setBookingRequestRedux })(
  SearchSubmitModalFreight
);
