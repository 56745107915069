import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import Barcode from "react-barcode";
import "./onlyStickerToPrint.css";
import { withRouter } from "react-router-dom";

export class OnlyStickerToPrint extends Component {
  render() {
    const { bookingObj, user } = this.props;
    return (
      <>
        {bookingObj.shipmentMethod === "Express" ? (
          <div className="sticker-container">
            <div
              style={{
                fontSize: "150%",
                paddingTop: "50px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Booking Id: {bookingObj.bookingId}
            </div>
            <div
              style={{
                fontSize: "120%",
                paddingTop: "10px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Shipping Mark: ALG-{user.userId}
            </div>
            <div className="flex-box">
              <div className="Senders Information">
                <h3
                  className="mt-3 mb-3"
                  style={{ fontSize: "150%", fontWeight: "bold" }}
                >
                  Sender's Information
                </h3>
                <p>Name: {user.displayName}</p> <br />
                <p>Mobile: {user.mobileNo}</p> <br />
                <p>Email: {user.email}</p> <br />
                {user.address && user.address.length > 50 ? (
                  <p>
                    Address:
                    {user.address.slice(0, 50)} <br /> <br />
                    {user.address.slice(50, user.address.length + 1)}
                  </p>
                ) : (
                  <p>Address: {user.adderss}</p>
                )}
                <br />
              </div>
              <div className="Receivers Information">
                <h3
                  className="mt-3 mb-3"
                  style={{ fontSize: "150%", fontWeight: "bold" }}
                >
                  Receiver's Information
                </h3>
                <p>Name: {bookingObj.receiversName}</p> <br />
                <p>Mobile: {bookingObj.receiversMobileNo}</p> <br />
                <p>Post Code: {bookingObj.receiversPostCode}</p> <br />
                {bookingObj.receiversAddress &&
                bookingObj.receiversAddress.length > 50 ? (
                  <p>
                    Address:
                    {bookingObj.receiversAddress.slice(0, 50)} <br /> <br />
                    {bookingObj.receiversAddress.slice(
                      50,
                      bookingObj.receiversAddress.length + 1
                    )}
                  </p>
                ) : (
                  <p>Address: {bookingObj.receiversAddress}</p>
                )}
              </div>
            </div>
            <div className="product-information">
              <div>
                <h3
                  className="mt-3 mb-3"
                  style={{ fontSize: "150%", fontWeight: "bold" }}
                >
                  Product Information
                </h3>
                <p>Type: {bookingObj.parcelType}</p> <br />
                <p>Product Name: {bookingObj.productName}</p> <br />
                <p>Product Contains: {bookingObj.productContains}</p> <br />
              </div>
            </div>
            <div className="barcode">
              <div className="barcode-container">
                <Barcode
                  value={`${bookingObj.bookingId}`}
                  width={3}
                  height={40}
                  displayValue={false}
                />
              </div>
              <div
                style={{
                  marginBottom: "50px",
                  marginLeft: "120px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginRight: "100px",
                }}
              >
                Alglimited.com
              </div>
            </div>
          </div>
        ) : (
          <div className="sticker-container">
            <div
              style={{
                fontSize: "150%",
                paddingTop: "50px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Booking Id: {bookingObj.bookingId}
            </div>
            <div
              style={{
                fontSize: "150%",
                paddingTop: "10px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Shipping Mark: ALG-{user.userId}
            </div>

            <div className="flex-box-2">
              <div className="Senders Information">
                <h3
                  className="mt-3 mb-3"
                  style={{ fontSize: "150%", fontWeight: "bold" }}
                >
                  Receiver's Information
                </h3>
                <p>Name: {user.displayName}</p> <br />
                <p>Product Name: {bookingObj.productType}</p> <br />
                <p>Brand/Non-brand: {bookingObj.productBrand}</p> <br />
                <p>Product Contains:{bookingObj.productContains}</p> <br />
                {user.address && user.address.length > 50 ? (
                  <p>
                    Address:
                    {user.address.slice(0, 50)} <br /> <br />
                    {user.address.slice(50, user.address.length + 1)}
                  </p>
                ) : (
                  <p>Address: {user.adderss}</p>
                )}
                <br />
              </div>
            </div>
            <div className="barcode">
              <div className="barcode-container">
                <Barcode
                  value={`${bookingObj.bookingId}`}
                  width={3}
                  height={40}
                  displayValue={false}
                />
              </div>
              <div
                style={{
                  marginBottom: "50px",
                  marginLeft: "120px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginRight: "100px",
                }}
              >
                Alglimited.com
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.currentUser.currentUser,
  };
};
export default withRouter(connect(mapStateToProps, {})(OnlyStickerToPrint));
