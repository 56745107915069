import React from "react";
import Slider from "./Slilder";
import Content from "./Content";
import VideoBox from "./VideoBox";
import SearchPopup from "../../../SearchPopup";
import SignUpModal from "../../../SignUpModal";
import OrderTrackingModal from "../../../OrderTrackingModal";
import OrderTrackingModalResult from "../../../OrderTrackingModalResult";
import BookingTrackingModalResult from "../../../BookingTrackingModalResult";
import SearchSubmitModalFreight from "../../../SearchSubmitModalFreight";
import SearchSubmitModalDoortoDoor from "../../../SearchSubmitModalDoortoDoor";
import SearchSubmitModalExpress from "../../../SearchSubmitModalExpress";
import LoginModal from "../../../LoginModal";
import IntroModal from "../../../IntroModal";
import PhoneModal from "../../../PhoneModal";
import OtpModal from "../../../OtpModal";
import ForgotPasswordModal from "../../../ForgotPasswordModal";
import SearchBar from "./SearchBar";
const PageContent = () => {
  return (
    <>
      <SearchBar />
      <Slider />
      <Content />
      <VideoBox />
      <SearchPopup />
      <SignUpModal />
      <OrderTrackingModal />
      <BookingTrackingModalResult />
      <OrderTrackingModalResult />
      <SearchSubmitModalFreight />
      <SearchSubmitModalDoortoDoor />
      <SearchSubmitModalExpress />
      <LoginModal />
      <PhoneModal />
      <ForgotPasswordModal />
      <OtpModal />
      <IntroModal />
    </>
  );
};
export default PageContent;
