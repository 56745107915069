import React, { Component, Fragment } from "react";
import Breadcrumb from "./common/breadcrumb";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import CanvasJSReact from "./assets/canvas/canvasjs.react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserLoanRedux } from "../../actions/index";
import { Pie, Doughnut, Bar, Line } from "react-chartjs-2";
import {
  pieOptions,
  doughnutOption,
  lineOptions,
  buyOption,
  employeeData,
  employeeOptions,
} from "./constants/chartData";
// image import
import user2 from "./assets/images/dashboard/user2.jpg";
import user1 from "./assets/images/dashboard/user1.jpg";
import man from "./assets/images/dashboard/man.jpg";
import user from "./assets/images/dashboard/user.png";
import designer from "./assets/images/dashboard/designer.jpg";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export class Dashboard extends Component {
  getTotalPaymentAmount = (paymentsArray) => {
    var totalAmount = paymentsArray.reduce((acc, payments) => {
      payments.payments.forEach((payment) => {
        acc += parseInt(payment.amount);
      });
      return acc;
    }, 0);
    return totalAmount;
  };

  componentDidMount = () => {
    if (this.props.currentUser.id) {
      this.props.getUserLoanRedux(this.props.currentUser.id);
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.currentUser.id !== this.props.currentUser.id) {
      this.props.getUserLoanRedux(nextProps.currentUser.id);
    }
  };

  render() {
    const { allOrders, payments, recharges, currentUser, parcelsArray } =
      this.props;

    // getting total my due
    let myDue = 0;
    const UnPaidInvoices = parcelsArray.filter(
      (parcel) => parcel.invoiceStatus === "Not Paid"
    );
    UnPaidInvoices.forEach((parcel) => {
      myDue = myDue + parcel.finalTotal;
      return myDue;
    });

    // getting my Parcels
    const myParcels = parcelsArray.filter((parcel) => !parcel.parcelStatus);

    // getting all Delivered
    const allDeliverd = parcelsArray.filter(
      (parcel) => parcel.parcelStatus === "Delivered"
    );

    // need to add additional info Parcels
    const addAdditionalInfoParcels = parcelsArray.filter(
      (parcel) => !parcel.editRequested
    );
    const lineData = {
      labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
      datasets: [
        {
          lagend: "none",
          data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
          borderColor: "#ff8084",
          backgroundColor: "#ff8084",
          borderWidth: 2,
        },
        {
          lagend: "none",
          data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
          borderColor: "#a5a5a5",
          backgroundColor: "#a5a5a5",
          borderWidth: 2,
        },
      ],
    };

    const buyData = {
      labels: ["", "10", "20", "30", "40", "50"],
      datasets: [
        {
          backgroundColor: "transparent",
          borderColor: "#13c9ca",
          data: [20, 5, 80, 10, 100, 15],
        },
        {
          backgroundColor: "transparent",
          borderColor: "#a5a5a5",
          data: [0, 50, 20, 70, 30, 27],
        },
        {
          backgroundColor: "transparent",
          borderColor: "#ff8084",
          data: [0, 30, 40, 10, 86, 40],
        },
      ],
    };

    const doughnutOptions = {
      title: "",
      pieHole: 0.35,
      pieSliceBorderColor: "none",
      colors: ["#ff8084", "#13c9ca", "#a5a5a5"],
      legend: {
        position: "none",
      },
      pieSliceText: "none",
      tooltip: {
        trigger: "none",
      },
      animation: {
        startup: true,
        easing: "linear",
        duration: 1500,
      },
      chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
      enableInteractivity: false,
    };
    const pieOptions = {
      title: "",
      pieHole: 1,
      slices: [
        {
          color: "#ff8084",
        },
        {
          color: "#13c9ca",
        },
        {
          color: "#f0b54d",
        },
      ],
      tooltip: {
        showColorCode: false,
      },
      chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
      legend: "none",
    };
    const LineOptions = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#ff8084"],
      legend: "none",
    };
    const LineOptions1 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#13c9ca"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    const LineOptions2 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#f5ce8a"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    const LineOptions3 = {
      hAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      vAxis: {
        textPosition: "none",
        baselineColor: "transparent",
        gridlineColor: "transparent",
      },
      colors: ["#a5a5a5"],
      chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
      legend: "none",
    };
    return (
      <Fragment>
        <Breadcrumb title="Dashboard" parent="Dashboard" />
        <div className="container-fluid">
          <div className="row">
            <>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-warning card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Navigation className="font-warning" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Loan
                        </span>
                        <h3 className="mb-0">
                          Tk{" "}
                          <CountUp className="counter" end={this.props.loan} />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden  widget-cards">
                  <div className="bg-secondary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Box className="font-secondary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Invoice Due
                        </span>
                        <h3 className="mb-0">
                          Tk &nbsp;
                          <CountUp className="counter" end={myDue} />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-primary card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <MessageSquare className="font-primary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          My Parcel
                        </span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={myParcels.length} />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div className="bg-danger card-body">
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <Users className="font-danger" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{ fontWeight: "bold", fontSize: "130%" }}
                        >
                          Total Received Parcel
                        </span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={allDeliverd.length}
                          />
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>

            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Recent Recharges</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Date</th>
                          <th scope="col">Method</th>
                          <th scope="col">Receit No</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Recharge By</th>
                        </tr>
                      </thead>

                      <tbody>
                        {recharges.length > 0
                          ? recharges.slice(0, 5).map((recharge) => (
                              <tr key={recharge.rechargeId}>
                                <td className="font-danger">
                                  {recharge.rechargeId}
                                </td>
                                <td className="font-danger">
                                  {recharge.rechargedAt}
                                </td>
                                <td className="font-danger">
                                  {recharge.paymentMethod}
                                </td>
                                <td className="font-danger">
                                  {recharge.receitNo}
                                </td>
                                <td className="font-danger">
                                  {recharge.amount}Tk
                                </td>
                                <td className="font-danger">
                                  {recharge.rechargeBy}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>

                    <Link
                      to={`${process.env.PUBLIC_URL}/user-recharge-wallet`}
                      className="btn btn-primary"
                    >
                      View All Recharges
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Recent Payments</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Paid At</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Invoices</th>
                        </tr>
                      </thead>

                      <tbody>
                        {payments
                          ? payments.slice(0, 5).map((payment) => (
                              <tr key={payment.paymentId}>
                                <td className="font-danger">
                                  {payment.paymentId}
                                </td>
                                <td className="font-danger">
                                  {payment.paidAt}
                                </td>
                                <td className="font-danger">
                                  {payment.amount}Tk
                                </td>
                                <td className="font-danger">
                                  {payment.paidInvoice.map(
                                    (invoice) => `${invoice},`
                                  )}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>

                    <Link
                      to={`${process.env.PUBLIC_URL}/user-my-payment`}
                      className="btn btn-primary"
                    >
                      View All Payments
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Invoices To Pay</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Order ID</th>
                          <th scope="col">Product</th>
                          <th scope="col">Weight</th>
                          <th scope="col">Per Kg</th>
                          <th scope="col">Order Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {UnPaidInvoices.length > 0
                          ? UnPaidInvoices.slice(0, 5).map((order) => (
                              <tr key={order.orderId}>
                                <td className="font-danger">
                                  {order.parcelId}
                                </td>
                                <td className="font-danger">
                                  {order.productName}
                                </td>
                                <td className="font-danger">
                                  {order.grossWeight}kg
                                </td>
                                <td className="font-danger">
                                  {order.ratePerKg}Tk
                                </td>
                                <td className="font-danger">
                                  Tk {order.finalTotal}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>

                    <Link
                      to={`${process.env.PUBLIC_URL}/user-my-invoice`}
                      className="btn btn-primary"
                    >
                      View All Invoices
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 xl-100">
              <div className="card">
                <div className="card-header">
                  <h5>Add Parcel Info</h5>
                </div>
                <div className="card-body">
                  <div className="user-status table-responsive latest-order-table">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">Parcel ID</th>
                          <th scope="col">Product</th>
                          <th scope="col">Tracking No</th>
                          <th scope="col">Contains</th>
                        </tr>
                      </thead>

                      <tbody>
                        {addAdditionalInfoParcels.length > 0
                          ? addAdditionalInfoParcels
                              .slice(0, 5)
                              .map((order) => (
                                <tr key={order.parcelId}>
                                  <td className="font-danger">
                                    {order.parcelId}
                                  </td>
                                  <td className="font-danger">
                                    {order.productName}
                                  </td>
                                  <td className="font-danger">
                                    {order.trackingNo}
                                  </td>
                                  <td className="font-danger">
                                    {order.productType}
                                  </td>
                                </tr>
                              ))
                          : null}
                      </tbody>
                    </table>

                    <Link
                      to={`${process.env.PUBLIC_URL}/user-my-parcel`}
                      className="btn btn-primary"
                    >
                      View All Parcels
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelsArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.currentUser.currentUser,
    loan: state.currentUser.loan,
    payments: state.currentUser.payments,
    recharges: state.currentUser.recharge,
  };
};
export default connect(mapStateToProps, { getUserLoanRedux })(Dashboard);
