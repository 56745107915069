import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./myRefundDatatable.css";
import { Link } from "react-router-dom";
import { updateRefundStatusRedux } from "../../../actions/index";
import { connect } from "react-redux";
export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };
  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      console.log(myData);
      myData.forEach((parcel) => {
        //  this is not affecting my output see line 104
        newData.push({
          "Parcel Id": parcel.parcelId,
          "Lot No": parcel.lotNo,
          "Carton No": parcel.cartonNo,
          "Tracking No": parcel.trackingNo,
          "Total Bill": `${parcel.finalTotal}Tk`,
          "Refund Amount": parcel.refundAmount
            ? `${parcel.refundAmount}Tk`
            : "0Tk",
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index]
              ? newData[cellInfo.index][cellInfo.column.id]
              : null,
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      console.log(myData);
      myData.forEach((parcel) => {
        //  this is not affecting my output see line 104
        newData.push({
          "Parcel Id": parcel.parcelId,
          "Lot No": parcel.lotNo,
          "Carton No": parcel.cartonNo,
          "Tracking No": parcel.trackingNo,
          "Total Bill": `${parcel.finalTotal}Tk`,
          "Refund Amount": parcel.refundAmount
            ? `${parcel.refundAmount}Tk`
            : "0Tk",
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: "center",
        },
      });
    }
    columns.push(
      {
        Header: <b>Product Info</b>,
        id: "orderDetails",
        accessor: (str) => "orderDetails",
        Cell: (row) => (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id={`popover-positioned-bottom`}
                style={{ minWidth: "20%" }}
              >
                <Popover.Title
                  style={{ backgroundColor: "#13c9ca", color: "white" }}
                  as="h3"
                >{`Parcel Id: ${row.original["Parcel Id"]}`}</Popover.Title>
                <Popover.Content className="popover-body-container">
                  <div
                    style={{
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Product Name:&nbsp;
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).productName
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Product Quantity:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).quantity
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Total CBM:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).totalCbm
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Total Weight:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).grossWeight
                            : ""}
                          kg
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Chargeable Weight:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? Math.round(
                                myData.find(
                                  (parcel) =>
                                    parcel.parcelId ===
                                    row.original["Parcel Id"]
                                ).totalCbm * 167
                              )
                            : ""}
                          kg
                        </span>
                      </p>
                      <div
                        style={{
                          color: "white",
                          padding: "5px 10px",
                          marginTop: "10px",
                          backgroundColor: "purple",
                          borderRadius: "5rem",
                        }}
                      >
                        {myData.length > 0
                          ? myData.find(
                              (parcel) =>
                                parcel.parcelId === row.original["Parcel Id"]
                            ).note
                          : null}
                      </div>
                    </div>
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <button className="btn btn-secondary">info</button>
          </OverlayTrigger>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
      },
      {
        Header: <b>Refund</b>,
        id: "delete",
        accessor: (str) => "delete",
        Cell: (row) => {
          const parcelObj = myData.find(
            (parcel) => parcel.parcelId === row.original["Parcel Id"]
          );
          if (!parcelObj.refundStatus) {
            return (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    this.props.updateRefundStatusRedux({
                      ...parcelObj,
                      refundStatus: "Pending",
                    })
                  }
                >
                  claim
                </button>
              </div>
            );
          } else {
            if (parcelObj.refundStatus === "Pending") {
              return (
                <div style={{ color: "orange" }}>
                  <i className="icofont-spinner"></i>
                  Pending
                </div>
              );
            }
            if (parcelObj.refundStatus === "Claimed") {
              return (
                <div style={{ color: "greeen" }}>
                  <i className="icofont-like"></i>
                  Claimed
                </div>
              );
            }
            if (parcelObj.refundStatus === "Rejected") {
              return (
                <div style={{ color: "red" }}>
                  <i className="icofont-close"></i>
                  Rejected
                </div>
              );
            }
          }
        },
        style: {
          textAlign: "center",
        },
        sortable: false,
      }
    );

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allLots: state.lots.lots,
  };
};
export default connect(mapStateToProps, { updateRefundStatusRedux })(Datatable);
