import React, { Component } from "react";
import "./searchbar.css";
import { connect } from "react-redux";
import {
  getAllExpressRatesParcelRedux,
  getAllExpressRatesDocumentRedux,
  getAllD2DRatesRedux,
  setExpressResultRedux,
  setD2dResultRedux,
  setFreightResultRedux,
} from "../../../../actions/index";
import {
  handleExpressSubmit,
  handleDoorToDoorSubmit,
  handleFreightSubmit,
} from "./SearchSubmit";
import lookup from "country-code-lookup";
import Ship from "./assets/ship.png";
import Plane from "./assets/plane.png";
import Express from "./assets/express.png";
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      air: true,
      sea: false,
      express: false,
      freight: false,
      doorToDoor: true,
      toggleLcl: false,
      toggleProductType: false,

      expressRatesType: "",
      expressRatesFrom: "Dhaka,Bangladesh",
      expressRatesParcelTo: "",
      expressRatesParcelWeightType: "",
      expressRatesParcelProductType: "",

      boxTypeParcel: [],
      boxTypeDocument: [],

      shipTo: "Dhaka,Bangladesh",
      shipToFreight: "",
      shipFrom: "",
      date: "",
      containerType: "",
      weight: "",
      productType: "",

      validatedForm: false,
      showSuggestionShipTo: false,
      showSuggestionShipFrom: false,
    };
  }

  componentDidMount = async () => {
    await this.props.getAllExpressRatesDocumentRedux();
    await this.props.getAllExpressRatesParcelRedux();
  };

  initialState = (headerSelection) => {
    this.setState({
      air: headerSelection === "air" ? true : false,
      sea: headerSelection === "sea" ? true : false,
      express: headerSelection === "express" ? true : false,
      freight: false,
      doorToDoor: true,
      toggleLcl: false,
      toggleProductType: false,

      expressRatesType: "",
      expressRatesFrom: "Dhaka,Bangladesh",
      expressRatesParcelTo: "",
      expressRatesParcelWeightType: "",
      expressRatesParcelProductType: "",

      boxTypeParcel: [],
      boxTypeDocument: [],

      shipTo: "Dhaka,Bangladesh",
      shipToFreight: "",
      shipFrom: "",
      date: "",
      containerType: "",
      weight: "",
      productType: "",
      validatedForm: false,
      showSuggestionShipTo: false,
      showSuggestionShipFrom: false,
    });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
    if (value == "") {
      this.setState({ validatedForm: false });
    }
  };

  handleCountryChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(this.state.expressRatesParcelTo);

      this.props.allExpressRatesDocument.map((country) => {
        console.log(country.country);
        const countryObjDocument = this.props.allExpressRatesDocument.find(
          (country) => country.country == this.state.expressRatesParcelTo
        );
        const keyArrayDocument = countryObjDocument
          ? Object.keys(countryObjDocument).filter((key) => key !== "country")
          : [];
        const keyArrayDoc = keyArrayDocument.map((box) => {
          if (box == "half") {
            return `275☓350mm (0.5kg)`;
          } else if (box == "one") {
            return `337☓182☓100mm (1kg)`;
          } else if (box == "oneAndHalf") {
            return `337☓182☓120mm (1.5kg)`;
          } else if (box == "two") {
            return `337☓322☓100mm (2kg)`;
          } else if (box == "twoAndHalf") {
            return `337☓322☓130mm (2.5kg)`;
          } else {
            return `337☓322☓345mm (${box}kg)`;
          }
        });
        const countryObjParcel = this.props.allExpressRatesParcel.find(
          (country) => country.country == this.state.expressRatesParcelTo
        );
        const keyArrayParcel = countryObjParcel
          ? Object.keys(countryObjParcel).filter((key) => key !== "country")
          : [];

        const keyArrayPar = keyArrayParcel.map((box) => {
          if (box == "half") {
            return `275☓350mm (0.5kg)`;
          } else if (box == "one") {
            return `337☓182☓100mm (1kg)`;
          } else if (box == "oneAndHalf") {
            return `337☓182☓120mm (1.5kg)`;
          } else if (box == "two") {
            return `337☓322☓100mm (2kg)`;
          } else if (box == "twoAndHalf") {
            return `337☓322☓130mm (2.5kg)`;
          } else if (box == "three") {
            return `337☓322☓150mm (3kg)`;
          } else if (box == "five") {
            return `337☓322☓190mm (5kg)`;
          } else if (box == "sevenAndHalf") {
            return `337☓322☓245mm (7.5kg)`;
          } else if (box == "ten") {
            return `337☓322☓345mm (10kg)`;
          } else if (box == "twenty") {
            return `437☓372☓345mm (20kg)`;
          } else if (box == "thirty") {
            return `537☓422☓365mm (30kg)`;
          } else {
            return `637☓472☓405mm (40kg)`;
          }
        });

        this.setState({
          boxTypeDocument: keyArrayDoc,
          boxTypeParcel: keyArrayPar,
        });
        if ((value = "")) {
          this.setState({ validatedForm: false });
        }
      });
    });
  };

  handleCountryChangeSeaAir = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (this.state.sea) {
        this.props.getAllD2DRatesRedux("sea", this.state.shipFrom);
      }
      if (this.state.air) {
        this.props.getAllD2DRatesRedux("air", this.state.shipFrom);
      }

      if (value === "") {
        this.setState({ validatedForm: false });
      }
    });
  };

  handleCountryChangeFreightShipFrom = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    console.log(value);
    this.setState({ showSuggestionShipFrom: true });
    if (!value) {
      this.setState({
        showSuggestionShipFrom: false,
      });
    }
  };

  handleCountryChangeFreightShipTo = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    console.log(value);
    this.setState({ showSuggestionShipTo: true });
    if (!value) {
      this.setState({
        showSuggestionShipTo: false,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ validatedForm: true }, () => {
      document.getElementById("form-submit-button").click();
    });

    const {
      expressRatesType,
      expressRatesParcelTo,
      expressRatesParcelWeightType,
      expressRatesParcelProductType,
    } = this.state;
    const { allExpressRatesDocument, allExpressRatesParcel } = this.props;

    if (this.state.express) {
      const result = handleExpressSubmit(
        expressRatesType,
        expressRatesParcelTo,
        expressRatesParcelWeightType,
        allExpressRatesParcel,
        allExpressRatesDocument
      );
      this.props.setExpressResultRedux({
        shipmentMethod: "Express",
        parcelType: expressRatesType,
        parcelBox: expressRatesParcelWeightType,
        productName: expressRatesParcelProductType,
        parcelTo: expressRatesParcelTo,
        bookingStatus: "Pending",
        total: result,
      });
    } else {
      if (this.state.doorToDoor) {
        const { shipFrom, weight, productType } = this.state;
        const { allD2dRates } = this.props;
        const result = handleDoorToDoorSubmit(
          weight,
          productType,
          allD2dRates,
          this.state.sea
        );
        this.props.setD2dResultRedux({
          shipBy: this.state.sea ? "sea" : "air",
          shipmentMethod: "D2D",
          shipFrom,
          weight,
          productType,
          bookingStatus: "Pending",
          d2dSeaAir: this.state.sea ? "sea" : "air",
          ...result,
        });
      }
      if (this.state.freight) {
        const { shipFrom, date, shipToFreight } = this.state;
        this.props.setFreightResultRedux({
          shipBy: this.state.sea ? "sea" : "air",
          shipmentMethod: "Freight",
          shipFrom,
          shipToFreight,
          date,
          bookingStatus: "Pending",
          freightResultFrom: this.state.air ? "air" : "sea",
        });
      }
    }
    // this.initialState("air");
  };

  renderShowSuggestion = (shipToOrFrom) => {
    if (this.state[shipToOrFrom]) {
      const suggestionCountry = lookup.countries.filter((country) =>
        country.country
          .toLowerCase()
          .includes(this.state[shipToOrFrom].toLowerCase())
      );

      return suggestionCountry.slice(0, 10).map((country) => (
        <li
          key={country.country}
          onClick={() => {
            this.setState({
              [shipToOrFrom]: country.country,
            });
            if (shipToOrFrom === "shipFrom") {
              this.setState({
                showSuggestionShipFrom: false,
              });
            }
            if (shipToOrFrom === "shipToFreight") {
              this.setState({
                showSuggestionShipTo: false,
              });
            }
          }}
        >
          {country.country}
        </li>
      ));
    } else {
      return null;
    }
  };

  render() {
    const freight = {
      color: "red",
    };
    const doorToDoor = {
      color: "#bf03bf",
    };

    const express = {
      color: "rgb(222 193 46)",
    };

    const conditionals = {};
    if (this.state.validatedForm) {
      conditionals["data-toggle"] = "modal";
    }

    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const todayDate = `${year}-${month}-${day}`;
    console.log(todayDate);
    return (
      <div className="searchbar-container-home">
        <h5 className="header-title-1">
          SHIPPING TO AND FROM ANYWHERE IN THE WORLD
        </h5>
        <h1 className="header-title-2">
          Find the &nbsp;
          {!this.state.express ? (
            <>
              {this.state.freight ? (
                <>
                  <span
                    style={{
                      fontSize: "130%",
                      color: freight.color,
                      fontWeight: "bolder",
                      textTransform: "none",
                    }}
                  >
                    best freight
                  </span>
                  &nbsp;quote
                </>
              ) : (
                <>
                  <span
                    style={{
                      fontSize: "130%",
                      color: doorToDoor.color,
                      fontWeight: "bolder",
                      textTransform: "none",
                    }}
                  >
                    best door to door
                  </span>
                  &nbsp; rate
                </>
              )}
            </>
          ) : (
            <>
              <span
                style={{
                  fontSize: "130%",
                  color: express.color,
                  fontWeight: "bolder",
                  textTransform: "none",
                }}
              >
                best express
              </span>
              &nbsp; rate
            </>
          )}
        </h1>
        <div className="search-bar">
          <div className="search-options">
            {this.state.air ? (
              <div
                className="option"
                style={{
                  color: "white",
                  borderBottom: `4px solid white`,
                }}
                onClick={() => this.initialState("air")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <img src={Plane} style={{ height: 30, width: 30 }} />
                  <p style={{ color: "#ccceda", margin: "auto" }}> Air</p>
                </div>
              </div>
            ) : (
              <div
                className="option"
                style={{
                  color: "white",
                  borderBottom: `4px solid #777777`,
                }}
                onClick={() => this.initialState("air")}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    src={Plane}
                    style={{
                      height: 30,
                      width: 30,
                      filter: "grayscale(100%)",
                      filter: "brightness(0.5)",
                    }}
                  />
                  <p style={{ margin: "auto" }}> Air</p>
                </div>
              </div>
            )}

            <>
              {this.state.sea ? (
                <div
                  className="option"
                  style={{
                    color: "white",
                    borderBottom: `4px solid white`,
                  }}
                  onClick={() => this.initialState("sea")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginLeft: "20px",
                    }}
                  >
                    <img src={Ship} style={{ height: 30, width: 30 }} />
                    <p style={{ color: "#ccceda", margin: "auto" }}> Sea</p>
                  </div>
                </div>
              ) : (
                <div
                  className="option"
                  style={{
                    color: "white",
                    borderBottom: `4px solid #777777`,
                  }}
                  onClick={() => this.initialState("sea")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      src={Ship}
                      style={{
                        height: 30,
                        width: 30,
                        filter: "grayscale(100%)",
                      }}
                    />
                    <p style={{ margin: "auto" }}> Sea</p>
                  </div>
                </div>
              )}
            </>
            <>
              {this.state.express ? (
                <div
                  className="option "
                  style={{
                    color: express.color,
                    borderBottom: `4px solid white`,
                  }}
                  onClick={() => this.initialState("express")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <img src={Express} style={{ height: 30, width: 30 }} />
                    <p style={{ color: "#ccceda", margin: "auto" }}> Express</p>
                  </div>
                </div>
              ) : (
                <div
                  className="option express"
                  style={{
                    color: "white",
                    borderBottom: `4px solid #777777`,
                  }}
                  onClick={() => this.initialState("express")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <img
                      src={Express}
                      style={{
                        height: 30,
                        width: 30,
                        filter: "grayscale(100%)",
                      }}
                    />
                    <p style={{ margin: "auto" }}>Express</p>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="search-input-bar" style={{ paddingTop: "10px" }}>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                {!this.state.express ? (
                  <div
                    className="logo-input-container mid-options"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <>
                      {this.state.doorToDoor ? (
                        <span
                          className="door-to-door"
                          style={{
                            cursor: "pointer",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            background: doorToDoor.color,
                            borderRadius: "8px",
                          }}
                          onClick={() =>
                            this.setState({ freight: false, doorToDoor: true })
                          }
                        >
                          <i
                            className="d2d-text"
                            style={{
                              padding: "10px",
                              backgroundColor: doorToDoor.color,
                              color: "black",
                              borderRadius: "8px",
                            }}
                          >
                            D2D
                          </i>
                        </span>
                      ) : (
                        <span
                          className="door-to-door"
                          style={{
                            cursor: "pointer",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                          }}
                          onClick={() =>
                            this.setState({ freight: false, doorToDoor: true })
                          }
                        >
                          <i
                            className="d2d-text"
                            style={{ color: "gray", padding: "10px" }}
                          >
                            D2D
                          </i>
                        </span>
                      )}
                    </>
                    {this.state.freight ? (
                      <span
                        className="freight"
                        style={{
                          cursor: "pointer",
                          paddingLeft: "0px",
                          paddingRight: "5px",
                          borderRight: "2px solid gray",
                          background: freight.color,
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          this.setState({ freight: true, doorToDoor: false })
                        }
                      >
                        <i
                          className="freight-text"
                          style={{
                            padding: "10px",
                            background: freight.color,
                            color: "black",
                            borderRadius: "8px",
                          }}
                        >
                          freight
                        </i>
                      </span>
                    ) : (
                      <span
                        className="freight"
                        style={{
                          cursor: "pointer",
                          paddingLeft: "0px",
                          background: "white",
                          paddingRight: "5px",
                          borderRight: "2px solid gray",
                        }}
                        onClick={() =>
                          this.setState({ freight: true, doorToDoor: false })
                        }
                      >
                        <i
                          className="freight-text"
                          style={{ color: "gray", padding: "10px" }}
                        >
                          freight
                        </i>
                      </span>
                    )}

                    <span style={{ padding: "0px", marginTop: "0px" }}>
                      <i className="icofont-"></i>
                    </span>
                  </div>
                ) : (
                  <div className="logo-input-container select-type">
                    <span>
                      <i
                        className="icofont-cube"
                        style={{
                          color: express.color,
                        }}
                      ></i>
                    </span>

                    <select
                      style={{ border: "0px", marginTop: "5px" }}
                      value={this.state.expressRatesType}
                      onChange={this.handleChange}
                      name="expressRatesType"
                      className="custom-select"
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="Document">Document</option>
                      <option value="Parcel">Parcel</option>
                    </select>

                    <span
                      style={{
                        padding: "0px",
                        marginRight: "4px",
                        marginTop: "0px",
                      }}
                    >
                      <i className="icofont-"></i>
                    </span>
                  </div>
                )}

                <div className="logo-input-container">
                  <span style={{ borderLeft: "0px" }}>
                    <i
                      className="icofont-industries-2"
                      style={{
                        color: this.state.express
                          ? express.color
                          : this.state.freight
                          ? freight.color
                          : doorToDoor.color,
                      }}
                    ></i>
                  </span>
                  {this.state.express ? (
                    <input
                      style={{ backgroundColor: "white", marginTop: "7px" }}
                      type="text"
                      name="expressRatesFrom"
                      value={this.state.expressRatesFrom}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Country,City,Port"
                      readOnly
                      required
                    />
                  ) : !this.state.freight ? (
                    <select
                      style={{ border: "0px", marginTop: "5px" }}
                      onChange={this.handleCountryChangeSeaAir}
                      name="shipFrom"
                      value={this.state.shipFrom}
                      className="custom-select"
                      required
                    >
                      <option value="">Select Country</option>
                      <option value="china">China</option>
                      <option value="malaysia">Malaysia</option>
                      <option value="hongkong">Hongkong</option>
                      <option value="singapore">Singapore</option>
                      <option value="thailand">Thailand</option>
                      {this.state.air && (
                        <>
                          <option value="dubai">Dubai</option>
                          <option value="pakistan">Pakistan</option>
                          <option value="india">India</option>
                        </>
                      )}
                    </select>
                  ) : (
                    <>
                      <input
                        style={{ backgroundColor: "white", marginTop: "7px" }}
                        type="text"
                        name="shipFrom"
                        value={this.state.shipFrom}
                        onChange={this.handleCountryChangeFreightShipFrom}
                        className="form-control"
                        placeholder="Country,City,Port"
                        autoComplete="off"
                        required
                      />
                      <ul
                        className="below-searchbar-recommendation"
                        style={{
                          display: this.state.showSuggestionShipFrom
                            ? "flex"
                            : "none",
                          flexDirection: "column",
                        }}
                      >
                        {this.renderShowSuggestion("shipFrom")}
                      </ul>
                    </>
                  )}
                </div>
                <div
                  className={`arrow-container ${
                    this.state.express ? "express" : ""
                  }`}
                >
                  <div
                    style={{
                      paddingTop: "3px",
                      border: "blue 1px slolid",
                      borderRadius: ".5rem",
                      marginTop:
                        window.innerWidth < "600px"
                          ? this.state.express
                            ? "7rem"
                            : null
                          : null,
                    }}
                  >
                    <i
                      className="icofont-long-arrow-left"
                      style={{
                        color: "black",
                        fontSize: "140%",
                        fontWeight: "bold",
                        padding: "2px",
                        backgroundColor: !this.state.express
                          ? this.state.freight
                            ? freight.color
                            : doorToDoor.color
                          : express.color,
                      }}
                    ></i>
                  </div>
                  <div>
                    <i
                      className="icofont-long-arrow-right"
                      style={{
                        color: "black",
                        fontSize: "140%",
                        fontWeight: "bold",
                        padding: "2px",
                        backgroundColor: !this.state.express
                          ? this.state.freight
                            ? freight.color
                            : doorToDoor.color
                          : express.color,
                      }}
                    ></i>
                  </div>
                </div>
                <div className="logo-input-container">
                  <span style={{ borderLeft: "0px" }}>
                    <i
                      className="icofont-home"
                      style={{
                        color: this.state.express
                          ? express.color
                          : this.state.freight
                          ? freight.color
                          : doorToDoor.color,
                      }}
                    ></i>
                  </span>
                  {this.state.express ? (
                    <select
                      style={{ border: "0px", marginTop: "5px" }}
                      onChange={this.handleCountryChange}
                      name="expressRatesParcelTo"
                      value={this.state.expressRatesParcelTo}
                      className="custom-select"
                      required
                    >
                      <option value="">Select Country</option>
                      {this.state.expressRatesType == "Parcel"
                        ? this.props.allExpressRatesParcel.map((country) => (
                            <option
                              key={country.country}
                              value={`${country.country}`}
                            >
                              {country.country}
                            </option>
                          ))
                        : this.props.allExpressRatesDocument.map((country) => (
                            <option
                              key={country.country}
                              value={`${country.country}`}
                            >
                              {country.country}
                            </option>
                          ))}
                    </select>
                  ) : !this.state.freight ? (
                    <input
                      style={{ backgroundColor: "white", marginTop: "7px" }}
                      type="text"
                      name="shipTo"
                      value={this.state.shipTo}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Country,City,Port"
                      readOnly
                      required
                    />
                  ) : (
                    <>
                      <input
                        style={{ backgroundColor: "white", marginTop: "7px" }}
                        type="text"
                        name="shipToFreight"
                        value={this.state.shipToFreight}
                        onChange={this.handleCountryChangeFreightShipTo}
                        className="form-control"
                        placeholder="Country,City,Port"
                        autoComplete="off"
                        required
                      />
                      <ul
                        className="below-searchbar-recommendation"
                        style={{
                          display: this.state.showSuggestionShipTo
                            ? "flex"
                            : "none",
                          flexDirection: "column",
                        }}
                      >
                        {this.renderShowSuggestion("shipToFreight")}
                      </ul>
                    </>
                  )}
                </div>
                {!this.state.doorToDoor ? (
                  <div className="logo-input-container">
                    {!this.state.express ? (
                      <>
                        <span>
                          <i
                            className="icofont-calendar"
                            style={{
                              color: this.state.express
                                ? express.color
                                : freight.color,
                            }}
                          ></i>
                        </span>
                        <input
                          type="date"
                          name="date"
                          onChange={this.handleChange}
                          value={this.state.date}
                          className="form-control"
                          placeholder="Date"
                          required
                        />
                      </>
                    ) : (
                      <>
                        <span>
                          <i
                            className="icofont-cube"
                            style={{
                              color: doorToDoor.color,
                            }}
                          ></i>
                        </span>
                        <select
                          style={{ border: "0px", marginTop: "0px" }}
                          className="custom-select"
                          onChange={this.handleChange}
                          name="expressRatesParcelWeightType"
                          value={this.state.expressRatesParcelWeightType}
                          required
                        >
                          <option value="">Select Box Type</option>
                          {this.state.expressRatesType == "Parcel"
                            ? this.state.boxTypeParcel.map((box) => (
                                <option key={box} value={`${box}`}>
                                  {box}
                                </option>
                              ))
                            : this.state.boxTypeDocument.map((box) => (
                                <option key={box} value={`${box}`}>
                                  {box}
                                </option>
                              ))}
                        </select>{" "}
                      </>
                    )}
                  </div>
                ) : null}

                {!this.state.doorToDoor ? (
                  <>
                    {this.state.sea ? (
                      <div className="logo-input-container">
                        <span>
                          <i
                            className="icofont-cube"
                            style={{
                              color: this.state.express
                                ? express.color
                                : freight.color,
                            }}
                          ></i>
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingRight: "5px",
                          }}
                        >
                          <label style={{ fontSize: "80%" }}>
                            Container type
                          </label>
                          <select
                            style={{ border: "0px" }}
                            value={this.state.containerType}
                            name="containerType"
                            onChange={this.handleChange}
                            className="cutom-select"
                            required
                          >
                            <option value="FCL">FCL </option>
                            <option value="LCL">LCL </option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="lcl-fcl logo-input-container"
                        style={{ cursor: "pointer" }}
                      >
                        {!this.state.express ? (
                          <>
                            <span>
                              <i
                                className="icofont-airplane"
                                style={{
                                  color: this.state.freight
                                    ? freight.color
                                    : doorToDoor.color,
                                }}
                              ></i>
                            </span>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingTop: "5px",
                              }}
                              onClick={() =>
                                this.setState({
                                  toggleLcl: !this.state.toggleLcl,
                                })
                              }
                            >
                              <div style={{ fontSize: "80%" }}>
                                air delivery
                              </div>
                              <div style={{ fontWeight: "bold" }}>Air</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <span>
                              <i
                                className="icofont-bag"
                                style={{
                                  color: this.state.exprees.color,
                                }}
                              ></i>
                            </span>
                            {/* {this.state.express ? (
                              <input
                                value={this.state.expressRatesParcelProductType}
                                name="expressRatesParcelProductType"
                                onChange={this.handleChange}
                                style={{ marginBottom: "4px", outline: "0px" }}
                                placeholder="Enter Product Name"
                                type="text"
                                required
                              ></input>
                            ) : (
                              <select
                                style={{ border: "0px", marginTop: "7px" }}
                                onChange={this.handleChange}
                                name="productType"
                                value={this.state.productType}
                                className="custom-select"
                                required
                              >
                                <option value="">Select Product Type</option>
                                <option value="china">china</option>
                                <option value="malaysia">malaysia</option>
                                <option value="hongkong">hongkong</option>
                                <option value="dubai">dubai</option>
                                <option value="pakistan">pakistan</option>
                                <option value="singapore">singapore</option>
                                <option value="thailand">thailand</option>
                                <option value="india">india</option>
                              </select>
                            )} */}
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="logo-input-container"
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        <i
                          className="icofont-cube"
                          style={{
                            color: this.state.express
                              ? express.color
                              : this.state.freight
                              ? freight.color
                              : doorToDoor.color,
                          }}
                        ></i>
                      </span>
                      {this.state.express ? (
                        <select
                          style={{ border: "0px", marginTop: "5px" }}
                          className="custom-select"
                          onChange={this.handleChange}
                          name="expressRatesParcelWeightType"
                          value={this.state.expressRatesParcelWeightType}
                          required
                        >
                          <option value="">Select Box Type</option>
                          {this.state.expressRatesType == "Parcel"
                            ? this.state.boxTypeParcel.map((box) => (
                                <option key={box} value={`${box}`}>
                                  {box}
                                </option>
                              ))
                            : this.state.boxTypeDocument.map((box) => (
                                <option key={box} value={`${box}`}>
                                  {box}
                                </option>
                              ))}
                        </select>
                      ) : (
                        <input
                          type="number"
                          min={0}
                          step={0.01}
                          name="weight"
                          onChange={this.handleChange}
                          value={this.state.weight}
                          className="form-control"
                          style={{
                            background: "white",
                            outlineColor: "white",
                            marginTop: "7px",
                          }}
                          placeholder="Enter weight(kg)"
                          required
                        />
                      )}
                    </div>
                    {!this.state.express ? (
                      <div className="logo-input-container">
                        {!this.state.express && (
                          <span
                            style={{ marginTop: "5px" }}
                            className="enter-name-span"
                          >
                            <i
                              className="icofont-bag"
                              style={{
                                color: this.state.express
                                  ? express.color
                                  : this.state.freight
                                  ? freight.color
                                  : doorToDoor.color,
                              }}
                            ></i>
                          </span>
                        )}

                        <select
                          style={{ border: "0px", marginTop: "5px" }}
                          onChange={this.handleChange}
                          name="productType"
                          value={this.state.productType}
                          className="custom-select"
                          required
                        >
                          <option value="">Select Product Type</option>
                          {this.props.allD2dRates.map((productType) => (
                            <option value={productType.id} key={productType.id}>
                              {productType.id}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                  </>
                )}
                <button
                  type="submit"
                  className="logo-input-container search-button"
                  style={{
                    backgroundColor: !this.state.express
                      ? this.state.freight
                        ? freight.color
                        : doorToDoor.color
                      : express.color,
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: !this.state.express
                        ? this.state.freight
                          ? freight.color
                          : doorToDoor.color
                        : express.color,
                      color: "black",
                      fontSize: "150%",
                      paddingTop: "5px",
                      borderLeft: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    <i className="icofont-search-1"></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div
            {...conditionals}
            id="form-submit-button"
            data-target={
              !this.state.express
                ? this.state.doorToDoor
                  ? "#request_search_submit_popup_door_to_door"
                  : "#request_search_submit_popup_freight"
                : "#request_search_submit_popup_express"
            }
          ></div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  allExpressRatesParcel: state.expressRatesParcel.expressRatesParcel,
  allExpressRatesDocument: state.expressRatesDocuments.expressRatesDocuments,
  allD2dRates: state.d2dRates.d2dRates,
});
export default connect(mapStateToProps, {
  getAllExpressRatesParcelRedux,
  getAllExpressRatesDocumentRedux,
  setExpressResultRedux,
  setD2dResultRedux,
  setFreightResultRedux,
  getAllD2DRatesRedux,
})(SearchBar);
