import React, { Component } from "react";
import firebase from "./firebase/firebase.utils";
import { SendOtpSms } from "./adnSms";
import { toast } from "react-toastify";
import { sendOtpRedux } from "../actions";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";
import Axios from "axios";
class PhoneModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      time: 60,
      submit_loader: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(this.state.phoneNumber);
    });
  };

  onSignInSubmit = async (event) => {
    event.preventDefault();
    // first send an otp
    if (
      this.state.phoneNumber.length !== 11 &&
      !this.state.phoneNumber.startsWith("0")
    ) {
      alert("Enter valide mobile number without country code.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }

    this.setState({
      submit_loader: true,
    });
    let url = `https://alglimited.com/api/v1/otp-sms-send/${this.state.phoneNumber}`;
    let resultObj;
    await Axios.get(url)
      .then((response) => {
        resultObj = response;
        if (resultObj && resultObj.data && resultObj.data.error == 0) {
          toast.success("An OTP was sent to you successfully!");
          this.props.sendOtpRedux(this.state.phoneNumber);
          this.setState({ phoneNumber: "", submit_loader: false });
          window.document.getElementById("request_otp_popup_modal").click();
        } else if (resultObj && resultObj.data && resultObj.data.error == 400) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(
            "The request was rejected, due to a missing or invalid parameter."
          );
        } else if (resultObj && resultObj.data && resultObj.data.error == 403) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`You don't have permissions to perform the request.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 404) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`The requested resource not found.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 405) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Authorization required.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 409) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Unknown error occurred on Server end.`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 410) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Account expired`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 411) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Reseller Account expired or suspended`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 412) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Invalid Schedule`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 413) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Invalid Sender ID`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 414) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Message is empty`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 415) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Message is too long`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 416) {
          this.setState({ submit_loader: false });
          toast.error(`No valid number found`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 417) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Insufficient balance`);
        } else if (resultObj && resultObj.data && resultObj.data.error == 420) {
          this.setState({ phoneNumber: "", submit_loader: false });
          toast.error(`Content Blocked`);
        } else {
          toast.error("There was an error.Please try again later.");
          this.setState({
            submit_loader: false,
            phoneNumber: "",
          });
        }
      })
      .catch((error) => {
        toast.error("Too many request. Please try again after some times.");
        this.setState({
          submit_loader: false,
        });
      });
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_phone_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div className="modal-content phone-modal">
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="icofont-close-line"></i>
                    </a>
                    <div
                      className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col bg-fixed login-img-bg request_pag_img"
                        style={{ minHeight: "200px" }}
                      >
                        &nbsp;
                      </div>

                      <div
                        className="col-md-7 col-12"
                        style={{ marginTop: "30px" }}
                      >
                        <div className="px-3 m-4">
                          <h2
                            className="h2-xl fw-6"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                            }}
                          >
                            Enter your Mobile no
                          </h2>
                          <form
                            noValidate="novalidate"
                            className="rounded-field"
                          >
                            <div className="form-row mb-1">
                              <div className="col">
                                <input
                                  onChange={this.handleChange}
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  placeholder="Your Mobile no"
                                  value={this.state.phoneNumber}
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="col pt-2">
                                <button
                                  id="number-submit"
                                  type="submit"
                                  className="form-btn btn-theme bg-orange"
                                  onClick={this.onSignInSubmit}
                                  style={{
                                    minWidth: "130px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!this.state.submit_loader && (
                                    <>
                                      Get Otp
                                      <i className="icofont-rounded-right"></i>
                                    </>
                                  )}

                                  <CircleLoader
                                    loading={this.state.submit_loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                                <div
                                  id="request_otp_popup_modal"
                                  style={{ display: "none" }}
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_otp_popup"
                                ></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { sendOtpRedux })(PhoneModal);
