import React, { useEffect } from "react";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  auth,
  getSelectedIntroModal,
  updateAllDateBooking,
} from "./firebase/firebase.utils";
import "./header.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setIntroModal } from "../actions";
const Header = (props) => {
  useEffect(() => {
    const fetchIntro = async () => {
      const introModal = await getSelectedIntroModal();
      props.setIntroModal(introModal);
      if (introModal) {
        document.getElementById("intro-modal").click();
      }
    };
    fetchIntro();
  }, []);
  return (
    <>
      {/* <Loader /> */}
      <header className="fixed-top header-fullpage bordered-nav wow fadeInDown">
        <div
          className="top-bar-right d-flex align-items-center text-md-left"
          style={{ padding: "5px" }}
        >
          <div className="container px-0">
            <div className="row align-items-center">
              <div className="col d-flex">
                <div className="top-text" style={{ color: "white" }}>
                  <small className="txt-black" style={{ color: "white" }}>
                    Address
                  </small>
                  37/2 Pritom-Zaman Tower, Ground Floor, Godown no:8, Culvert
                  Road, Dhaka-1000. Bangladesh
                </div>
                <div className="top-text" style={{ color: "white" }}>
                  <small className="txt-black" style={{ color: "white" }}>
                    Email Us
                  </small>
                  <Link to="#" style={{ color: "whitesmoke" }}>
                    info@alglimited.com
                  </Link>
                </div>
                <div className="top-text" style={{ color: "white" }}>
                  <small className="txt-black" style={{ color: "white" }}>
                    Phone Number
                  </small>
                  +8801885994310
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          className="navbar navbar-expand-lg"
          style={{ backgroundColor: "white" }}
        >
          <div className="container text-nowrap bdr-nav px-0">
            <div className="d-flex mr-auto">
              <Link className="navbar-brand rounded-bottom light-bg" to="/">
                <img
                  className="header-logo-image"
                  style={{ maxWidth: "70%", filter: "contrast(200%)" }}
                  src="images/alg-header-logo2.png"
                  alt=""
                />
              </Link>
            </div>
            <span className="order-lg-last d-inline-flex request-btn">
              <a
                className="nav-link mr-2 ml-auto"
                href="#"
                id="search_home"
                role="button"
                data-toggle="modal"
                data-target="#request_order_tracking_popup"
                style={{ color: "black" }}
              >
                <i className="icofont-search"></i>
              </a>
            </span>
            <button
              className="navbar-toggler x collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{
                color: "black",
                background: "#f5060c",
                padding: "8px",
                marginLeft: "5px",
              }}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarCollapse"
              data-hover="dropdown"
              data-animations="slideInUp slideInUp slideInUp slideInUp"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={`${process.env.PUBLIC_URL}/`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/about`}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/services`}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/recent-news`}
                  >
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/blogs`}
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle-mob"
                    to={`${process.env.PUBLIC_URL}/`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Contact <i className="icofont-rounded-down"></i>
                  </Link>
                  <ul
                    className="dropdown-menu"
                    style={{ flexDirection: "column" }}
                  >
                    <li style={{ display: "block" }}>
                      <Link
                        className="dropdown-item"
                        to={`${process.env.PUBLIC_URL}/contact`}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className="btn-theme icon-left no-shadow d-none d-lg-inline-block align-self-center nav-link"
                    to="#"
                    role="button"
                    data-toggle="modal"
                    data-target="#request_order_tracking_popup"
                    style={{
                      background: "rgb(245 74 79)",
                      fontSize: "85%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <i className="icofont-location-arrow"></i> Order tracking
                  </Link>
                </li>

                <li className="nav-item" style={{ display: "none" }}>
                  <Link
                    className="btn-theme icon-left no-shadow d-none d-lg-inline-block align-self-center nav-link"
                    id="intro-modal"
                    to="#"
                    role="button"
                    data-toggle="modal"
                    data-target="#request_intro_popup"
                    style={{
                      background: "#7d017d",
                      fontSize: "85%",
                      fontFamily: "sans-serif",
                      display: "none",
                    }}
                  >
                    <i className="icofont-location-arrow"></i> Intro Modal
                  </Link>
                </li>
                {!props.currentUser.displayName ? (
                  <li className="nav-item">
                    <Link
                      className="btn-theme icon-left no-shadow d-none d-lg-inline-block align-self-center nav-link"
                      to="/"
                      role="button"
                      data-toggle="modal"
                      data-target="#request_login_popup"
                      style={{
                        background: "#999999",
                        fontSize: "85%",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <i className="icofont-man-in-glasses"></i>
                      Login
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle-mob"
                      to={`${process.env.PUBLIC_URL}/`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        paddingTop: "6px",
                        paddingBottom: "7px",
                        color: "black",
                      }}
                    >
                      <i className="icofont-man-in-glasses"></i>
                      {props.currentUser.displayName.slice(0, 10)}
                    </Link>
                    <ul className="dropdown-menu">
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/dashboard`}
                        >
                          <i className="icofont-dashboard-web"></i>&nbsp; My
                          Dashboard
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-booking`}
                        >
                          <i className="icofont-ebook"></i>&nbsp; My Bookings
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-parcel`}
                        >
                          <i className="icofont-box"></i>&nbsp; My Parcels
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-invoice`}
                        >
                          <i className="icofont-pay"></i>&nbsp; My Invoices
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-payment`}
                        >
                          <i className="icofont-list"></i>&nbsp; My Payments
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-all-delivered`}
                        >
                          <i className="icofont-tick-boxed"></i>&nbsp; All
                          Delivered
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-refund`}
                        >
                          <i className="icofont-paperclip"></i>&nbsp; My Refund
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <a
                          href={`${process.env.PUBLIC_URL}/`}
                          className="dropdown-item"
                          onClick={async () => {
                            toast.error("You are logged out!");
                            await auth.signOut();
                          }}
                        >
                          <i className="icofont-logout"></i>&nbsp; Log Out
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.currentUser,
  };
};
export default connect(mapStateToProps, { setIntroModal })(Header);
