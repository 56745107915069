const INITIAL_STATE = { p2p: [], p2pAgentRequest: [] };

const p2pReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_P2PS":
      return { ...state, p2p: [...action.payload] };
    case "GET_ALL_P2P_AGENT_REQUEST":
      return { ...state, p2pAgentRequest: [...action.payload] };
    case "UPLOAD_P2P":
      return { ...state, p2p: [action.payload, ...state.p2p] };
    case "UPDATE_P2P":
      const filteredp2p = state.p2p.filter(
        (p2p) => p2p.id !== action.payload.id
      );
      return { ...state, p2p: [action.payload, ...filteredp2p] };

    case "DELETE_P2P":
      const updatedp2pArray = state.p2p.filter(
        (p2p) => p2p.id !== action.payload
      );
      return {
        ...state,
        p2p: [...updatedp2pArray],
      };
    default:
      return { ...state };
  }
};
export default p2pReducer;
