import React, { Component } from "react";
import {
  auth,
  createUserProfileDocument,
} from "../../../components/firebase/firebase.utils";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "./OtherInformationModal.css";
import { updateMyParcelArrayOfUser } from "../../../actions/index";
class ReviewModal extends Component {
  render() {
    const { parcelObj } = this.props;

    console.log(parcelObj);
    return (
      <>
        <div
          className="modal fade"
          id="request_review_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content signup-modal"
              style={{ maxWidth: "700px" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close other_information_modal_close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="modal-close-icon-review"
                    >
                      <i className="icofont-close-line"></i>
                    </a>
                    <div
                      className="d-lg-flex justify-content-center no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div className="col-12">
                        <div className="px-3 m-4 ">
                          <h2
                            className="h2-xl fw-6 sign-in-header-title other-information-header-title"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            Additional Information
                          </h2>
                          <form className="rounded-field">
                            <div className="form-row mb-4">
                              <div className="col">
                                <span
                                  style={{
                                    color: "purple",
                                    marginLeft: "2px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  Local Delivery, Packaging, Insurace,Issues
                                </span>
                                {parcelObj.chineseNote && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      color: "red",
                                      fontSize: "80%",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {parcelObj.chineseNote}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-row mb-1">Products Value</div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <input
                                  name="productsValue"
                                  value={
                                    parcelObj.productsValue
                                      ? `${parcelObj.productsValue}Tk`
                                      : "Not Declared"
                                  }
                                  className="form-control input-field-70"
                                  onChange={this.handleChange}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">
                              Product Contains
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <input
                                  name="customerDeclaredProductContains"
                                  value={
                                    parcelObj.customerDeclaredProductContains
                                      ? `${parcelObj.customerDeclaredProductContains}`
                                      : "none"
                                  }
                                  className="form-control input-field-70"
                                  onChange={this.handleChange}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">Booking Id</div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <input
                                  name="bookingId"
                                  value={`${
                                    parcelObj.bookingId
                                      ? parcelObj.bookingId
                                      : "none"
                                  }`}
                                  className="form-control input-field-70"
                                  onChange={this.handleChange}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">
                              Delivery Address
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <textarea
                                  value={
                                    parcelObj.deliveryAddress
                                      ? parcelObj.deliveryAddress
                                      : "Alg office"
                                  }
                                  className="form-control input-field-70"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row mb-1">
                              Any Other Problems
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <textarea
                                  value={
                                    parcelObj.chineseNote
                                      ? parcelObj.chineseNote
                                      : "No Problem"
                                  }
                                  className="form-control input-field-70"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="col">
                                <div className="form-row mb-1">
                                  Packaging Cost
                                </div>
                                <input
                                  value={`${
                                    parcelObj.packagingCost
                                      ? parcelObj.packagingCost
                                      : "0"
                                  }Tk`}
                                  className="form-control"
                                  readOnly
                                />
                              </div>
                              <div className="col">
                                <div className="form-row mb-1">
                                  Chosed Packaging
                                </div>
                                <input
                                  value={
                                    parcelObj.packagingChosed
                                      ? parcelObj.packagingChosed
                                      : "No packaging"
                                  }
                                  className="form-control  mb-3"
                                  readOnly
                                />
                              </div>
                            </div>

                            <div className="form-row mb-3">
                              <div className="col">
                                <div className="form-row mb-1">Insurance</div>
                                <input
                                  value={
                                    parcelObj.insurance
                                      ? parcelObj.insurance
                                      : "No insurance"
                                  }
                                  className="form-control  mb-3"
                                  readOnly
                                />
                              </div>
                              <div className="col">
                                <div className="form-row mb-1">
                                  Edit Approved
                                </div>
                                <input
                                  value={
                                    parcelObj.editApproved ? "Yes" : "Pending"
                                  }
                                  className="form-control  mb-3"
                                  readOnly
                                />
                              </div>
                            </div>
                            {parcelObj.imageUrl && (
                              <div className="form-row mb-3">
                                <a href={parcelObj.imageUrl} target="_blank">
                                  <img
                                    style={{
                                      maxHeight: "300px",
                                      maxWidth: "200px",
                                    }}
                                    src={parcelObj.imageUrl}
                                  />
                                </a>
                              </div>
                            )}

                            <div className="form-row mt-4"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelObj: state.parcelObj.parcelObj,
  };
};
export default withRouter(
  connect(mapStateToProps, { updateMyParcelArrayOfUser })(ReviewModal)
);
