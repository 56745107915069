import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllNoticesRedux, getAllBlogsRedux } from "../actions/index";
import sslCommerze from "./ssl commerze.png";
class Footer extends Component {
  componentDidMount = async () => {
    await this.props.getAllNoticesRedux();
    await this.props.getAllBlogsRedux();
  };

  render() {
    return (
      <>
        {/* footer start */}
        <footer className="wide-tb-70 bg-light-gray pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 ">
                <div className="logo-footer">
                  <img
                    src="images/alg-header-logo2.png"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </div>

                <p>
                  The Cargo Company was established in 2016 to supply customers
                  and trading partners with a cost effective, reliable range of
                  international logistics services.
                </p>

                <p>
                  we have completed over 4 years in the shipping industry and
                  this experience has helped us build a reputation as
                  professional, reliable and efficient company.
                </p>

                <h3 className="footer-heading">We're Social</h3>
                <div className="social-icons">
                  <a href="https://www.facebook.com/algtrading" target="_blank">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/alg-limited"
                    target="_blank"
                  >
                    <i className="icofont-linkedin"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/alglimited"
                    target="_blank"
                  >
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="https://twitter.com/LimitedAlg" target="_blank">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a
                    href="https://www.reddit.com/user/alglimited/"
                    target="_blank"
                  >
                    <i className="icofont-reddit"></i>
                  </a>
                  <a href="https://myspace.com/alglimited" target="_blank">
                    <i className="icofont-brand-myspace"></i>
                  </a>
                  <a href="https://imgur.com/user/alglimited" target="_blank">
                    <i className="icofont-italic"></i>
                  </a>
                  <a
                    href="https://www.pinterest.com/alglimited2016"
                    target="_blank"
                  >
                    <i className="icofont-pinterest"></i>
                  </a>
                  <a
                    href="https://www.scoop.it/topic/alg-limited"
                    target="_blank"
                  >
                    <i className="icofont-italic-alt"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 ">
                <h3 className="footer-heading">Recent Post</h3>
                <div className="blog-list-footer">
                  <ul className="list-unstyled">
                    {this.props.allNotices && this.props.allNotices.length > 0
                      ? this.props.allNotices.slice(-2).map((notice) => (
                          <li key={notice.id}>
                            <div className="media">
                              <div className="post-thumb">
                                <img
                                  src="images/post_thumb_2.jpg"
                                  alt=""
                                  className="rounded-circle"
                                />
                              </div>
                              <div className="media-body post-text">
                                <h5 className="mb-3 h5-md">
                                  <Link to={`/recent-news/${notice.id}`}>
                                    {notice.noticeTitle.slice(0, 30)}
                                  </Link>
                                </h5>
                                <p>{notice.noticeBody.slice(0, 50)}</p>

                                <div className="comment-box">
                                  <span>
                                    <i className="icofont-ui-calendar"></i>
                                    {notice.createdAt}
                                  </span>
                                  <span>
                                    <Link to={`/recent-news/${notice.id}`}>
                                      <i className="icofont-speech-comments"></i>
                                      25
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 ">
                <h3 className="footer-heading">Download App</h3>
                <div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.algcargos"
                      target="_blank"
                    >
                      <img
                        src="images/googlePlay.png"
                        style={{ width: "50%" }}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://apps.apple.com/us/app/alg-cargo-and-logistics/id1617212524"
                      target="_blank"
                    >
                      <img src="images/appStore.png" style={{ width: "50%" }} />
                    </a>
                  </div>
                  <div>
                    <img />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright-wrap bg-navy-blue wide-tb-30">
            <div className="container">
              <div className="row text-md-left text-center">
                <div className="col-sm-12 col-md-6 copyright-links">
                  <Link to={`${process.env.PUBLIC_URL}/terms-and-condition`}>
                    Terms and Condition
                  </Link>
                  <span>|</span>
                  <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                    Privacy Policy
                  </Link>
                  <span>|</span>
                  <Link to={`${process.env.PUBLIC_URL}/refund-policy`}>
                    Refund Policy
                  </Link>
                  <span>|</span>
                  <Link to={`${process.env.PUBLIC_URL}/contact`}>CONTACT</Link>
                  <span>|</span>
                  <Link to={`${process.env.PUBLIC_URL}/freight-forwarders`}>
                    Fregiht Forwarders (by BAFFA)
                  </Link>
                </div>
                <div className="col-sm-12 col-md-6 text-md-right text-center">
                  copyright
                  <Link to={`${process.env.PUBLIC_URL}/`} rel="nofollow">
                    Alglimited.com
                  </Link>
                  © 2021 All Rights Reserved
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={sslCommerze}
                  alt=""
                  style={{
                    maxWidth: "70%",
                  }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allNotices: state.notices.notices,
  };
};
export default connect(mapStateToProps, {
  getAllNoticesRedux,
  getAllBlogsRedux,
})(Footer);
