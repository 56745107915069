import React from "react";
import { connect } from "react-redux";
import "./content.css";
import { withRouter } from "react-router";
import { getAllForwarderRedux } from "../../../actions";
import { useEffect, useState } from "react";
import building from "./building.png";
import user from "./user.png";
const Content = (props) => {
  const [forwarderObj, setForwarderObj] = useState(null);
  useEffect(() => {
    props.getAllForwarderRedux();
  }, []);
  const { forwarders } = props;
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container pos-rel">
            <div className="row" style={{ marginTop: -30 }}>
              <div className="col-md-12 responsive-table-container">
                <div
                  className="row"
                  style={{
                    justifyContent: "center",
                    fontWeight: "bold",
                    color: "#363636",
                    fontSize: 35,
                  }}
                >
                  Members List
                </div>
                <div className="card-body responsive-table-card">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover table-responsive-xl">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                            colSpan={2}
                          >
                            Company Name
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            BMN
                          </th>
                          <th
                            scope="col"
                            colSpan={3}
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Address
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                            colSpan={2}
                          >
                            Contact Person
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Details
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {forwarders.map((forwarder, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td colSpan={2}>{forwarder.company}</td>
                            <td>{forwarder.bmn}</td>
                            <td colSpan={3}>{forwarder.address}</td>
                            <td colSpan={2}>
                              {forwarder.contactPerson} <br />
                              {forwarder.position}
                            </td>
                            <td>
                              <div>
                                <p
                                  style={{
                                    padding: "4px",
                                    border: "1px solid red",
                                    color: "red",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    maxHeight: 30,
                                    maxWidth: 80,
                                  }}
                                  className="more-button"
                                  data-toggle="modal"
                                  data-target="#forwarderDetailModal"
                                  onClick={() => {
                                    setForwarderObj(forwarder);
                                  }}
                                >
                                  More..
                                </p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        className="modal fade"
        id="forwarderDetailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ margin: "auto" }}
        >
          <div className="modal-content" style={{ top: 10, margin: "auto" }}>
            <div
              className="modal-header"
              style={{
                backgroundColor: "#f4eadb",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <div className="row" style={{ minWidth: "100%" }}>
                <div>
                  <img src={building} style={{ height: 140, width: 120 }}></img>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <div
                    className="row"
                    style={{
                      fontWeight: "bold",
                      fontSize: "20",
                      letterSpacing: 1,
                      marginBottom: 10,
                      marginTop: 20,
                    }}
                  >
                    {forwarderObj && forwarderObj.company}
                  </div>
                  <div
                    className="row"
                    style={{ fontWeight: "bold", marginBottom: 7 }}
                  >
                    Address:
                    <span style={{ fontWeight: "lighter" }}>
                      &nbsp;{forwarderObj && forwarderObj.address}
                    </span>
                  </div>
                  <div
                    className="row"
                    style={{ fontWeight: "bold", marginBottom: 7 }}
                  >
                    Mobile No:
                    <span style={{ fontWeight: "lighter" }}>
                      &nbsp;{forwarderObj && forwarderObj.mobile}
                    </span>
                  </div>
                  <div
                    className="row"
                    style={{ fontWeight: "bold", marginBottom: 7 }}
                  >
                    {" "}
                    Email:
                    <span style={{ fontWeight: "lighter" }}>
                      &nbsp;{forwarderObj && forwarderObj.email}
                    </span>
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="personal-info-close"
              >
                <span aria-hidden="true" style={{ color: "black" }}>
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body">
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "darkgray",
                  }}
                >
                  BRIEF OF COMPANY{" "}
                </div>
                <div
                  style={{
                    height: 3,
                    width: 50,
                    backgroundColor: "darkorange",
                    marginBottom: 10,
                  }}
                ></div>
                <div
                  style={{
                    color: "black",
                    letterSpacing: 1,
                    lineHeight: 1.3,
                  }}
                >
                  The Cargo Company was established in 2016 to supply customers
                  and trading partners with a cost effective, reliable range of
                  international logistics services. Today we have completed over
                  4 years in the shipping industry and this experience has
                  helped us build a reputation as professional, reliable and
                  efficient company. We have been immensely blessed by support
                  from our customers which has spelled our success story. We are
                  today one of the fastest growing logistics providers in
                  Bangladesh with a strong agency network all around the world.
                  We are member of Bangladesh China chamber of commerce and
                  industry (BCCCI) and E-Commerce association of Bangladesh
                  (e-Cab). We have a dedicated, hands-on team committed to
                  providing the highest standards of services. Our employees are
                  regularly trained and the market trends are systematically
                  investigated by experts; reason being that we believe our
                  customers deserve nothing but the best. Our employees arrange
                  the most complex of road, air and ocean shipments,
                  door-to-door deliveries and insurance, warehousing and
                  distribution services. We are also authorized custom house
                  agents since 2006 and thus render different types of custom
                  clearance services to the clients. We handle all
                  documentation, custom broking and inland clearance for our
                  customers. Every day The Cargo Co. solves logistic doubts of
                  its clients and provides them a tailor-made supply chain
                  solution.
                </div>
                <div
                  style={{
                    backgroundColor: "#ece9e9",
                    border: "1px dashed #ccc",
                    marginTop: 35,
                    padding: 20,
                  }}
                >
                  <div className="row">
                    <div>
                      <img src={user} style={{ height: 100, widht: 100 }}></img>
                    </div>
                    <div style={{ marginTop: 20, marginLeft: 20 }}>
                      <div
                        className="row"
                        style={{ fontWeight: "bold", marginBottom: 5 }}
                      >
                        {forwarderObj && forwarderObj.contactPerson}
                      </div>
                      <div className="row" style={{ color: "gray" }}>
                        {forwarderObj && forwarderObj.position}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    forwarders: state.forwarder.forwarders,
  };
};
export default withRouter(
  connect(mapStateToProps, { getAllForwarderRedux })(Content)
);
