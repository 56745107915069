import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setBookingRequestRedux } from "./../actions/index";
import "./OrderTrackingModalResult.css";
class BookingTrackingModalResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      ctnQuantity: "",
      ctnHeight: "",
      ctnWidth: "",
      ctnLength: "",
      productContains: "",
      productBrand: "",
    };
  }

  render() {
    const { bookingTrackingResult } = this.props;
    let parcelObj = {};
    let userObj = {};
    if (bookingTrackingResult) {
      parcelObj = bookingTrackingResult.bookingObj;
    }

    return (
      <>
        <div
          className="modal fade"
          id="request_booking_tracking_result_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable request_popup"
            role="document"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="modal-content order-tracking-modal-result">
              <div className="modal-body p-0">
                <section className="pos-rel">
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close search-submit-modal-close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="modal-close-icon-door-to-door"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "orange" }}
                      ></i>
                    </a>
                    {bookingTrackingResult !== null ? (
                      <div
                        style={{
                          boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "black",
                          }}
                        >
                          <div
                            className="row mt-4 mb-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "150%",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            {parcelObj.bookingId}
                          </div>

                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              From: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              Dhaka,Bangladesh
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              To: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {parcelObj.parcelTo}
                            </div>
                          </div>

                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Parcel Type: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {parcelObj.parcelType}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Parcel Box: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {parcelObj.parcelBox}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Booking Date: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                                marginBottom: "20px",
                              }}
                            >
                              {parcelObj.date}
                            </div>
                          </div>
                          <div
                            className="row mt-2"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              paddingLeft: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                fontSize: "100%",
                                color: "white",
                              }}
                            >
                              Booking Status: &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: "100%",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              {parcelObj.bookingStatus}
                            </div>
                          </div>
                          {parcelObj.receivedAt && (
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "10%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  fontSize: "100%",
                                  color: "white",
                                }}
                              >
                                Received At: &nbsp;
                              </div>
                              <div
                                style={{
                                  fontSize: "100%",
                                  color: "white",
                                  fontWeight: "bold",
                                  marginBottom: "20px",
                                }}
                              >
                                {parcelObj.receivedAt}
                              </div>
                            </div>
                          )}
                          {parcelObj.bookingStatus == "Success" && (
                            <div
                              className="row mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                paddingLeft: "10%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",

                                  fontSize: "100%",
                                  color: "white",
                                }}
                              >
                                Please send your goods to our Bangladesh office
                                address.
                              </div>
                              <div
                                style={{
                                  fontSize: "100%",
                                  color: "white",
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              ></div>
                            </div>
                          )}
                        </div>

                        <div>
                          <div className="px-3 m-4">
                            <h2
                              className="h2-xl fw-6"
                              style={{
                                color: "purple",
                                fontSize: "140%",
                                fontWeight: "bolder",
                              }}
                            >
                              Order Status
                            </h2>
                            <div clssName="row mt-3"></div>
                            <div className="row mt-5">
                              <div className="col order-tracking-bar">
                                {parcelObj.receivedAt ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-industries"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Received <br />
                                      {parcelObj.receivedAt}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-industries"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Received</p>
                                  </span>
                                )}
                                {parcelObj.shipmentStatusScore >= 1 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-industries"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Local Warehouse
                                      <br />
                                      {parcelObj.LocalWarehouseDate}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-industries"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Local Warehouse</p>
                                  </span>
                                )}
                                {parcelObj.shipmentStatusScore >= 2 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Bangladesh Customs
                                      <br />
                                      {parcelObj.BangladeshCustomsDate}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Bangladesh Customs</p>
                                  </span>
                                )}
                                {parcelObj.shipmentStatusScore >= 3 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-airplane-alt"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Ready to Fly
                                      <br />
                                      {parcelObj.ReadyToFlyDate}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-airplane-alt"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Ready to Fly</p>
                                  </span>
                                )}
                                {parcelObj.shipmentStatusScore >= 4 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-police"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                      <div
                                        className="progress-bar-line"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                        }}
                                      ></div>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Abroad Customs
                                      <br />
                                      {parcelObj.AbroadCustomsDate}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-police"></i>
                                      <div className="progress-bar-line"></div>
                                    </div>
                                    &nbsp; <p>Abroad Customs</p>
                                  </span>
                                )}

                                {parcelObj.shipmentStatusScore >= 5 ? (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i
                                        className="icofont-tick-mark"
                                        style={{
                                          backgroundColor: "rgb(103 0 103)",
                                          color: "white",
                                          border: "1px soild rgb(103 0 103)",
                                        }}
                                      ></i>
                                    </div>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "rgb(103 0 103)",
                                        fontSize: "40%",
                                      }}
                                    >
                                      Delivered
                                      <br />
                                      {parcelObj.DeliveredDate}
                                    </p>
                                  </span>
                                ) : (
                                  <span className="row">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <i className="icofont-tick-mark"></i>
                                    </div>
                                    &nbsp; <p>Delivered</p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col">
                              <div
                                className="center-head"
                                style={{ marginBottom: "0px" }}
                              >
                                <span
                                  className="bg-light-gray"
                                  style={{
                                    textTransform: "none",
                                    color: "purple",
                                  }}
                                >
                                  শিপমেন্ট সংক্রান্ত নোটিশ
                                </span>
                              </div>
                            </div>
                          </div>
                          <marquee
                            style={{
                              color: "red",
                              fontSize: "80%",
                              padding: "10px",
                              paddingTop: "5px",
                              paddingBottom: "15px",
                            }}
                          >
                            {parcelObj.shipmentNotice}
                          </marquee>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "#4e014e",
                          }}
                        >
                          <div
                            className="row mt-4 mb-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "150%",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            Not found
                          </div>
                        </div>

                        <div>
                          <div className="px-3 m-4">
                            <div className="row mt-3"></div>
                            <div
                              className="row mt-5"
                              style={{ color: "darkgray", fontSize: "150%" }}
                            >
                              Sorry! No Order is found with this tracking No.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookingTrackingResult: state.ordersAlg.bookingTrackingResult,
  };
};
export default connect(mapStateToProps, { setBookingRequestRedux })(
  BookingTrackingModalResult
);
