import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import {
  uploadImageRechargeRequest,
  makeP2pAgentRequest,
} from "../../firebase/firebase.utils";
import {
  getAllP2PRedux,
  getAllWarehouseProductsRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
  addToCart,
  removeFromCart,
} from "../../../actions/index";
import { ShoppingCart, Bell } from "react-feather";
import { withRouter } from "react-router-dom";
import { Search } from "react-feather";
export class Warehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",

      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      arrivalDate: "",
      searchFor: "",
    };
  }

  componentDidMount = async () => {
    console.log(this.props);
    const { status } = this.props.match.params;
    const { getAllWarehouseProductsRedux, currentUser } = this.props;

    if (status && currentUser && currentUser.id) {
      await getAllWarehouseProductsRedux(status, currentUser);
    }
  };

  componentWillReceiveProps = async (nextProps) => {
    const { status } = this.props.match.params;
    const status2 = nextProps.match.params.status;
    const { getAllWarehouseProductsRedux } = this.props;
    const { currentUser } = nextProps;
    if (status !== status2 && currentUser && currentUser.id) {
      await getAllWarehouseProductsRedux(status2, currentUser);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    let date = new Date();

    if (this.state.loading) {
      alert("Please wait.. your image is uploading");
      return;
    }
    if (this.state.type === "upload") {
      let bookingObj = {
        id: date.getTime(),
        date: date.toLocaleDateString("en-GB"),
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.props.currentUser.id,
        status: "pending",
      };

      await this.props.uploadP2PRedux(bookingObj);
    } else if (this.state.type === "update") {
      let bookingObj = {
        id: this.state.id,
        date: this.state.date,
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.state.userId,
        status: this.state.status,
      };
      await this.props.updateP2PRedux(bookingObj);
    }

    this.setState({
      name: "",
      quantity: "",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
    });
  };

  renderActionButton = (product, cart) => {
    let addedToCart = cart.find((product2) => product2.id == product.id);
    console.log(addedToCart);
    if (product.agentStatus && product.agentStatus !== "Reject") {
      return (
        <div
          style={{
            color: "white",
            padding: "5px 7px",
            borderRadius: 5,
            backgroundColor:
              product.agentStatus == "Pending" ? "darkorange" : "green",
            textAlign: "center",

            fontWeight: "bold",
          }}
        >
          <i
            className="icofont-business-man"
            style={{
              fontSize: "130%",
              marginRight: "5px",
              color: "white",
            }}
          ></i>
          {product.agentStatus}
        </div>
      );
    } else {
      if (addedToCart) {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "darkgreen",
              textAlign: "center",

              fontWeight: "bold",
            }}
          >
            <i
              className="icofont-tick-boxed"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Added
          </div>
        );
      } else {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "green",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => {
              this.props.addToCart(product);
              return toast.success("Added to Cart");
            }}
          >
            <i
              className="icofont-cart-alt"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Add
          </div>
        );
      }
    }
  };
  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCartSubmit = async () => {
    const { cart, currentUser } = this.props;
    if (!this.state.arrivalDate) {
      alert("Please Select Your arrival date.");
      return;
    }
    let [year, month, day] = this.state.arrivalDate.split("-");
    for (let i = 0; i < cart.length; i++) {
      await this.props.updateP2PRedux({
        ...cart[i],
        agentId: currentUser.id,
        agentStatus: "Pending",
        arrivalDate: `${day}/${month}/${year}`,
        assignedTime: new Date().getTime(),
      });
      this.props.removeFromCart(cart[i]);
    }
    let arrivalDate = `${day}/${month}/${year}`;
    await makeP2pAgentRequest(cart, currentUser, arrivalDate);
  };

  render() {
    const { productObj } = this.state;
    const { cart } = this.props;

    let total = 0;
    cart.map((product) => {
      total += parseInt(product.agentCost);
    });
    let renderableP2p = this.props.p2p;
    if (this.state.searchFor) {
      renderableP2p = renderableP2p.filter((booking) => {
        return (
          booking.bookingId
            .toLowerCase()
            .includes(this.state.searchFor.toLowerCase()) ||
          booking.name
            .toLowerCase()
            .includes(this.state.searchFor.toLowerCase())
        );
      });
    }
    return (
      <Fragment>
        <Breadcrumb title={this.props.match.params.status} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {this.props.match.params.status}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Product"
                            style={{ paddingLeft: 10 }}
                            onChange={this.handleSearchBarChange}
                          />
                          <span>
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                    <div
                      style={{
                        color: "white",
                        padding: "7px 7px",
                        borderRadius: 5,
                        backgroundColor: "rgb(0, 37, 76)",

                        cursor: "pointer",
                        fontWeight: "bold",
                        position: "relative",
                        maxWidth: 170,
                      }}
                      data-toggle="modal"
                      data-target="#instructionModal"
                    >
                      <ShoppingCart />
                      <span
                        className="badge badge-pill badge-primary pull-left notification-badge"
                        style={{
                          position: "absolute",
                          left: "20px",
                          top: "2px",
                        }}
                      >
                        {cart.length}
                      </span>{" "}
                      Your Cart
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Weight
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Earn
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderableP2p.map((product, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {index + 1}
                            </th>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.date}
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <a href={product.imageUrl} target="_blank">
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={
                                    product.imageUrl ? product.imageUrl : man
                                  }
                                />
                              </a>
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.name}
                            </td>

                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.quantity}pc
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.totalWeight}Kg
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.amount}Tk
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.agentCost || 0}Tk
                            </td>

                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  backgroundColor:
                                    product.status == "pending"
                                      ? "darkorange"
                                      : product.status == "approved"
                                      ? "green"
                                      : product.status == "reject"
                                      ? "red"
                                      : "cadetblue",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td>{this.renderActionButton(product, cart)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Product Image</td>
                      <td style={{ fontWeight: "bold" }}>Product Name</td>
                      <td style={{ fontWeight: "bold" }}>Total Quantity</td>
                      <td style={{ fontWeight: "bold" }}>Total Weight</td>
                      <td style={{ fontWeight: "bold" }}>Total Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.totalWeight}Kg</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.agentCost) || "0"}Tk
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          paddingTop: 0,
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.status == "pending"
                              ? "darkorange"
                              : productObj.status == "approved"
                              ? "green"
                              : productObj.status == "reject"
                              ? "red"
                              : productObj.status == "delivered"
                              ? "darkgreen"
                              : "cadetblue"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.status}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Country:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.selectCountry}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Payment Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",

                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.paymentStatus == "Not Generated"
                              ? "gray"
                              : productObj.paymentStatus == "Not Paid"
                              ? "orange"
                              : productObj.paymentStatus == "Pending"
                              ? "darkorange"
                              : productObj.paymentStatus == "Paid"
                              ? "green"
                              : "red"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.paymentStatus}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Product Details:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.description}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Booking Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      From Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.fromWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouseReceiveDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Assigned Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.assignedDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      To Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.toWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouseReceiveDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsName}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Payment Status:{" "}
                      {productObj && productObj.agentsName ? (
                        <span
                          style={{
                            padding: 10,
                            fontWeight: "bold",

                            color: "white",
                            padding: "3px 7px",
                            borderRadius: 5,
                            backgroundColor: productObj
                              ? productObj.agentPaid == "Not Generated"
                                ? "gray"
                                : productObj.agentPaid == "Not Paid"
                                ? "orange"
                                : productObj.agentPaid == "Pending"
                                ? "darkorange"
                                : productObj.agentPaid == "Paid"
                                ? "green"
                                : "red"
                              : "white",
                            textAlign: "center",
                          }}
                        >
                          {productObj && productObj.agentPaid
                            ? productObj.agentPaid
                            : "Not Paid"}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsAddress}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsMobileNo}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsPostcode}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's NID/Passport No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsNid}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="instructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", maxWidth: "100%" }}
            >
              <div
                className="modal-header"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Your Picked Products
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        marginBottom: 5,
                      }}
                    >
                      Arrival Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="arrivalDate"
                      value={this.state.arrivalDate}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Select Arrival Date"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          colSpan={3}
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Earn
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((product, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{product.date}</td>
                          <td>
                            <a href={product.imageUrl} target="_blank">
                              <img
                                style={{ height: 70, width: 70 }}
                                src={product.imageUrl ? product.imageUrl : man}
                              />
                            </a>
                          </td>
                          <td>{product.name}</td>
                          <td colSpan={3}>
                            <pre>{product.description}</pre>
                          </td>
                          <td>{product.quantity}</td>
                          <td>{product.amount}Tk</td>
                          <td>{product.agentCost || 0}Tk</td>
                          <td>
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <i
                                className="icofont-trash"
                                data-toggle="modal"
                                data-target="#deleteExpenseModal"
                                onClick={() => {
                                  this.props.removeFromCart(product);
                                }}
                                style={{
                                  color: "red",
                                  marginLeft: 8,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan={9}
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                            textAlign: "end",
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{ fontWeight: "bold", fontSize: 24 }}
                          colSpan={2}
                        >
                          {total}Tk
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                {cart.length > 0 && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => {
                        this.handleCartSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2p,
    currentUser: state.currentUser.currentUser,
    cart: state.currentUser.cart,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllWarehouseProductsRedux,
    uploadP2PRedux,
    updateP2PRedux,
    deleteP2PRedux,
    addToCart,
    removeFromCart,
  })(Warehouse)
);
