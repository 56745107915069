import React from "react";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 pb-0">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12">
                <h3 className="h3-sm fw-6 txt-blue mb-4">
                  ১। মালামাল পাঠানোর পূর্বে অবশ্যই জেনে নিন :
                </h3>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                      <li>
                        <i className="icofont-check"></i>গ্রাহকের পাঠানো পণ্য
                        গুলো অবশ্যই পাঠানো দেশের আমদানি ও রফতানি আইন কর্তৃক বৈধ
                        হতে হবে।
                      </li>
                      <li>
                        <i className="icofont-check"></i>কোনো প্রকার অবৈধ পণ্য
                        দেয়া যাবে না।
                      </li>
                      <li>
                        <i className="icofont-check"></i>অবৈধ পণ্যের ক্ষেত্রে
                        সমস্ত জরিমানা বা যেকোনো পরিণতির জন্য গ্রাহক সম্পূর্ণভাবে
                        দায়ী থাকবে।
                      </li>
                      <li>
                        <i className="icofont-check"></i> ইসরাইল ছাড়া বিশ্বের
                        যে কোনও দেশের জন্য লজিস্টিক পরিষেবা প্রদান করা হয়।
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h3 className="h3-sm fw-6 txt-blue mb-4">২। ডেলিভারী :</h3>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                      <li>
                        <span className="dropcap2 rounded-circle bg-navy-blue">
                          ক.
                        </span>
                        Door-to-Door শিপমেন্টের জন্য, By Air এ প্রত্যাশিত
                        ডেলিভারি সময় পাঁচ থেকে সাত দিন এবং By Sea প্রত্যাশিত
                        ডেলিভারি সময় ৪০ থেকে ৪৫ দিন।
                      </li>
                      <li>
                        <span className="dropcap2 rounded-circle bg-navy-blue">
                          খ.
                        </span>
                        Freight এর জন্য, আমাদের বিমানে দুই থেকে তিন দিন প্রয়োজন
                        এবং জাহাজ এর জন্য ১৮ দিন সাধারণ ডেলিভারি সময় হিসেবে
                        প্রয়োজন। কাস্টম জনিত সমস্যা বা ফ্লাইট এর সময়সূচি
                        পরিবর্তন জনিত সমস্যার কারণে যদি আমাদের স্বাভাবিকের চেয়ে
                        বেশি সময় প্রয়োজন হয়, সমস্যাটি সমাধান না হওয়া পর্যন্ত
                        আমাদেরকে অতিরিক্ত সময় নিয়ে সহযোগিতা করতে হবে।
                      </li>
                      <li>
                        <span className="dropcap2 rounded-circle bg-navy-blue">
                          গ.
                        </span>
                        Express এর জন্য ALG Limited সর্বাধিক 8 থেকে 10 দিনের
                        মধ্যে পণ্য সরবরাহ করবে। গন্তব্য দেশের সমস্ত
                        কাস্টমস-সংক্রান্ত সমস্যাগুলি গ্রাহককে সমাধান করতে হবে।
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                ৩। বাংলাদেশে বিতরণ প্রক্রিয়া :
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        ক.
                      </span>
                      গ্রাহক যদি বাংলাদেশের কোনও গুদাম থেকে পণ্যটি গ্রহণ করেন
                      তবে পণ্যটি অবশ্যই স্পষ্টভাবে দেখার এবং গণনার পরে ভালো করে
                      বুজে নিতে হবে। কোনও অভিযোগ থাকলে তা অবিলম্বে জানাতে হবে।
                      গুদাম থেকে পণ্যটি নেওয়ার পরে কোনও সমালোচনা বা অভিযোগ
                      গ্রহণযোগ্য হবে না ।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        খ.
                      </span>
                      কুরিয়ারের মাধ্যমে বিতরণ করা পণ্যগুলি গ্রহণ করার সময়,
                      গ্রাহককে পণ্যগুলো দেখে গণনা করতে হবে এবং ভাল করে বুজে নিতে
                      হবে। যদি কোনও অভিযোগ থাকে তবে ডেলিভারি ম্যান থাকাকালীন
                      ক্লায়েন্টকে আমাদের অফিসে অভিযোগ করতে হবে। অভিযোগে পণ্যের
                      একটি ছবি এবং ভিডিও অন্তর্ভুক্ত করতে হবে।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        গ.
                      </span>
                      যদি কোনও অভিযোগ থাকে তবে ডেলিভারি ম্যান থাকাকালীন আপনাকে
                      আমাদের অফিসে যত তাড়াতাড়ি সম্ভব আমাদের সাথে যোগাযোগ করতে
                      হবে। আপনাকে একটি ছবি এবং ভিডিও দিয়ে ভুল পণ্য সম্পর্কে
                      বিশদ তথ্য সরবরাহ করতে হবে।
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">৩। মূল্য পরিশোধ :</h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        ক.
                      </span>
                      গ্রাহক লজিস্টিক সেবা প্রদানকারী কোম্পানির ধার্য কৃত পণ্য
                      মূল্য এবং আনুসাঙ্গিক চার্জ যদি থাকে পরিশোধ করতে সম্মত
                      থাকবে। বিভিন্ন কারণে পণ্যের কেজি প্রতি খরচ ওঠা নামা করে।
                      গ্রাহককে পণ্য প্রদানের আগেই মূল্য জিজ্ঞাসা করে নিশ্চিত
                      হয়ে নিতে হবে।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        খ.
                      </span>
                      ALG Limited কর্তৃক নির্ধারিত মুল্যটি চূড়ান্ত প্রদানযোগ্য
                      মূল্য হবে। কেবলমাত্র উভয় পক্ষের পারস্পরিক লিখিত চুক্তির
                      মাধ্যমে প্রদানযোগ্য মূল্য সংশোধন বা পরিবর্তন করা যেতে
                      পারে। লজিস্টিক পরিষেবা সরবরাহকারী সংস্থা কোম্পানির নাম সীল
                      এবং সাইন সহ চালান এবং প্রদানের রশিদ জারি করবে, যা চূড়ান্ত
                      হবে এবং কোনও অনুসন্ধানের প্রমানে ব্যবহৃত হবে।
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                ৪। ক্ষতিপূরণ ও বীমা নীতি:
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        ক.
                      </span>
                      Door to Door: যান্ত্রিক ত্রুটি বা প্রাকৃতিক বিপর্যয়ের
                      কারণে বিমান বা জাহাজ ক্ষতিগ্রস্ত হলে গ্রাহকের জন্য
                      কোম্পানির বীমা নীতি কার্যকর হবে। বীমা পরিষেবা গ্রহণ করতে
                      ইচ্ছুক গ্রাহকরা পণ্য লোড করার আগে বা বিমান যাত্রার আগেই
                      তাদের পণ্যের মোট মূল্যের 3% অর্থ পরিশোধ করতে হবে। বীমা
                      গ্রহণ করেছেন এমন সমস্ত গ্রাহকরা তাদের ক্ষতিগ্রস্থ পণ্যের
                      পুরো অর্থ ফেরত পাবেন।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        খ.
                      </span>
                      Freight: গ্রাহক তাদের উদ্যোগে তাদের পণ্যটির সুরক্ষা
                      নিশ্চিত করবে।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        গ.
                      </span>
                      Express: ALG Limited কর্তৃপক্ষ সমস্ত পণ্যের বীমা বহন করবে।
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="h3-sm fw-6 txt-blue mb-4">
                ৫। রাপ্তি স্বীকার পত্র :
              </h3>
              <div className="row pb-4">
                <div className="col-sm-12 col-md-6">
                  <ul className="list-unstyled icons-listing theme-orange mb-0">
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        ক.
                      </span>
                      এই শর্ত 15 দিনের লিখিত নোটিশের পরে, কারণ সহ বা ছাড়াই উভয়
                      পক্ষের সম্মতির দ্বারা সমাপ্ত হতে পারে। উপরোক্ত সত্ত্বেও,
                      কোনও পক্ষই এই লিখিত নোটিশের পরে 15 দিনের মধ্যে এই
                      শর্তগুলোর উপাদান লঙ্ঘন নিরাময়ে ব্যর্থ হলে তাৎক্ষণিক লিখিত
                      নোটিশের ভিত্তিতে এই শর্ত বাতিল করতে পারে।
                    </li>
                    <li>
                      <span className="dropcap2 rounded-circle bg-navy-blue">
                        খ.
                      </span>
                      যদি লজিস্টিক পরিষেবা সরবরাহকারী কোম্পানির বিরুদ্ধে
                      দেউলিয়া হয়ে যাওয়ার আবেদন করা হয়, বা লজিস্টিকস পরিষেবা
                      সরবরাহকারী অবিচ্ছিন্ন হয়ে পড়ে, বা পাওনাদারদের সুবিধার্থে
                      বা দেউলিয়ার প্রস্তাবের জন্য একটি সাধারণ দায়িত্ব অর্পণ
                      করে, তবে ALG Limited কোনো প্রকার নোটিশ এবং আরও বাধ্যবাধকতা
                      ছাড়াই এই শর্তগুলো অবিলম্বে বাতিল করতে পারে ।
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
