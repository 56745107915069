import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import HomeOne from "./home/homeOne";
import HomeThree from "./home/homeThree";
import NotFound from "./pages/notfound";
import Pricing from "./pages/pricing";
import Services from "./services/services";
import ServiceDetails from "./services/servicedetails/Content";
import NoticeList from "./notices/noticelist";
import BlogList from "./blogs/bloglist";

import ProjectGrid from "./projects/projectgrid";
import ProjectMasonary from "./projects/projectmasonary";
import ProjectSingle from "./projects/projectsingle";
import Typography from "./pages/typography";
import ShortCodes from "./pages/shortcodes";
import RequestQuote from "./pages/requestquote";
import About from "./about";
import TermsAndCondition from "./pages/terms-and-condition/terms-and-condition";
import PrivacyPolicy from "./pages/privacy policy/privacy-policy";
import RefundPolicy from "./pages/refund/refund-policy";
import Contact from "./contact/contactus";
import ContactusOption from "./contact/contactusoption";

import NoticeSingle from "./notices/noticesingle";
import BlogSingle from "./blogs/blogsingle";
import UserPanel from "./userPanel";
import { connect } from "react-redux";

// user panel
import Dashboard from "./userPanel/dashboard";
import MyProfile from "./userPanel/myProfile/myProfile";
import MyBooking from "./userPanel/myBooking/myBooking";
import MyParcel from "./userPanel/myParcel/myParcel";
import MyParcelLots from "./userPanel/myParcel/myParcelLots";
import ExpressParcel from "./userPanel/myParcel/expressParcel";
import MyInvoice from "./userPanel/myInvoice/myInvoice";
import MyInvoiceLots from "./userPanel/myInvoice/myInvoiceLots";
import ExpressInvoice from "./userPanel/myInvoice/expressInvoice";
import MyPayment from "./userPanel/myPayment/myPayment";
import MyRefund from "./userPanel/myRefund/myRefund";
import MyInvoiceByOrder from "./userPanel/myInvoice/my-invoice-by-order";
import RechargeWallet from "./userPanel/recharge/rechargeWallet";
import AllDeliveredLots from "./userPanel/allDelivered/allDeliveredlots";
import AllDeliveredOrders from "./userPanel/allDelivered/allDeliveredOrders";
import AllDeliveredExpress from "./userPanel/allDelivered/allDeliveredExpress";
import MyInvoiceByOrderExpress from "./userPanel/myInvoice/my-invoice-by-order-express";
import AmaderSheba from "./pages/amaderSheba";
import AllNotices from "./pages/notices";
import SourcingInvoice from "./userPanel/sourcingInvoice/myInvoice";
import Forwarders from "./pages/freight-forwarder/index";

// p2p
import MyRequest from "./userPanel/p2pCustomer/myRequest";
import Warehouse from "./userPanel/p2pAgent/warehouse";
import Register from "./userPanel/p2pAgent/register";
import MyShipmentRequest from "./userPanel/p2pAgent/myShipmentRequest";
import MyRequestAgent from "./userPanel/p2pAgent/myRequest";

const MainNavigation = (props) => {
  const { currentUser } = props;

  useEffect(() => {}, [currentUser]);

  return (
    <Router basename={"/"}>
      <Switch>
        <Route path="/" exact component={HomeThree} />
        <Route path="/home-one" exact component={HomeOne} />

        <Route path="/pricing" exact component={Pricing} />
        <Route path="/requestquote" exact component={RequestQuote} />
        <Route path="/shortcodes" exact component={ShortCodes} />
        <Route path="/typography" exact component={Typography} />
        {/* * About Services Contact */}
        <Route path="/about" exact component={About} />
        {/** Terms and codition */}
        <Route
          path="/terms-and-condition"
          exact
          component={TermsAndCondition}
        />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />

        <Route path="/refund-policy" exact component={RefundPolicy} />
        <Route path="/services" exact component={Services} />
        <Route path="/servicedetails" exact component={ServiceDetails} />
        {/* <Route path="/contact" exact component={Contact} /> */}
        <Route path="/contact" exact component={ContactusOption} />
        {/** Notices */}
        <Route path="/recent-news" exact component={NoticeList} />
        <Route path="/info-recent-news" exact component={AllNotices} />
        <Route path="/freight-forwarders" exact component={Forwarders} />

        <Route path="/recent-news/:noticeId" exact component={NoticeSingle} />
        {/** Blogs */}
        <Route path="/blogs" exact component={BlogList} />
        <Route path="/info-blogs-list" exact component={AmaderSheba} />
        <Route path="/blogs/:blogId" exact component={BlogSingle} />
        {/** Projects */}
        <Route path="/projectgrid" exact component={ProjectGrid} />
        <Route path="/projectmasonary" exact component={ProjectMasonary} />
        <Route path="/projectsingle" exact component={ProjectSingle} />
        <UserPanel>
          <Route
            path="/user/edit-profile"
            exact
            component={() => <MyProfile />}
          />
          <Route path="/dashboard" exact component={() => <Dashboard />} />
          <Route
            path="/user-my-booking"
            exact
            component={() => <MyBooking />}
          />
          <Route
            path="/user-my-parcel"
            exact
            component={() => <MyParcelLots />}
          />
          <Route
            path="/user-my-parcel/:lotNo"
            exact
            component={() => <MyParcel />}
          />
          <Route
            path="/user-all-delivered-express"
            exact
            component={() => <AllDeliveredExpress />}
          />
          <Route
            path="/user-my-parcel-express"
            exact
            component={() => <ExpressParcel />}
          />
          <Route
            path="/user-recharge-wallet"
            exact
            component={() => <RechargeWallet />}
          />
          <Route path="/user-my-invoice" exact component={MyInvoiceLots} />
          <Route path="/user-invoice/:lotNo" exact component={MyInvoice} />
          <Route
            path="/user-invoice-sourcing"
            exact
            component={SourcingInvoice}
          />
          <Route
            path="/user-my-invoice-express"
            exact
            component={() => <ExpressInvoice />}
          />
          <Route
            path="/invoice-by-bookingId/:bookingId"
            exact
            component={() => <MyInvoiceByOrderExpress />}
          />
          <Route
            path="/user-my-payment"
            exact
            component={() => <MyPayment />}
          />
          <Route
            path="/user-all-delivered"
            exact
            component={() => <AllDeliveredLots />}
          />
          <Route
            path="/user-all-delivered/:lotNo"
            exact
            component={() => <AllDeliveredOrders />}
          />
          <Route
            path="/user-my-invoice/:parcelId"
            exact
            component={() => <MyInvoiceByOrder />}
          />
          <Route path="/user-my-refund" exact component={() => <MyRefund />} />
          {/* p2p  */}
          <Route path="/p2p/:status" exact component={() => <MyRequest />} />
          <Route
            path="/p2p-agent-register/new"
            exact
            component={() => <Register />}
          />
          <Route
            path="/p2p/agent2/my-shipment-request"
            exact
            component={() => <MyShipmentRequest />}
          />
          <Route
            path="/p2p/agent/:status"
            exact
            component={() => <MyRequestAgent />}
          />
          <Route
            path="/p2p/warehouse/:status"
            exact
            component={() => <Warehouse />}
          />
        </UserPanel>
        {/** invalid routes redirection */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.currentUser,
  };
};
export default connect(mapStateToProps)(MainNavigation);
