const INITIAL_STATE = {
  payments: [],
  checkedInvoices: [],
  totalAmount: 0,
  express: false,
  invoicesToPay: [],
};

const setPaymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ALL_PAYMENTS":
      return { ...state, payments: [...action.payload] };
    case "CHECKED_INVOICE_FOR_PAYMENT":
      return { ...state, checkedInvoices: [...action.payload] };
    case "TOTAL_AMOUNT":
      return {
        ...state,
        totalAmount: action.payload.total,
        express: action.payload.express,
        invoicesToPay: action.payload.invoicesToPay,
      };
    default:
      return { ...state };
  }
};
export default setPaymentsReducer;
