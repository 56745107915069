import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "./firebase/firebase.utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Header = (props) => {
  const { currentUser } = props;
  console.log(window.innerWidth);
  return (
    <>
      <header className="fixed-top header-fullpage top-border top-transparent wow fadeInDown">
        {window.innerWidth > 450 && (
          <div className="top-bar-right d-flex align-items-center text-md-left topBar-white-disabled">
            <div className="container">
              <div className="row align-items-center">
                <div className="col" style={{ color: "white", padding: "5px" }}>
                  <i className="icofont-google-map"></i>
                  37/2 Pritom Zaman Tower, Ground Floor, Godown no-8, Culvert
                  Road, Dhaka-1000. Bangladesh
                </div>
                <div className="col-md-auto">
                  <span className="mr-3" style={{ color: "white" }}>
                    <i className="icofont-ui-touch-phone"></i>+8801885994310
                  </span>
                  <span className="mr-3" style={{ color: "white" }}>
                    <i className="icofont-ui-email"></i> info@alglimited.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <nav className="navbar navbar-expand-lg bg-transparent">
          <div className="container text-nowrap">
            <div className="d-flex align-items-center w-100 col p-0">
              <Link
                className="navbar-brand rounded-bottom light-bg"
                to={`${process.env.PUBLIC_URL}/`}
              >
                <img
                  style={{ maxWidth: "80%", filter: "contrast(180%)" }}
                  src="images/alg-header-logo2.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="d-inline-flex request-btn order-lg-last col p-0">
              <Link className="nav-link" to="#" id="search_home">
                {/* <i className="icofont-search"></i> */}
              </Link>
              {/* <Link
                className="btn-theme icon-left bg-navy-blue no-shadow align-self-center"
                to="#"
                role="button"
                data-toggle="modal"
                data-target="#request_popup"
              >
                <i className="icofont-list"></i>
                <span className="d-none d-sm-inline-block"> Request Quote</span>
              </Link> */}
              <button
                className="navbar-toggler x collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ marginLeft: "60px" }}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarCollapse"
              data-hover="dropdown"
              data-animations="slideInUp slideInUp slideInUp slideInUp"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={`${process.env.PUBLIC_URL}/`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/about`}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/recent-news`}
                  >
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/blogs`}
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/services`}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`${process.env.PUBLIC_URL}/contact`}
                  >
                    Contact us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="btn-theme icon-left no-shadow d-none d-lg-inline-block align-self-center nav-link"
                    to="#"
                    role="button"
                    data-toggle="modal"
                    data-target="#request_order_tracking_popup"
                    style={{
                      background: "rgb(245 74 79)",
                      fontSize: "85%",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <i className="icofont-location-arrow"></i> Order tracking
                  </Link>
                </li>
                {!props.currentUser.displayName ? (
                  <li className="nav-item">
                    <Link
                      className="btn-theme icon-left no-shadow d-none d-lg-inline-block align-self-center nav-link"
                      to="/"
                      role="button"
                      data-toggle="modal"
                      data-target="#request_login_popup"
                      style={{
                        background: "#0e6f0e",
                        fontSize: "85%",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <i className="icofont-man-in-glasses"></i>
                      Login
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle-mob"
                      to={`${process.env.PUBLIC_URL}/`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ paddingTop: "6px", paddingBottom: "7px" }}
                    >
                      <i className="icofont-man-in-glasses"></i>
                      {props.currentUser.displayName.slice(0, 10)}
                    </Link>
                    <ul className="dropdown-menu">
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/dashboard`}
                        >
                          <i className="icofont-dashboard-web"></i>&nbsp; My
                          Dashboard
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-booking`}
                        >
                          <i className="icofont-ebook"></i>&nbsp; My Bookings
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-parcel`}
                        >
                          <i className="icofont-box"></i>&nbsp; My Parcels
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-invoice`}
                        >
                          <i className="icofont-pay"></i>&nbsp; My Invoices
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-payment`}
                        >
                          <i className="icofont-list"></i>&nbsp; My Payments
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-all-delivered`}
                        >
                          <i className="icofont-tick-boxed"></i>&nbsp; All
                          Delivered
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <Link
                          className="dropdown-item"
                          to={`${process.env.PUBLIC_URL}/user-my-refund`}
                        >
                          <i className="icofont-paperclip"></i>&nbsp; My Refund
                        </Link>
                      </li>
                      <li style={{ display: "block" }}>
                        <a
                          href={`${process.env.PUBLIC_URL}/`}
                          className="dropdown-item"
                          onClick={async () => {
                            toast.error("You are logged out!");
                            await auth.signOut();
                          }}
                        >
                          <i className="icofont-logout"></i>&nbsp; Log Out
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.currentUser,
  };
};

export default connect(mapStateToProps, null)(Header);
