import React from "react";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-100 pb-0">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">
                  Return and Refund Policy
                </h2>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    Last updated: Ferbuary 10, 2022
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    Thank you for using ALG Limited.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If, for any reason, You are not completely satisfied with a
                    payment, We invite You to review our policy on refunds and
                    returns.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The following terms are only applicable for any payment that
                    You have done in our website.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">
                  CONDITIONS FOR REFUNDS
                </h2>

                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>কাস্টমস</span> যদি
                        কাস্টমস, ফ্রেইট, এবং প্রাকৃতিক কোন সমস্যার কারণে
                        শিপমেন্ট দেরি হয়, তবে এএলজি কর্তৃপক্ষ কোন ধরনের রিফান্ড
                        প্রদান করবে না। এবং উক্ত শিপমেন্ট ডেলিভারি হওয়া পর্যন্ত
                        আমাদেরকে সময় দিয়ে সহযোগিতা করতে হবে।
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>প্যাকিং</span>{" "}
                        প্যাকিংকৃত পণ্য কোনভাবে ক্ষতিগ্রস্ত হলে,ভেঙ্গে গেলে
                        কিংবা পানিতে নষ্ট হলে তার দায় এএলজি লিমিটেড কর্তৃপক্ষ
                        নেবে না এবং এর জন্য কোন প্রকার ক্ষতিপূরণ প্রদান করা হবে
                        না।
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>
                          পণ্য হারানো{" "}
                        </span>{" "}
                        চায়নার রিসিভিং ওজন ও বাংলাদেশের ডেলিভারি ওজন যদি সমান
                        থাকে অথবা বাংলাদেশের অফিস থেকে যদি সম্পূর্ণ ইনট্যাক্ট
                        কার্টুন ডেলিভারি করা হয় তবে পণ্য হারানোর ব্যাপারে কোন
                        ধরণের ওজন আপত্তি গ্রহণযোগ্য নয় ।
                      </li>
                      <li>
                        <i className="icofont-check"></i>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          কার্টুন কাটাছেড়া{" "}
                        </span>{" "}
                        যদি কোন কার্টুন কাটাছেড়া থাকে তবে অবশ্যই অফিস থেকে নেয়াড়
                        সময় আমাদের প্রতিনিধির কাড়ছে জানাতে হবে এবং ওয়েবসাইটের
                        মাধ্যমে রিফান্ড এপ্লাই করতে হবে।{" "}
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>
                          অবৈধ পণ্য
                        </span>{" "}
                        গ্রাহকের পাঠানো পণ্যগুলো অবশ্যই পাঠানো দেশের আমদানি ও
                        রফতানি আইন কর্তৃক বৈধ হতে হবে । কোনো প্রকার অবৈধ পণ্য
                        দেয়া যাবে না। অবৈধ পণ্যের ক্ষেত্রে সমস্ত দায়ভার, জরিমানা
                        বা যেকোনো পরিণতির জন্য গ্রাহক সম্পূর্ণভাবে দায়ী থাকবে ।
                      </li>

                      <br></br>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">
                  Interpretation and Definitions
                </h2>
                <h3 className="h3-sm fw-6 txt-blue mb-4">Interpretation</h3>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">Definitions</h2>
                <h4 style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {" "}
                  For the purposes of this Return and Refund Policy:
                </h4>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    <ul className="list-unstyled icons-listing theme-orange mb-0">
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>Company</span>{" "}
                        (referred to as either "the Company", "We", "Us" or
                        "Our" in this Agreement) refers to ALG Limited, 37/2
                        Pritom-Zaman Tower, 10th Floor, suite 6A, Culvert Road,
                        Dhaka-1000. Bangladesh.
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>Goods</span> refer
                        to the items offered for sale on the Service.
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>Orders</span> mean
                        a request by You to purchase Goods from Us.
                      </li>
                      <li>
                        <i className="icofont-check"></i>{" "}
                        <span style={{ fontWeight: "bold" }}>Service</span>{" "}
                        refers to the Website.
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>Website</span>{" "}
                        refers to ALG Limited, accessible from
                        https://www.alglimited.com
                      </li>
                      <li>
                        <i className="icofont-check"></i>
                        <span style={{ fontWeight: "bold" }}>You</span> means
                        the individual accessing or using the Service, or the
                        company, or other legal entity on behalf of which such
                        individual is accessing or using the Service, as
                        applicable.
                      </li>
                      <br></br>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="h3-sm fw-6 txt-blue mb-4">
                  Your Order Cancellation Rights
                </h3>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    You are entitled to cancel Your Order before 2 days of
                    shipment (from china) without giving any reason for doing
                    so.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    In order to exercise Your right of cancellation, You must
                    inform Us of your decision by means of a clear statement.
                    You can inform us of your decision by:
                    <br></br>
                    <br></br>
                    By phone number: +8801885994310
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    We will reimburse You no later than 14 days. We will use the
                    same means of payment as You used for the Order, and You
                    will not incur any fees for such reimbursement.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">
                  Conditions for Returns
                </h2>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    In order for the payments to be eligible for a return,
                    please make sure that:
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The shipping charge of the Goods were paid in the last 14
                    days
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If the goods are damaged or lost, inform our delivery
                    section before leaving the warehouse. otherwise you will not
                    be eligible for a return.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    We'll check if your claiming is right. And the compnay will
                    take the decision accordingly.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The supply of Goods which according to their nature are not
                    suitable to be returned, deteriorate rapidly or where the
                    date of expiry is over.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The supply of Goods which are not suitable for return due to
                    health protection or hygiene reasons and were unsealed after
                    delivery.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    The supply of Goods which are, after delivery, according to
                    their nature, inseparably mixed with other items.
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    We reserve the right to refuse returns of any merchandise
                    that does not meet the above return conditions in our sole
                    discretion.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="h2-sm fw-6 txt-blue mb-4">Contact Us</h2>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    If you have any questions about our Returns and Refunds
                    Policy, please contact us:
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-sm-12 col-md-6">
                    By phone number: +8801885994310
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
