import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import "./css/invoice-by-order.css";
import Alg from "./alg.png";
import Paid from "./paid.png";
import { withRouter } from "react-router-dom";
import { makePaymentRedux, setParcelObj } from "../../../actions";
import { toast, ToastContainer } from "react-toastify";
import { CircleLoader } from "react-spinners";
import Print from "./print";
export class MyInvoiceByOrder extends Component {
  state = {
    submit_loader: false,
  };

  handleClick = async () => {
    const { orderObj } = this.props;
    const { userObj } = this.props;
    // if (this.state.submit_loader) {
    //   return;
    // }
    // if (userObj && orderObj) {
    //   if (orderObj.finalTotal > userObj.myWallet) {
    //     alert("Your account balance is not enough for this transaction.");
    //     return;
    //   }
    //   this.setState({ submit_loader: true });
    //   await this.props.makePaymentRedux(
    //     orderObj.finalTotal,
    //     [`${orderObj.parcelId}`],
    //     userObj,
    //     [orderObj]
    //   );
    //   toast.success("Your payment is successful");

    //   this.props.history.push(`${process.env.PUBLIC_URL}/user-my-invoice`);
    // }
  };
  componentWillUnmount = () => {
    this.setState({ submit_loader: false });
  };

  render() {
    const { orderObj, userObj } = this.props;
    let total;
    if (orderObj) {
      total = Math.round(
        parseInt(orderObj.grossWeight * orderObj.ratePerKg) +
          parseInt(
            orderObj.productsValue ? (orderObj.productsValue * 3) / 100 : 0
          ) +
          parseInt(orderObj.packagingCost) +
          parseInt(orderObj.localDeliveryCost)
      );
    }
    console.log(userObj);
    return (
      <Fragment>
        <Breadcrumb title="Invoice" parent="Invoice" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Invoice #{orderObj && orderObj.parcelId}</h5>
                </div>
                {/* <div
                  className="card-body"
                  style={{ backgroundColor: "#e4e3e1" }}
                >
                  <div id="basicScenario" className="product-list">
                    <div
                      id="container"
                      style={{ maxWidth: "1000px", borderRadius: ".2rem" }}
                    >
                      <section id="memo" style={{ height: "165px" }}>
                        <div className="logo">
                          <img
                            style={{ width: "70px", height: "70px" }}
                            src={Alg}
                          />
                        </div>

                        <div className="company-info">
                          <div>Alg Limited</div>
                          <br />
                          <span>
                            37/2 Pritom-Zaman Tower, 10th Floor, suite 6A &nbsp;
                          </span>{" "}
                          <br />
                          <span>Culvert Road, Dhaka-1000. Bangladesh</span>
                          <br />
                          <span>Hotline: 8801736404419,</span>
                          <span>info@algcargos.com</span>
                        </div>
                      </section>

                      <section id="invoice-title-number">
                        <span id="title" style={{ backgroundColor: "#8a0368" }}>
                          INVOICE
                        </span>
                        <span id="number" style={{ fontSize: "200%" }}>
                          #{orderObj && orderObj.parcelId}
                        </span>
                      </section>

                      <div className="clearfix"></div>

                      <section id="client-info">
                        <span>TO:</span>
                        <div>
                          <span className="bold">
                            {userObj ? userObj.displayName : null}
                          </span>
                        </div>
                        {userObj && userObj.address ? (
                          <div>
                            <span>{userObj.address}</span>
                          </div>
                        ) : null}
                        {userObj && userObj.city ? (
                          <div>
                            <span>{userObj.city}</span>
                          </div>
                        ) : null}

                        <div>
                          <span>{userObj && userObj.mobileNo}</span>
                        </div>

                        <div>
                          <span>{userObj && userObj.email}</span>
                        </div>
                      </section>

                      <div className="clearfix"></div>

                      <section id="items">
                        <table cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <th>#</th>
                              <th style={{ maxWidth: "50px" }}>Product</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>CTN no</th>
                              <th>Tracking no</th>
                              <th>Weight</th>
                              <th>In Total</th>
                            </tr>

                            <tr data-iterate="item">
                              <td>1</td>
                              <td style={{ maxWidth: "50px" }}>
                                {orderObj && orderObj.productName}
                              </td>
                              <td>{orderObj && orderObj.quantity}</td>
                              <td>{orderObj && orderObj.ratePerKg}Tk</td>
                              <td>{orderObj && orderObj.cartonNo}</td>
                              <td>{orderObj && orderObj.trackingNo}</td>

                              <td>{orderObj && orderObj.grossWeight}</td>
                              <td>
                                {orderObj && orderObj.total}
                                Tk
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>

                      <section id="sums">
                        <table cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>
                                {orderObj && orderObj.total}
                                Tk
                              </td>
                            </tr>

                            <tr data-iterate="tax">
                              <th>Packaging Charge</th>
                              <td>
                                {orderObj && orderObj.packagingCost
                                  ? orderObj.packagingCost
                                  : 0}
                                Tk
                              </td>
                            </tr>
                            <tr data-iterate="tax">
                              <th>Insurance</th>
                              <td>{orderObj && orderObj.insurance}Tk</td>
                            </tr>
                            <tr data-iterate="tax">
                              <th>Local Delivery</th>
                              <td>
                                {orderObj && orderObj.deliveryCost
                                  ? orderObj.deliveryCost
                                  : 0}
                                Tk
                              </td>
                            </tr>
                            {orderObj && orderObj.otherCharges ? (
                              <tr data-hide-on-quote="true">
                                <th>Other Charges</th>
                                <td>{orderObj.otherCharges}Tk</td>
                              </tr>
                            ) : null}
                            {orderObj && orderObj.qcCheck === "true" ? (
                              <tr data-hide-on-quote="true">
                                <th>QC Check</th>
                                <td>100Tk</td>
                              </tr>
                            ) : null}
                            <tr className="amount-total">
                              <th>TOTAL</th>
                              <td>{orderObj && orderObj.subTotal}Tk</td>
                            </tr>

                            <tr data-hide-on-quote="true">
                              <th>Discount</th>
                              <td>
                                {orderObj && orderObj.discountInvoice
                                  ? orderObj.discountInvoice
                                  : 0}
                                Tk
                              </td>
                            </tr>

                            <tr data-hide-on-quote="true">
                              <th>TOTAL </th>
                              <td>{orderObj && orderObj.finalTotal}Tk</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="clearfix"></div>
                      </section>

                      <div className="clearfix"></div>

                      <section id="invoice-info">
                        <div>
                          <span style={{ color: "#464242" }}>Created By</span>
                        </div>
                        <br />
                        <div>
                          <span style={{ color: "#464242" }}>Delivered By</span>
                        </div>
                        <div>
                          <span>Sagor</span>
                        </div>
                      </section>

                      <section id="terms">
                        <div className="notes">
                          <span
                            style={{ fontWeight: "bold", color: "darkorange" }}
                          >
                            {userObj && userObj.displayName}
                          </span>
                          , thank you very much.We really appreciate your
                          buisness. <br />
                          stay connected.
                        </div>
                        {orderObj && orderObj.chineseNote ? (
                          <div style={{ marginTop: "2rem", color: "gray" }}>
                            {orderObj && orderObj.chineseNote}
                          </div>
                        ) : null}

                        <br />

                        <div className="payment-info">
                          <div>Packaging</div>
                          <div>
                            {orderObj && orderObj.packagingChosed
                              ? orderObj.packagingChosed
                              : "None"}
                          </div>
                          <br />
                          <div>Delivery Address</div>
                          <div>
                            {orderObj && orderObj.deliveryAddress
                              ? orderObj.deliveryAddress
                              : "Alg Office"}
                          </div>
                        </div>
                      </section>

                      <div className="clearfix"></div>

                      <div
                        className="thank-you"
                        style={{ backgroundColor: "#8a0368" }}
                      >
                        THANKS!
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div> */}
                <div className="card-body">
                  <Print orderObj={true} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "#e4e3e1",
                    padding: "0px 20px 20px 0px",
                  }}
                >
                  {orderObj &&
                  orderObj.paymentRequest &&
                  orderObj.paymentRequest !== "recharged" ? (
                    <button
                      className="btn"
                      style={{
                        minWidth: "130px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        backgroundColor: "orange",
                        color: "white",
                      }}
                    >
                      {orderObj.paymentRequest}
                    </button>
                  ) : orderObj && orderObj.invoiceStatus === "Paid" ? null : (
                    <button
                      className="btn btn-secondary"
                      onClick={this.handleClick}
                      style={{
                        minWidth: "130px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {!this.state.submit_loader && <>Pay now</>}

                      <CircleLoader
                        loading={this.state.submit_loader}
                        color="white"
                        size={15}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {orderObj && orderObj.invoiceStatus === "Paid" ? (
          <div
            style={{
              position: "absolute",
              left: "25%",
              top: "20%",
            }}
          >
            <img
              style={{
                height: "220px",
                width: "220px",
              }}
              src={Paid}
            ></img>
          </div>
        ) : null} */}
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userObj: state.currentUser.currentUser,
    orderObj: state.orders.ordersArrayOfSingleUser.find(
      (order) => order.parcelId === ownProps.match.params.parcelId
    ),
  };
};
export default withRouter(
  connect(mapStateToProps, { makePaymentRedux, setParcelObj })(MyInvoiceByOrder)
);
