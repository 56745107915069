import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import Tabset_user from "./tabset-user";
import { connect } from "react-redux";
import man from "../assets/images/dashboard/user2.jpg";
import UpdateProfileModal from "./updateProfileModal";
import { uploadImageRedux } from "../../../actions/index";

export class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [man],
      file: "",
      toggleModal: true,
      user: null,
    };
  }

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { pictures } = this.state;

    reader.onloadend = () => {
      pictures[i] = reader.result;
      this.setState({
        file: file,
        pictures,
        user: null,
      });
    };
    if (file) {
      const { user } = this.props;
      reader.readAsDataURL(file);
      const imgUrl = await this.props.uploadImageRedux(user, file);
      console.log(imgUrl);
      pictures[i] = imgUrl;
      this.setState({
        pictures,
      });
      console.log(pictures);
    }
  };
  startToggleModal = async (userObj) => {
    if (userObj == null) {
      this.setState({ toggleModal: !this.state.toggleModal, user: null });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        user: userObj,
      });
    }
  };

  render() {
    const { user } = this.props;
    console.log(user);
    return (
      <Fragment>
        {/* <Breadcrumb title={user && user.displayName} parent="Users" /> */}
        <UpdateProfileModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          user={this.state.user}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div
                    className="box-input-file"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {user && user.imageUrl ? (
                      <img
                        className="img-60 rounded-circle lazyloaded blur-up"
                        src={user.imageUrl}
                        alt="#"
                        style={{
                          zIndex: 10,
                          cursor: "pointer",
                          maxWidth: "60px",
                          maxHeight: "60px",
                        }}
                        onClick={() => {
                          document.getElementById("upload-image-input").click();
                        }}
                      />
                    ) : (
                      <>
                        <img
                          className="img-60 rounded-circle lazyloaded blur-up"
                          src={this.state.pictures[0]}
                          alt="#"
                          style={{ zIndex: 10, cursor: "pointer" }}
                          onClick={() => {
                            document
                              .getElementById("upload-image-input")
                              .click();
                          }}
                        />
                        <li
                          style={{
                            padding: "1px 15px",
                            backgroundColor: "black",
                            color: "white",
                            position: "absolute",
                            zIndex: 11,
                            marginTop: "20px",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            document
                              .getElementById("upload-image-input")
                              .click();
                          }}
                        >
                          edit
                        </li>
                      </>
                    )}
                    <input
                      id="upload-image-input"
                      className="upload"
                      type="file"
                      style={{
                        position: "absolute",
                        zIndex: 5,
                        maxWidth: "50px",
                        marginTop: "20px",
                      }}
                      onChange={(e) => this._handleImgChange(e, 0)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "200%",
                      marginTop: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {user && user.displayName}
                  </div>
                </div>
                <div className="card-body">
                  <Tabset_user
                    user={user}
                    startToggleModal={this.startToggleModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.currentUser.currentUser,
  };
};

export default connect(mapStateToProps, { uploadImageRedux })(MyProfile);
