import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  getAllParcelsOfSingleUserRedux,
  totalAmountToPayRedux,
} from "../../../actions/index";
import "./makePaymentModal.css";
import "./paymentModal.css";
import { Link } from "react-router-dom";
import Delivery from "./assets/delivery.png";
import {
  updateMultipleOrders,
  updateMultipleOrdersSourcing,
} from "../../firebase/firebase.utils";
class DeliveryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      deliveryAddress: "",
      deliveryAddress2: "",
      vehicle: "",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.parcelArray && nextProps.parcelArray.length > 0) {
      const invoiceArray = nextProps.parcelArray.filter(
        (parcel) => parcel.invoiceGenerated == true
      );
      this.setState({
        invoiceArray,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleDelivery = async (invoices) => {
    const { currentUser } = this.props;
    const { deliveryAddress, deliveryAddress2, vehicle } = this.state;
    const invoice = invoices[0];
    if (invoice.parcelId) {
      await updateMultipleOrders(
        invoices,
        deliveryAddress,
        deliveryAddress2,
        vehicle
      );
    } else {
      await updateMultipleOrdersSourcing(
        invoices,
        deliveryAddress,
        deliveryAddress2,
        vehicle
      );
    }

    toast.success(
      "Thanks for taking our delivery service.Our Delivery memeber will call you soon"
    );
    document.getElementById("modal-close-icon-delivery-modal").click();
  };

  render() {
    const { email, password, invoiceArray } = this.state;
    const { checkedInvoices } = this.props;
    const invoicesToPay = invoiceArray.filter((invoice) =>
      checkedInvoices.includes(invoice.parcelId)
    );
    let total = 0;
    let totalWeight = 0;
    invoicesToPay.forEach((invoice) => (total += invoice.finalTotal));
    invoicesToPay.forEach(
      (invoice) => (totalWeight += parseFloat(invoice.grossWeight))
    );
    console.log(total);
    return (
      <>
        <div
          className="modal fade"
          id="request_delivery_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ maxWidth: 500, margin: "auto" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-delivery-modal"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <img src={Delivery} className="delivery-image" />
                          </div>

                          <div
                            style={{
                              background: "rgb(10 70 78)",
                              padding: "20px 30px",
                              borderRadius: 10,
                            }}
                          >
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 20,
                                fontWeight: "bold",
                                paddingBottom: 10,
                                borderBottom: "1px solid white",
                                color: "white",
                              }}
                            >
                              এখন থেকে পণ্য ডেলিভারি নিন ঘরে বসেই
                            </div>
                            <div className="row mb-3" style={{ marginTop: 20 }}>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 18,
                                  color: "white",
                                }}
                              >
                                SELECT ADDRESS
                              </label>
                              <select
                                style={{
                                  border: "0px",
                                  marginTop: "5px",
                                }}
                                value={this.state.deliveryAddress}
                                onChange={this.handleChange}
                                name="deliveryAddress"
                                className="custom-select"
                                required
                              >
                                <option value="">Select Address</option>
                                <option value="ALG OFFICE">
                                  ALG PALTAN OFFICE
                                </option>
                                <option value="OTHERS">OTHERS</option>
                              </select>
                            </div>
                            {this.state.deliveryAddress == "OTHERS" ? (
                              <>
                                <div className="row mb-3">
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                    }}
                                  >
                                    DELIVERY ADDRESS
                                  </label>
                                  <textarea
                                    style={{
                                      backgroundColor: "white",
                                      marginTop: "7px",
                                    }}
                                    type="text"
                                    name="deliveryAddress2"
                                    value={this.state.deliveryAddress2}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    placeholder="Enter your delivery address details information"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                                <div className="row mb-3">
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                    }}
                                  >
                                    SELECT VEHICLE
                                  </label>
                                  <select
                                    style={{
                                      border: "0px",
                                      marginTop: "5px",
                                    }}
                                    value={this.state.vehicle}
                                    onChange={this.handleChange}
                                    name="vehicle"
                                    className="custom-select"
                                    required
                                  >
                                    <option value="">Select Vehicle</option>
                                    <option value="Pickup">Pickup</option>
                                    <option value="Van">Van</option>
                                    <option value="REDEX">
                                      REDEX (Parcel)
                                    </option>
                                    <option value="Sundarban Courier">
                                      Sundarban Courier
                                    </option>
                                  </select>
                                </div>
                              </>
                            ) : null}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    if (invoicesToPay.length > 0) {
                                      this.handleDelivery(invoicesToPay);
                                    } else {
                                      this.handleDelivery(
                                        this.props.checkedInvoices
                                      );
                                    }
                                  }}
                                  className="mt-3 btn"
                                  style={{
                                    background: "#f54c3b",
                                    color: "white",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    checkedInvoices: state.payments.checkedInvoices,
    currentUser: state.currentUser.currentUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    getAllParcelsOfSingleUserRedux,
    totalAmountToPayRedux,
  })(DeliveryModal)
);
