import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Search } from "react-feather";
import man from "./plus image.jpeg";
import {
  uploadImageRechargeRequest,
  uploadP2pMemberRequest,
} from "../../firebase/firebase.utils";
import { updateUserRedux } from "../../../actions/index";
import { withRouter } from "react-router-dom";

export class MyRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentsName: "",
      agentsAddress: "",
      agentsMobileNo: "",
      agentsPostcode: "",
      agentsNid: "",
      loading: false,
      imageUrl: man,
      file: "",
    };
  }

  componentDidMount = async () => {};
  componentWillReceiveProps = async (nextProps) => {};

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    const { currentUser } = this.props;

    if (this.state.loading) {
      alert("Please wait.. your image is uploading");
      return;
    }

    await this.props.updateUserRedux({
      ...currentUser,
      p2pMemberRequest: true,
    });

    await uploadP2pMemberRequest({ ...currentUser, ...this.state, file: "" });

    this.setState({
      agentsName: "",
      agentsAddress: "",
      agentsMobileNo: "",
      agentsPostcode: "",
      agentsNid: "",
      loading: false,
      imageUrl: man,
      file: "",
    });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { currentUser } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Register" parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    Register As P2P Agent
                  </h5>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 20,
                        marginBottom: 10,
                      }}
                    >
                      {currentUser && currentUser.p2pMemberRequest
                        ? "Your request to join our P2P program is in Review. It may take upto two working days."
                        : "Register as a P2P Agent to access all of our Agent's facilities"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {currentUser && currentUser.p2pAgent ? (
                        <button
                          className="btn"
                          style={{
                            backgroundColor: "green",
                            padding: "10px",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Approved
                        </button>
                      ) : currentUser && currentUser.p2pMemberRequest ? (
                        <button
                          className="btn"
                          style={{
                            backgroundColor: "orange",
                            padding: "10px",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Pending
                        </button>
                      ) : (
                        <button
                          className="btn"
                          data-toggle="modal"
                          data-target="#personalInfoModal"
                          style={{
                            backgroundColor: "rgb(0, 37, 76)",
                            padding: "10px",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Register
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "50%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Registration Form
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Your Image (holding Passport/NID)
                    </label>
                    <div
                      className="box-input-file"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border text-light mt-3 ml-4"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <img
                            className="img-100 lazyloaded blur-up"
                            src={this.state.imageUrl}
                            alt="#"
                            style={{
                              zIndex: 10,
                              cursor: "pointer",
                              border: "1px solid gainsboro",
                            }}
                            onClick={() => {
                              document
                                .getElementById("upload-image-input")
                                .click();
                            }}
                          />

                          <input
                            id="upload-image-input"
                            className="upload"
                            type="file"
                            style={{
                              position: "absolute",
                              zIndex: 5,
                              maxWidth: "50px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => this._handleImgChange(e, 0)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="agentsName"
                      value={this.state.agentsName}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Full Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Mobile No
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="agentsMobileNo"
                      onChange={this.handleChange}
                      value={this.state.agentsMobileNo}
                      id="exampleFormControlInput1"
                      placeholder="Enter Mobile No"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Postcode
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="agentsPostcode"
                      onChange={this.handleChange}
                      value={this.state.agentsPostcode}
                      id="exampleFormControlInput1"
                      placeholder="Enter Postcode"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      NID/Passport No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="agentsNid"
                      onChange={this.handleChange}
                      value={this.state.agentsNid}
                      id="exampleFormControlInput1"
                      placeholder="Enter NID/Passport No"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Address
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="agentsAddress"
                      value={this.state.agentsAddress}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Ex: Street:6a,431/12,New York"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2p,
    currentUser: state.currentUser.currentUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateUserRedux,
  })(MyRequest)
);
