import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  getAllParcelsOfSingleUserRedux,
  uploadPaymentRequestSourcingRedux,
} from "../../../actions/index";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

import sslCommerze from "./assets/ssl commerze.png";
import "./makePaymentModal.css";
import { CircleLoader } from "react-spinners";
import man from "../assets/images/dashboard/plus image.jpeg";
import axios from "axios";
import "./paymentModal.css";
class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      method: "",
      submit_loader: false,
      file: "",
      loading: false,
      imageUrl: man,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.parcelArray && nextProps.parcelArray.length > 0) {
      const invoiceArray = nextProps.parcelArray.filter(
        (parcel) => parcel.invoiceGenerated == true
      );
      this.setState({
        invoiceArray,
      });
    }
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { totalAmount, checkedInvoices, currentUser, express } = this.props;

    if (this.state.loading) {
      alert("please wait for the image to be uploded.");
      return;
    }
    if (this.state.imageUrl === man) {
      alert("Please upload your transactions copy.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    const paymentId = Math.floor(Math.random() * Date.now());

    this.setState({ submit_loader: true });

    await this.props.uploadPaymentRequestSourcingRedux({
      status: "pending",
      paymentId,
      userId: currentUser.uid,
      amount: totalAmount,
      imageUrl: this.state.imageUrl,
      method: this.state.method,
      paidInvoices: checkedInvoices,
    });

    toast.success(
      "Your payment Request is submitted! Our team member will confirm it soon"
    );
    this.setState({
      amount: "",
      imageUrl: man,
      method: "",
      file: "",
      loading: false,
      submit_loader: false,
    });

    document.getElementById("modal-close-icon-payment-modal-3").click();
    document.getElementById("trigger-delivery-modal").click();
  };

  readyForSslCheckout = async (total) => {
    const { currentUser, checkedInvoices } = this.props;
    this.setState({
      submit_loader: true,
    });

    console.log(checkedInvoices);
    const data = {
      currentUser,
      amount: Math.round(total),
      invoicesToPay: checkedInvoices,
      from: "sourcing",
    };
    const response = await axios.post(
      "https://alglimited.com/init-sslCommerz",
      data
    );
    if (response.data && response.data.length > 30) {
      window.location.replace(response.data);
    } else {
      alert("checkout failed. Try again later.");
    }

    this.setState({ submit_loader: false });
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_payment_popup_2"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="modal-content final-payment-modal"
              style={{
                backgroundColor: "white",
                overflowY: "scroll",
                maxHeight: 600,
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid make-payment-container">
                    <a
                      id="modal-close-icon-payment-modal-3"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-center no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2 className="mb-1 fw-6 make-payment-header">
                            Make Payment
                          </h2>

                          <div
                            style={{
                              color: "purple",
                              fontWeight: "bold",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              marginBottom: "25px",
                            }}
                          >
                            Total Amount: {this.props.totalAmount}Tk
                          </div>
                          <Tabs style={{ marginBottom: "40px" }}>
                            <TabList
                              className="nav nav-tabs tab-coupon"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "15px",
                              }}
                            >
                              <Tab className="nav-link each-option">
                                Moblie Banking
                              </Tab>
                              <Tab className="nav-link each-option">
                                Credit/Debit Card
                              </Tab>
                              <Tab className="nav-link each-option">
                                Bank Deposite
                              </Tab>
                            </TabList>
                            <TabPanel>
                              <div
                                className="table-responsive-md"
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <table className="table">
                                  <thead>
                                    <tr
                                      className="table-light"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="col">Total</th>
                                      <th scope="col">
                                        {this.props.totalAmount}Tk
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="table-light"
                                      key="City Bank"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="row">Fee(1.5%)</th>
                                      <td>
                                        {parseInt(this.props.totalAmount) *
                                          (1.5 / 100)}
                                        Tk
                                      </td>
                                    </tr>
                                    <tr
                                      className="table-light"
                                      key="City Bank-1"
                                    >
                                      <th
                                        scope="row"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Amount to Pay
                                      </th>
                                      <td style={{ fontWeight: "bold" }}>
                                        {parseInt(this.props.totalAmount) *
                                          (1.5 / 100) +
                                          this.props.totalAmount}
                                        Tk
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <button
                                className="btn"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#2a5da8",
                                  color: "white",
                                  minWidth: 140,
                                  minHeight: 40,
                                  justifyContent: "center",
                                  textAlign: "center",
                                  flexDirection: "row",
                                }}
                                onClick={async () => {
                                  this.readyForSslCheckout(
                                    parseInt(this.props.totalAmount)
                                  );
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>Proceed to Pay</>
                                )}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </TabPanel>
                            <TabPanel>
                              <div
                                className="table-responsive-md"
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                              >
                                <table className="table">
                                  <thead>
                                    <tr
                                      className="table-light"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="col">Total</th>
                                      <th scope="col">
                                        {this.props.totalAmount}Tk
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="table-light"
                                      key="City Bank-2"
                                      style={{
                                        borderLeft: "2px solid #ebeef4",
                                        borderRight: "2px solid #ebeef4",
                                      }}
                                    >
                                      <th scope="row">Fee(2.5%)</th>
                                      <td>
                                        {parseInt(this.props.totalAmount) *
                                          (2.5 / 100)}
                                        Tk
                                      </td>
                                    </tr>
                                    <tr
                                      className="table-light"
                                      key="City Bank-3"
                                    >
                                      <th
                                        scope="row"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Amount to Pay
                                      </th>
                                      <td style={{ fontWeight: "bold" }}>
                                        {parseInt(this.props.totalAmount) *
                                          (2.5 / 100) +
                                          this.props.totalAmount}
                                        Tk
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <button
                                className="btn"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  backgroundColor: "#2a5da8",
                                  color: "white",
                                  minWidth: 140,
                                  minHeight: 40,
                                  justifyContent: "center",
                                  textAlign: "center",
                                  flexDirection: "row",
                                }}
                                onClick={async () => {
                                  this.readyForSslCheckout(
                                    parseInt(this.props.totalAmount)
                                  );
                                }}
                              >
                                {!this.state.submit_loader && (
                                  <>Proceed to Pay</>
                                )}

                                <CircleLoader
                                  loading={this.state.submit_loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </TabPanel>
                            <TabPanel>
                              <div>
                                <div
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    backgroundColor: "purple",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  নিন্মোক্ত একাউন্টতে জমা/ট্রান্সফার করুন
                                </div>
                                <div className="table-responsive-md">
                                  <table className="table">
                                    <thead>
                                      <tr className="table-light">
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          Bank
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC Name
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          AC No.
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "70%",
                                          }}
                                        >
                                          Branch
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className="table-light"
                                        key="City Bank-4"
                                        style={{
                                          borderBottom: "2px solid #ebeef4",
                                        }}
                                      >
                                        <th scope="row">City Bank</th>
                                        <td>ALG Limited</td>
                                        <td>1223250557001</td>
                                        <td>VIP ROAD BRANCH</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div
                                style={{
                                  maxWidth: "500px",
                                  borderRadius: "10px",
                                  background: "purple",
                                  padding: "40px",
                                  paddingBottom: "0px",
                                  margin: "auto",
                                }}
                              >
                                <form onSubmit={this.handleSubmit}>
                                  <div
                                    className="form-row mb-4"
                                    style={{
                                      fontSize: "140%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    জমার ছবি/স্ক্রিনশট আপলোড করুন
                                  </div>
                                  <div
                                    className="form-row"
                                    style={{
                                      fontSize: "120%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    Method
                                  </div>
                                  <div className="form-row mb-2">
                                    <select
                                      style={{
                                        border: "0px",
                                        marginTop: "5px",
                                      }}
                                      value={this.state.method}
                                      onChange={this.handleChange}
                                      name="method"
                                      className="custom-select"
                                      required
                                    >
                                      <option value="">Select Method</option>
                                      <option value="Cash">Cash</option>
                                      <option value="City Bank">
                                        City Bank
                                      </option>
                                      <option value="Brac Bank">
                                        Brac Bank
                                      </option>
                                      <option value="Bkash">Bkash</option>
                                    </select>
                                  </div>
                                  <div
                                    className="form-row mb-1"
                                    style={{
                                      fontSize: "130%",
                                      color: "white",
                                      fontWeight: "bold",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    Upload Image
                                  </div>
                                  <div
                                    className="form-row mb-2"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="box-input-file"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {this.state.loading ? (
                                        <div
                                          className="spinner-border text-light mt-3 ml-4"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            className="img-100 lazyloaded blur-up"
                                            src={this.state.imageUrl}
                                            alt="#"
                                            style={{
                                              zIndex: 10,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              document
                                                .getElementById(
                                                  "upload-image-input"
                                                )
                                                .click();
                                            }}
                                          />

                                          <input
                                            id="upload-image-input"
                                            className="upload"
                                            type="file"
                                            style={{
                                              position: "absolute",
                                              zIndex: 5,
                                              maxWidth: "50px",
                                              marginTop: "10px",
                                            }}
                                            onChange={(e) =>
                                              this._handleImgChange(e, 0)
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className="form-row"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <div
                                      className="col pt-2"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        style={{
                                          minWidth: "100px",
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          minHeight: "30px",
                                        }}
                                      >
                                        {!this.state.submit_loader && (
                                          <>Upload</>
                                        )}

                                        <CircleLoader
                                          loading={this.state.submit_loader}
                                          color="white"
                                          size={15}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </form>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    fontSize: "14px",
                                    color: "white",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  আপনার ব্যংক জমা স্লিপ অথবা ট্রান্সফার এর ছবিটি
                                  উপরে আপলোড করে পেমেন্ট সম্পন্ন করুন।
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={sslCommerze}
                              alt=""
                              style={{
                                maxWidth: "70%",
                              }}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          id="trigger-delivery-modal"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#request_delivery_popup"
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    checkedInvoices: state.payments.checkedInvoices,
    currentUser: state.currentUser.currentUser,
    totalAmount: state.payments.totalAmount,
    invoicesToPay: state.payments.invoicesToPay,
    express: state.payments.express,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    getAllParcelsOfSingleUserRedux,
    uploadPaymentRequestSourcingRedux,
  })(PaymentModal)
);
