import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  getAllParcelsOfSingleUserRedux,
  totalAmountToPayRedux,
} from "../../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";

class MakePaymentModalExpress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      termsAndCondition: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.expressOrdersArray &&
      nextProps.expressOrdersArray.length > 0
    ) {
      const invoiceArray = nextProps.expressOrdersArray.filter(
        (parcel) => parcel.bookingStatus == "Received"
      );
      this.setState({
        invoiceArray,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handlePayment = async (total, invoicesToPay, parcelsArray) => {
    const { currentUser } = this.props;

    if (total > currentUser.myWallet) {
      alert(
        "Your account balance is insufficent for this payment.Please Recharge your account first"
      );
      return;
    }

    await this.props.makePaymentRedux(
      total,
      invoicesToPay,
      currentUser,
      parcelsArray
    );
    await this.props.getAllParcelsOfSingleUserRedux(currentUser.uid);
    toast.success("Your payment is successful");
    document.getElementById("modal-close-icon-payment-modal").click();
  };

  render() {
    const { email, password, invoiceArray } = this.state;
    const { checkedInvoices } = this.props;
    const invoicesToPay = invoiceArray.filter((invoice) =>
      checkedInvoices.includes(invoice.bookingId)
    );
    let total = 0;
    invoicesToPay.forEach(
      (invoice) =>
        (total += parseInt(invoice.totalCost) || parseInt(invoice.total))
    );
    console.log(total);
    return (
      <>
        <div
          className="modal fade"
          id="request_make_payment_express_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal"
              style={{
                backgroundColor: "rgb(68 0 97)",
                maxHeight: "800px",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      id="modal-close-icon-payment-modal"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-2 fw-6 pb-2"
                            style={{
                              color: "black",
                              textTransform: "none",
                              fontSize: "250%",
                              textAlign: "center",
                            }}
                          >
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div className="table-responsive-md">
                            <table className="table">
                              <thead>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Booking Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Box Size
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Product
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesToPay.length > 0 &&
                                  invoicesToPay.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.bookingId}
                                      >
                                        <th scope="row">{invoice.date}</th>
                                        <td>{invoice.bookingId}</td>
                                        <td>{invoice.parcelType}</td>
                                        <td>{invoice.parcelBox}</td>
                                        <td>{invoice.productName}</td>
                                        <td>
                                          {invoice.totalCost || invoice.total}Tk
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr className="table-light">
                                  <th scope="row"></th>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Grand Total
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {total}Tk
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{ display: "flex", flexDirectioin: "row" }}
                          >
                            <input
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div style={{ marginLeft: "7px" }}>
                              I read & agree to the{" "}
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push(
                                    "/terms-and-condition"
                                  );
                                }}
                                data-dismiss="modal"
                              >
                                Terms & Condition
                              </span>
                              ,&nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push("/privacy-policy");
                                }}
                                data-dismiss="modal"
                              >
                                Privacy Policy
                              </span>
                              &nbsp;&&nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                                onClick={() => {
                                  this.props.history.push("/refund-policy");
                                }}
                                data-dismiss="modal"
                              >
                                Refund Policy
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <button
                                onClick={() => {
                                  this.props.totalAmountToPayRedux({
                                    total,
                                    express: true,
                                    invoicesToPay,
                                  });
                                }}
                                className="mt-3 btn btn-secondary"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#request_payment_popup"
                              >
                                Proceed to checkout
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expressOrdersArray: state.orders.expressOrders,
    checkedInvoices: state.payments.checkedInvoices,
    currentUser: state.currentUser.currentUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    getAllParcelsOfSingleUserRedux,
    totalAmountToPayRedux,
  })(MakePaymentModalExpress)
);
