import React, { useEffect } from "react";
import { Component } from "react";
import MainNavigation from "./components/MainNavigation";
import { connect } from "react-redux";
import { setCurrentUserRedux } from "./actions/index";
import {
  auth,
  createUserProfileDocument,
  firestore,
  updateAllDateBooking,
  updateAllUser,
} from "./components/firebase/firebase.utils";
import { ToastContainer, toast } from "react-toastify";
import FbMessenger from "./components/facebookMessenger/facebookMessenger";

class App extends Component {
  unsubscribeFromAuth = null;

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        console.log(userAuth);
        const userRef = await createUserProfileDocument(userAuth);

        if (userRef) {
          userRef.onSnapshot((snapShot) => {
            console.log(snapShot.data());
            this.props.setCurrentUserRedux({
              id: snapShot.id,
              ...snapShot.data(),
            });
          });
          toast.success("successfully logged in!");
        } else {
          this.props.setCurrentUserRedux({ displayName: "", email: "" });
        }
      } else {
        this.props.setCurrentUserRedux({ displayName: "", email: "" });
      }
    });
  };

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    return (
      <>
        <FbMessenger />
        <MainNavigation />
        <ToastContainer />
      </>
    );
  }
}

export default connect(null, { setCurrentUserRedux })(App);
