import React, { Component } from "react";
import firebase, { auth } from "./firebase/firebase.utils";
class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(this.state.email);
    });
  };

  onSignInSubmit = async (event) => {
    event.preventDefault();
    var emailAddress = this.state.email;
    console.log(emailAddress);
    auth
      .sendPasswordResetEmail(this.state.email)
      .then(() => {
        alert(
          "Password reset email has been sent to your email address.Plesase check your email"
        );
        this.setState({ email: "" });
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ email: "" });
      });
  };

  render() {
    return (
      <>
        <div
          className="modal fade"
          id="request_forgot_password_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div className="modal-content phone-modal">
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-0">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="icofont-close-line"></i>
                    </a>
                    <div
                      className="d-lg-flex justify-content-end no-gutters mb-spacer-md"
                      style={{
                        boxShadow: "0px 18px 76px 0px rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      <div
                        className="col bg-fixed login-img-bg request_pag_img"
                        style={{ minHeight: "200px" }}
                      >
                        &nbsp;
                      </div>

                      <div
                        className="col-md-7 col-12"
                        style={{ marginTop: "30px" }}
                      >
                        <div className="px-3 m-4">
                          <h2
                            className="h2-xl fw-6"
                            style={{
                              color: "orange",
                              fontSize: "140%",
                              fontWeight: "bolder",
                            }}
                          >
                            Forgot Password
                          </h2>
                          <form
                            noValidate="novalidate"
                            className="rounded-field"
                            onSubmit={this.onSignInSubmit}
                          >
                            <div className="form-row mb-2">
                              <div className="col">
                                <input
                                  onChange={this.handleChange}
                                  type="text"
                                  name="email"
                                  value={this.state.email}
                                  className="form-control"
                                  placeholder="Enter Your Email"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="col pt-2">
                                <button
                                  id="number-submit"
                                  type="submit"
                                  className="form-btn btn-theme bg-orange"

                                  // data-dismiss="modal"
                                  // data-toggle="modal"
                                  // data-target="#request_otp_popup"
                                >
                                  Reset Password
                                  <i className="icofont-rounded-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ForgotPasswordModal;
