import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./myRefundDatatable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Search } from "react-feather";
import { getAllParcelsOfSingleUserRedux } from "../../../actions";
export class MyRefund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAvailableRefund: [],
      searchFor: "",
    };
  }

  componentDidMount = async () => {
    if (this.props.currentUser.uid) {
      await this.props.getAllParcelsOfSingleUserRedux(
        this.props.currentUser.uid
      );
      const { parcelArray } = this.props;
      if (parcelArray && parcelArray.length > 0) {
        let allAvailableRefundArray = parcelArray.filter(
          (parcel) => parcel.note
        );
        this.setState({ allAvailableRefund: allAvailableRefundArray });
      }
    }
  };
  componentWillReceiveProps = async (nextProps) => {
    const { parcelArray } = nextProps;
    if (this.props.currentUser.uid !== nextProps.currentUser.uid) {
      await this.props.getAllParcelsOfSingleUserRedux(
        this.props.currentUser.uid
      );
    }
    if (parcelArray && parcelArray.length > 0) {
      let allAvailableRefundArray = parcelArray.filter((parcel) => parcel.note);
      this.setState({ allAvailableRefund: allAvailableRefundArray });
    }
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    console.log(this.state.allAvailableRefund);
    return (
      <Fragment>
        <Breadcrumb title="My Refunds" parent="Refunds" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5
                    style={{
                      fontFamily: "fantasy",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    <i
                      className="icofont-notebook"
                      style={{
                        fontSize: "180%",
                        marginRight: "5px",
                        marginTop: "5px",
                        color: "#430861",
                      }}
                    ></i>
                    My Refunds
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                          className="search-box-top"
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Refund"
                            onChange={this.handleSearchBarChange}
                            style={{ paddingLeft: "4px" }}
                          />
                          <span
                            // className="d-sm-none mobile-search"
                            onClick={() => this.handleSearchClick()}
                          >
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <Datatable
                      history={this.props.history}
                      multiSelectOption={false}
                      myData={
                        !this.state.searchFor
                          ? this.state.allAvailableRefund
                          : this.state.allAvailableRefund.filter(
                              (refund) =>
                                refund.parcelId
                                  .toLowerCase()
                                  .includes(this.state.searchFor) ||
                                refund.trackingNo
                                  .toLowerCase()
                                  .includes(this.state.searchFor)
                            )
                      }
                      pageSize={50}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parcelArray: state.orders.ordersArrayOfSingleUser,
    currentUser: state.currentUser.currentUser,
  };
};

export default connect(mapStateToProps, { getAllParcelsOfSingleUserRedux })(
  MyRefund
);
