const INITIAL_STATE = {
  bookingRequests: [],
  bookingsArrayOfSingleUser: [],
  bookingObj: null,
};

const setBookingRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BOOKING_REQUEST":
      return {
        ...state,
        bookingRequests: [...state.bookingRequests, action.payload],
      };
    case "GET_ALL_BOOKINGS_OF_SINGLE_USER":
      return {
        ...state,
        bookingsArrayOfSingleUser: action.payload,
      };
    case "GET_SINGLE_BOOKING":
      return {
        ...state,
        bookingObj: action.payload,
      };
    default:
      return { ...state };
  }
};
export default setBookingRequestReducer;
